<template>
  <button
    :class="`${classes} ${showActiveClasses ? activeClasses : ''}`"
    @click="action()"
  >
    <slot />
    <span v-if="hotkey" class="text-gray">[{{ hotkey }}]</span>
  </button>
</template>

<script>
export default {
  name: 'HotkeyButton',
  components: {},
  data() {
    return {
      showActiveClasses: false,
      removeActiveClassesTimeout: null
    }
  },
  methods: {

    handleKeydown(e) {
      if(this.hotkey && this.hotkey===e.key) {
        if(this.allowHold) {
          this.action()
          this.temporarilyShowActiveClasses()
        }
      }
    },
    handleKeyup(e) {
      if(this.hotkey && this.hotkey===e.key) {
        if(!this.allowHold) {
          this.action()
          this.temporarilyShowActiveClasses()
        }
      }
    },
    /**
     * Adds classes to temporarily give active styles (as if the user clicked the button)
     * Useful for UX feedback when activating button via hotkey
     */
    temporarilyShowActiveClasses() {
      this.showActiveClasses = true
      clearTimeout(this.removeActiveClassesTimeout)
      this.removeActiveClassesTimeout = setTimeout(()=>{
        this.showActiveClasses = false
      },75)
    },
  },
  mounted() {
    window.addEventListener('keyup',this.handleKeyup)
    window.addEventListener('keydown',this.handleKeydown)
  },
  beforeUnmount() {
    window.removeEventListener('keyup',this.handleKeyup)
    window.removeEventListener('keydown',this.handleKeydown)
  },
  props: {
    hotkey: String,
    action: Function,
    classes: {
      type: String,
      default: 'button'
    },
    allowHold: {
      type: Boolean,
      default: false
    },
    activeClasses: {
      type: String,
      default: 'bg-green text-black border-green scale-[1.1]'
    }
  }
}
</script>
