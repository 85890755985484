<template>
  <header class="absolute bg-black px-2 py-1 top-0 left-0">
    <h1 class="text-md text-green font-head uppercase">Moon Dungeon <span class="text-white" v-if="cohortName!=='default'">({{ cohortName }})</span></h1>
  </header>
</template>

<script>
  import getCohortName from '@/lib/getCohortName';

  export default {
    name: 'Header',
    data() {
      return {
        cohortName: getCohortName()
      }
    }
  }
</script>