import { playerContainerWidth } from "@/lib/constants";

// TODO: clean up graphics? and finish documenting, pan audio to world coordinates

/**
 * Creates an audio-visualizer centered on
 * @param {number} x - world coordinate
 * @param {number} y - world coordinate
 * @param {Object} soundInstance - the phaser sound to feed the analyser
 * @param {Object} gadget - phaser
 * @param {Object} options
 */
export function createSoundwave(
  x,
  y,
  soundInstance,
  phaserScene,
  options = {},
  color = 0xffffff
) {
  const defaultOptions = {
    duration: 500,
    color,
    radius: 5,
    alpha: 1,
  };
  options = { ...defaultOptions, ...options };

  // const graphics = phaserScene.make.graphics({
  //   x: x,
  //   y: y,
  //   add: true,
  // });

  // // Create a webaudio analyser (for our visualizer)
  // //https://rexrainbow.github.io/phaser3-rex-notes/docs/site/audio/#analyser
  // const analyser = phaserScene.sound.context.createAnalyser();
  // analyser.fftSize = 512;
  // soundInstance.volumeNode.connect(analyser);
  // const bufferLength = analyser.frequencyBinCount;
  // let dataArray = new Float32Array(bufferLength);

  // // Begin animation
  // const startTime = new Date().getTime();
  // function draw() {
  //   // Clear canvas
  //   graphics.clear();

  //   // Stop if we're done
  //   const elapsedTime = new Date().getTime() - startTime;
  //   const fractionDone = elapsedTime / options.duration;
  //   if (fractionDone >= 1) return;

  //   // Get a head start on next frame
  //   requestAnimationFrame(draw);

  //   // Line styles
  //   graphics.alpha = 1 - fractionDone; // Fade away over time
  //   graphics.lineStyle(6, options.color, options.alpha);
  //   graphics.beginPath(); // Draw the line

  //   // Get visualizer data
  //   analyser.getFloatTimeDomainData(dataArray);

  //   // Render data (in circular/polar graph)
  //   var angularSlice = (2 * Math.PI) / bufferLength;
  //   const currentRadius = fractionDone * playerContainerWidth * options.radius; // Grows over time
  //   let firstCoord = null;
  //   for (var i = 0; i < bufferLength; i++) {
  //     const coord = [
  //       Math.cos(angularSlice * i) *
  //         (currentRadius * 2 * dataArray[i] + currentRadius * 2),
  //       Math.sin(angularSlice * i) *
  //         (currentRadius * 2 * dataArray[i] + currentRadius * 2),
  //     ];
  //     if (i === 0) {
  //       firstCoord = coord;
  //       graphics.moveTo(...coord);
  //     } else {
  //       graphics.lineTo(...coord);
  //     }
  //   }
  //   graphics.lineTo(...firstCoord);
  //   graphics.strokePath();
  // }
  // draw(); // Start her up! Now we're drawing!

  for (let i = 0; i < 2; i++) {
    setTimeout(() => {
      // const soundwaveCircle = phaserScene.add.circle(
      //   x,
      //   y,
      //   playerContainerWidth / 20
      // );
      let soundwaveCircle = phaserScene.add.image(x, y, "soundwave");
      soundwaveCircle.setScale(0.0001);
      if (options.radius > 1) soundwaveCircle.setAlpha(0.5); // This is hacky--should be a continuous function
      soundwaveCircle.name = "sound-indicator";
      // soundwaveCircle.setStrokeStyle(8, color);
      const tween = phaserScene.tweens.add({
        targets: soundwaveCircle,
        alpha: 0,
        scale: options.radius * 1.5,
        duration: 300,
        onComplete: () => {
          soundwaveCircle.destroy();
          soundwaveCircle = null;
        },
      });
    }, i * 80);
  }
}
