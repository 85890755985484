import Joi from 'joi';

/**
 * Scheme for store commit payload to create a push notification
 */
const notificationSchema = Joi.object().keys({
  type: Joi.string().valid('story','system').default('story'),
  content: Joi.string().required(),
  timeout: Joi.number().default(5),
  character: Joi.string()
})
export default notificationSchema