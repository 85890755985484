<template>
  <Game />
</template>

<script>
import Game from '@/components/Game'
export default {
  name: 'Home',
  components: { Game }
}
</script>

<style lang="postcss">
  /* Cannot add classes to html or body components in Vue, so styling via postcss. */
  html {
    /* Set the rem */
    font-size: 1.2vw;
  }

  body {
    @apply overflow-hidden;
  }
</style>