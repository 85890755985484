<template>
  <div
    class="fixed bottom-0 left-0 bg-black px-2 py-1 border border-green text-xs opacity-0 hover:opacity-100 transition-opacity z-50 focus-within:opacity-100"
  >
    <Accordion title="Debug">
      <div class="max-h-[90vh] overflow-y-auto">
        <div>Deploy note: "Wordhack final 2"</div>

        <Accordion title="Performance" start-open>
          <div class="flex">
            <section v-if="$store.state.fps" class="bar-graph">
              Graphics
              <ul>
                <li
                  v-for="(avg, index) in $store.state.fpsAverages"
                  :key="index"
                >
                  <span
                    v-bind:style="{
                      '--color':
                        avg > 58 ? 'lime' : avg > 50 ? 'yellow' : 'red',
                      '--size': (avg / 60) * 100 + '%',
                    }"
                    class="bar-graph__bar"
                  >
                    <span>{{ avg.toFixed(0) }}</span>
                    <span>{{
                      $store.state.playerCountAverages[index].toFixed(0)
                    }}</span>
                  </span>
                </li>
              </ul>
            </section>
            <!-- <section v-if="$store.state.fps" class="bar-graph ml-2">
              Network
              <ul>
                <li
                  v-for="(avg, index) in $store.state.heartbeatLengthAverages"
                  :key="index"
                >
                  <span
                    v-bind:style="{
                      '--color':
                        avg < 36 ? 'lime' : avg < 50 ? 'yellow' : 'red',
                      '--size': (avg / 100) * 100 + '%',
                    }"
                    class="bar-graph__bar"
                  >
                    <span>{{ avg.toFixed(0) }}</span>
                  </span>
                </li>
              </ul>
            </section> -->
          </div>
        </Accordion>
        <Accordion title="room info">
          <pre v-if="$store.state.room"> {{ JSON.stringify($store.state.room,null,2) }}</pre>
        </Accordion>
        <Accordion title="my player">
          <pre v-if="myPlayerData">{{ myPlayerData }}</pre>
        </Accordion>
        <Accordion title="my connections">
          <ul>
            <li :key="getMyPlayerId()" class="my-0.5">
              <ConnectionDebug
                :connectionId="getMyPlayerId()"
                :isMyPlayer="true"
              />
            </li>
            <li v-for="id in playerIds" :key="id" class="my-0.5">
              <ConnectionDebug :connectionId="id" />
            </li>
          </ul>
        </Accordion>

        <Accordion title="global connections">
          <ul>
            <li
              v-for="id in Object.keys(globalConnectionsSummary)"
              :key="id"
              :class="`font-[monospace] ${
                globalConnectionsSummary[id].connected !== Object.keys(globalConnectionsSummary).length - 1
                  ? 'text-[red]'
                  : 'text-[lime]'
              }`"
            >
              {{ id }}: {{ JSON.stringify(globalConnectionsSummary[id]) }}
            </li>
          </ul>
        </Accordion>

        <Accordion title="rendering">
          <ul>
            <li v-if="forcedRenderType">
              Forced Render Engine: {{ forcedRenderType }}
            </li>
          </ul>
        </Accordion>
      </div>
    </Accordion>
  </div>
</template>

<script>
import Accordion from "@/components/Accordion";
import ConnectionDebug from "@/components/Debug/ConnectionDebug";
import store from "@/lib/store";
import benchmark from "@/lib/benchmark";

export default {
  name: "Debug",
  components: { ConnectionDebug, Accordion },
  data() {
    return {
      forcedRenderType: false,
      playerIds: {},
      /** copy of globalConnectionsSummary report (who is connected to who) */
      globalConnectionsSummary: {},
      /** Holds strinigifed copy of MyPlayer data */
      myPlayerData: "",
    };
  },
  computed: {},
  methods: {
    /**
     * Retrieve myPlayer nickname from MyPlayer
     */
    getMyPlayerNickname() {
      return window?.phaserScene?.playerManager?.myPlayer?.nickname;
    },

    /**
     * Retrieve myPlayer id from network handler
     */
    getMyPlayerId() {
      return this.$store.state.networkProvider.id;
    },

    /**
     * Retrieve a stringified copy of MyPlayer obj
     */
    getMyPlayerData() {
      if (!window?.phaserScene?.playerManager?.myPlayer) return;
      const { betweenRooms } = window.phaserScene.playerManager.myPlayer;
      this.myPlayerData = JSON.stringify(
        {
          betweenRooms,
        },
        null,
        2
      );
    },

    /**
     * Convenience function for stylizing a console log so it stands out (color background and white text)
     * @param str - text to log
     * @param [color] - bg color
     */
    stylizeLog(str, color = "red") {
      return ["%c " + str + " ", `color: white; background:${color};`];
    },

    /**
     * Pseudo-random number generator algo mulberry32
     * @param seed
     * @returns
     */
    seededRandom(seed) {
      if (typeof seed === "string") {
        seed = this.seedFromString(seed);
      }
      let t = (seed += 0x6d2b79f5);
      t = Math.imul(t ^ (t >>> 15), t | 1);
      t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
      return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
    },

    /**
     * Generates a color (and its opposite) at random from a string.
     * The randomness is seeded/deterministic--so youll always get the same color for a string.
     */
    deterministicRandomColorFromString(str, opposite = false) {
      let h = this.seededRandom(str) * 360;
      if (opposite) h = (h + 180) % 360;
      return hslToHexString(h, 100, 50);
    },

    /**
     * A sloppy hash that will return a (decently) unique number from a string
     * @param str string to
     * @returns hash
     */
    seedFromString(str) {
      let hash = 0,
        i,
        chr,
        len;
      if (typeof str.length === "undefined" || str.length === 0) return hash;
      for (i = 0, len = str.length; i < len; i++) {
        chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0;
      }
      return hash;
    },
  },
  async mounted() {
    const params = new URLSearchParams(window.location.search);
    this.forcedRenderType = params.get("render-type");

    // Add benchmarking UI (nothing will be run if certain query string parameters not present in url)
    // Currently just starting this after a delay so game can be ready--this is a hack, we really need to hook benchmark's initiation to a game ready event
    // But I was in a hurry.
    setTimeout(()=>benchmark(),1000)

    // Intervalically audit the list of connections from the network handler
    setInterval(() => {
      const players = store.state.networkProvider.connections;
      this.playerIds = Object.keys(players).map((id) => id);
      this.getMyPlayerData();
    }, 100);

    // Get the global connections summary report from the server by listening to the event that is triggered when the server sends updates
    window.networkProvider.events.on(
      "global-connections-summary-update",
      (report) => {
        this.globalConnectionsSummary = report;
      }
    );
  },
};
</script>

<style lang="scss" scoped>
.bar-graph {
  ul {
    display: flex;
    background: black;
    padding: 0;
    font-size: 11px;
    margin: 0;
    overflow: hidden;

    li {
      height: 80px;
      width: 20px;
      position: relative;

      .bar-graph__bar {
        color: black;
        background-color: var(--color, white);
        position: absolute;
        bottom: 0;
        height: var(--size);
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        span {
          width: 100%;
          text-align: center;
        }
      }
    }
  }
}
</style>

<style lang="scss">
// This is not scoped to this component, as it is injected by an external script--but this seems like a good place for it...
#benchmark {
  background: white;
  color: black;
  position: fixed;
  top: 0;
  right: 0;
  padding: 0.5em;
  cursor: pointer;
  z-index: 2;

  h1 {
    font-size: 1rem;
    margin: 0;
    text-align: center;
  }

  &.benchmark--error {
    outline: 2px solid red;
    color: red;
    background: pink;
  }
}
</style>
