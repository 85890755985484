import Joi from "joi";

/**
 * Some defaults to import into our performerControl schemas
 */
const performerControlSchemaDefaults = {
  id: Joi.string().required(),
  label: Joi.string().default("Label"),
};

/**
 * An object of schemas for different types of performer controls
 * Adding a new control type? Just expand this object and you are done with validation.
 */
const performerControlPossibleSchemas = {
  button: Joi.object().keys({
    ...performerControlSchemaDefaults,
    type: Joi.string().valid("button").required(),
    hotkey: Joi.string(),
  }),
  text: Joi.object().keys({
    ...performerControlSchemaDefaults,
    type: Joi.string().valid("text").required(),
    value: Joi.string().allow("").default(""),
  }),
  input: Joi.object().keys({
    ...performerControlSchemaDefaults,
    type: Joi.string().valid("input").required(),
    value: Joi.string().allow("").default(""),
  }),
  "dynamic-input": Joi.object().keys({
    ...performerControlSchemaDefaults,
    type: Joi.string().valid("dynamic-input").required(),
    value: Joi.string().allow("").default(""),
  }),
  sequence: Joi.object().keys({
    ...performerControlSchemaDefaults,
    type: Joi.string().valid("sequence").required(),
    current: Joi.number().default(0),
    steps: Joi.array().items(Joi.string()).required(),
    prevHotkey: Joi.string(),
    nextHotkey: Joi.string(),
    teleprompterMode: Joi.boolean().default(false),
  }),
  toggle: Joi.object().keys({
    ...performerControlSchemaDefaults,
    type: Joi.string().valid("toggle").required(),
    value: Joi.boolean().default(false),
    hotkey: Joi.string(),
  }),
  target: Joi.object().keys({
    ...performerControlSchemaDefaults,
    type: Joi.string().valid("target").required(),
    hotkey: Joi.string(),
    value: Joi.string(),
  }),
  slider: Joi.object().keys({
    ...performerControlSchemaDefaults,
    type: Joi.string().valid("slider").required(),
    min: Joi.number().default(0),
    max: Joi.number().default(1),
    step: Joi.number().default(0.01),
    value: Joi.number().default(0),
    plusHotkey: Joi.string(),
    minusHotkey: Joi.string(),
    hotKeyStep: Joi.number().default(0.2),
  }),
};

/**
 * A Joi schema that validates against ANY of performerControlPossibleSchemas
 * Which one will be used to validate is based on .type
 */
export const performerControlSchema = Joi.alternatives()
  // We use .type to determine which which schema it should be.
  .conditional(".type", {
    // If .type = a key in in peerfomerControlSchemas, we validate this item against that schema
    switch: [
      ...Object.keys(performerControlPossibleSchemas).map((controlType) => ({
        is: controlType,
        then: performerControlPossibleSchemas[controlType],
      })),
    ],
  });
