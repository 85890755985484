import Player from "./Player";
import PositionIndicator from "./PositionIndicator";
import { LERP_SPEED, LERP_SPEED_ANGULAR, DEPTHS } from "@/lib/constants";
import { lerp } from "@/lib/lerp";

// OtherPlayer does a LOT less than MyPlayer--it just animates to match the state sent over the server
export default class OtherPlayer extends Player {
  constructor(scene, id, options = {}) {
    super(scene, id, options);
    this.matterRotation = 0; // see explanation in update() function
  }

  create() {
    super.create();

    // We loop toward this state
    this.destination = {
      x: this.playerPhaserObject.x,
      y: this.playerPhaserObject.y,
      rotation: this.matterRotation,
      scaleMultiplier: 1,
    };

    // Set depth to other player depth (so that this player is behind MyPlayer)
    this.playerPhaserObject.setDepth(DEPTHS.OTHER_PLAYERS);
  }

  afterCreate() {
    super.afterCreate();

    // Get our stable state from the network.
    this.networkProvider.requestPeerStableState(this.id);
  }

  // Player manager will get this called with scene update
  update = () => {
    super.update();

    // Determine if we are not in the same room as My Player (if so this flag will hide us and suppress certain gadget behaviors)
    this.outsideCurrentRoom = this.scene.room.id !== this.currentRoomId;

    // If player is not in the current room, we want them FAR away from everything
    // Cant just hide them by making them transparent because we dont wanna risk any physics/collisions
    if (this.outsideCurrentRoom) {
      this.updatePlayerVolatileStateWithoutLerping({
        x: -99999,
        y: -99999,
      });
    } else {
      // There are few cases when it is BAD to LERP.
      // Namely, when this OtherPlayer tranisions between the outside room state (positioned at -99999,-99999) and somewhere in the room
      // We do NOT want interpolation here
      // Usually this happens for two reason:
      // This OtherPlayer is leaving or entering the current room
      // Or MyPlayer is entering or leaving a room with this OtherPlayer in it
      // We check the isBetweenRooms() status of both this other player, and as a fallback we see if this player phaser object is x,y<0
      // indicating it is probably coming from an outside room status
      if (
        this.isBetweenRooms() ||
        this.scene.playerManager.myPlayer.isBetweenRooms() ||
        this.playerPhaserObject.x < 0 ||
        this.playerPhaserObject.y < 0
      ) {
        this.updatePlayerVolatileStateWithoutLerping(this.destination);
      }
    }

    // Lerp to target our position
    this.playerPhaserObject.x = lerp(
      this.playerPhaserObject.x,
      this.destination.x,
      LERP_SPEED
    );
    this.playerPhaserObject.y = lerp(
      this.playerPhaserObject.y,
      this.destination.y,
      LERP_SPEED
    );

    // Lerp the player scale
    this.scaleMultiplier = lerp(
      this.scaleMultiplier,
      this.destination.scaleMultiplier,
      LERP_SPEED
    );
    this.playerPhaserObject.scaleX = this.scaleMultiplier ?? 1;
    this.playerPhaserObject.scaleY = this.scaleMultiplier ?? 1;

    // Lerp player alpha
    this.playerPhaserObject.alpha = lerp(
      this.playerPhaserObject.alpha,
      this.destination.alpha,
      0.8
    );

    // SO... matter and phaser handle rotation values differently.  Matter uses a continuous value, phaser repeats over the interval [-PI,PI]
    // We don't wanna deal with the translation between those two spaces, so we store the rotation ourselves and lerp from that
    // rather than retrieving it from our phaser container
    const nextRotation = lerp(
      this.matterRotation,
      this.destination.rotation,
      LERP_SPEED_ANGULAR
    );
    this.playerPhaserObject.setRotation(nextRotation);
    this.matterRotation = nextRotation;

    // Lerp our energy
    this.energy = lerp(this.energy, this.destination.energy, 0.01);

    // Draw a position indicator if appropriate
    if (
      this.outsideCurrentRoom ||
      this.playerPhaserObject.x < 0 ||
      this.playerPhaserObject.y < 0
    ) {
      if (this.positionIndicator) {
        this.positionIndicator.destroy();
        this.positionIndicator = null;
      }
    } else if (!this.positionIndicator) {
      this.positionIndicator = new PositionIndicator(
        this.playerPhaserObject,
        this.scene,
        { color: this.playerColor }
      );
    }
  };

  /**
   * Update the volalitle state of this OtherPlayer to match the passed volatileState
   * But do so INSTANTLY without any lerping.  This is important for moments e.g. changing rooms
   * @param {PlayerVolatileState} playerVolatileState
   */
  updatePlayerVolatileStateWithoutLerping = (playerVolatileState) => {
    if (typeof playerVolatileState.x !== "undefined") {
      this.playerPhaserObject.x = playerVolatileState.x;
      this.destination.x = playerVolatileState.x;
    }
    if (typeof playerVolatileState.y !== "undefined") {
      this.playerPhaserObject.y = playerVolatileState.y;
      this.destination.y = playerVolatileState.y;
    }
    if (typeof playerVolatileState.rotation !== "undefined") {
      this.playerPhaserObject.setRotation(playerVolatileState.rotation);
      this.matterRotation = playerVolatileState.rotation;
      this.destination.rotation = playerVolatileState.rotation;
    }
    if (typeof playerVolatileState.energy !== "undefined") {
      this.energy = playerVolatileState.energy;
      this.destination.energy = playerVolatileState.energy;
    }
    if (typeof playerVolatileState.scaleMultiplier !== "undefined") {
      this.scaleMultiplier = playerVolatileState.scaleMultiplier;
      this.destination.scaleMultiplier = playerVolatileState.scaleMultiplier;
    }
    if (typeof playerVolatileState.alpha !== "undefined") {
      this.playerPhaserObject.alpha = playerVolatileState.alpha;
      this.destination.alpha = playerVolatileState.alpha;
    }
    if (typeof playerVolatileState.betweenRooms !== "undefined") {
      this.betweenRooms.volatile = playerVolatileState.betweenRooms;
    }
  };

  /**
   * Update the volalitle state of this OtherPlayer to match the passed volatileState (probably coming from a peer on the network)
   * Fired by PlayerManager
   * @param {PlayerVolatileState} playerVolatileState
   */
  updatePlayerVolatileState = (playerVolatileState) => {
    if (typeof playerVolatileState.x !== "undefined")
      this.destination.x = playerVolatileState.x;
    if (typeof playerVolatileState.y !== "undefined")
      this.destination.y = playerVolatileState.y;
    if (typeof playerVolatileState.rotation !== "undefined")
      this.destination.rotation = playerVolatileState.rotation;
    if (typeof playerVolatileState.energy !== "undefined")
      this.destination.energy = playerVolatileState.energy;
    if (typeof playerVolatileState.scaleMultiplier !== "undefined")
      this.destination.scaleMultiplier = playerVolatileState.scaleMultiplier;
    if (typeof playerVolatileState.alpha !== "undefined")
      this.destination.alpha = playerVolatileState.alpha;
    if (typeof playerVolatileState.betweenRooms !== "undefined") {
      this.betweenRooms.volatile = playerVolatileState.betweenRooms;
    }
  };

  /**
   * Parse and routes VolatileState data to the functions that will update the player and the gadgets
   * @param {VolatileState} volatileState
   */
  updateVolatileState = (volatileState) => {
    this.updatePlayerVolatileState(volatileState.player);
    if (this.gadgetManager)
      this.gadgetManager.updateGadgetsVolatileState(volatileState.gadgets);
  };

  // Clean up our objects and handlers
  destroy() {
    if (this.positionIndicator) this.positionIndicator.destroy();
    super.destroy();
  }
}
