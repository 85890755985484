<template>
  <div class="font-[monospace] font-bold text-sm">
    <span class="w-[14rem] inline-block px-1"
      :style="`color: ${deterministicRandomColorFromString(connectionId)}; background: ${deterministicRandomColorFromString(connectionId,true)}`"
    >{{ getPlayerNickname(connectionId) ?? ''}}</span>
    <!-- <span class="w-[10rem] inline-block">{{ getPlayerNickname(connectionId) ?? ''}}</span> -->
    <template v-if="isMyPlayer">
      <span class="mx-1">(me)</span>
    </template>
    <template v-else>
      <button class="border px-1 mx-1" @click="disconnectPlayer()">Disconnect</button>
      <button class="border px-1 mx-1" @click="logPeer()">Log</button>

      <span class="w-[16rem] inline-block ml-2">[{{ status }}: {{ candidatePairData }}]</span>

      <span :class="`w-[3rem] inline-block bg-[violet] text-black ${renderBitrate(report.id)==='NaN' || renderBitrate(report.id)==='0' ? 'opacity-30' : ''}`" v-for="report in outboundVideoReports" :key="report.id">
        <span class="inline-block scale-[2] px-2">🎥↑</span> {{ renderBitrate(report.id) }}
      </span>
      <span :class="`w-[3rem] inline-block bg-[violet] text-black ${renderBitrate(report.id)==='NaN' || renderBitrate(report.id)==='0' ? 'opacity-30' : ''}`" v-for="report in outboundAudioReports" :key="report.id">
        <span class="inline-block scale-[2] px-2">🔊↑</span> {{ renderBitrate(report.id) }}
      </span>
      <span :class="`w-[3rem] inline-block bg-[cyan] text-black ${renderBitrate(report.id)==='NaN' || renderBitrate(report.id)==='0' ? 'opacity-30' : ''}`" v-for="report in inboundVideoReports" :key="report.id">
        <span class="inline-block scale-[2] px-2">🎥↓</span> {{ renderBitrate(report.id) }}
      </span>
      <span :class="`w-[3rem] inline-block bg-[cyan] text-black ${renderBitrate(report.id)==='NaN' || renderBitrate(report.id)==='0' ? 'opacity-30' : ''}`" v-for="report in inboundAudioReports" :key="report.id">
        <span class="inline-block scale-[2] px-2">🔊↓</span> {{ renderBitrate(report.id) }}
      </span>
    </template>
  </div>


</template>

<script>
import {hslToHexString} from '@/lib/hslToHexString'
export default {
  name: 'Debug',
  components: {},
  data() {
    return {
      forcedRenderType: false,
      connection: {},
      getConnectionStatsInterval: null,
      status: '',
      candidatePairData: '',
      inboundVideoReports: [],
      inboundAudioReports: [],
      outboundVideoReports: [],
      outboundAudioReports: [],
      rollingDeltas: {},
    }
  },
  computed: {
  },
  methods: {
    /**
     * Convenience function for stylizing a console log so it stands out (color background and white text)
     * @param str - text to log
     * @param [color] - bg color
     */
    stylizeLog(str,color='red') {
      return ['%c '+str+' ',`color: white; background:${color};`]
    },

    /**
     * Pseudo-random number generator algo mulberry32
     * @param seed
     * @returns
     */
    seededRandom(seed) {
      if(typeof seed === 'string') {
        seed = this.seedFromString(seed)
      }
      let t = seed += 0x6D2B79F5;
      t = Math.imul(t ^ t >>> 15, t | 1);
      t ^= t + Math.imul(t ^ t >>> 7, t | 61);
      return ((t ^ t >>> 14) >>> 0) / 4294967296;
    },

    /**
     * Generates a color (and its opposite) at random from a string.
     * The randomness is seeded/deterministic--so youll always get the same color for a string.
     */
    deterministicRandomColorFromString(str,opposite=false) {
      let h = this.seededRandom(str)*360
      if(opposite) h = (h + 180) % 360
      return hslToHexString(h,100,50)
    },

    /**
     * A sloppy hash that will return a (decently) unique number from a string
     * @param str string to
     * @returns hash
     */
    seedFromString(str) {
      let hash = 0, i, chr, len;
      if (typeof str.length === 'undefined' || str.length === 0)
        return hash;
      for (i = 0, len = str.length; i < len; i++) {
        chr = str.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0;
      }
      return hash;
    },

    /**
     * Convert bytes sent/received over the update interval to human readable formaat (normalized to /1s)
     * @param {number} bytesPerInterval t
     */
    renderBitrate(id) {
      const bytesPerInterval = this.rollingDeltas[id]?.delta
      return (bytesPerInterval/0.25/1024).toFixed()
    },

    /**
     * Manages keeping track of changes in a value between updates, which you can access with this.rollingDeltas[id].delta
     * @param {number} newValue the new value
     * @param {string} id name of the state variable (will be accessed with this[id])
     */
    updateRollingDelta(newValue,id) {
      if(this.rollingDeltas[id]) {
        this.rollingDeltas[id].prev = this.rollingDeltas[id].current
        this.rollingDeltas[id].current = newValue
        this.rollingDeltas[id].delta = this.rollingDeltas[id].current - this.rollingDeltas[id].prev
      } else {
        this.rollingDeltas[id] = {
          current: newValue,
          prev: false,
          delta: false,
        }
      }
    },

    /**
     * Reference OtherPlayer object for id to get nickname
     * @param {string} id
     */
    getPlayerNickname(id) {
      return this.isMyPlayer ?
        window?.phaserScene?.playerManager?.myPlayer?.nickname
        :
        window?.phaserScene?.playerManager?.otherPlayers?.[id]?.nickname ?? id
    },

    /**
     * Dump a massive amount of data to the console concerning this connection
     */
    async logPeer () {
      const volatilePeerStats = await this.connection.volatileChannel.peer._pc.getStats()
      console.log (
        ...this.stylizeLog('[DEBUG LOG CONNECTION DUMP]'),
      )
      console.log (
        ...this.stylizeLog('[PEER ID]'),
        this.connectionId
      )
      console.log (
        ...this.stylizeLog('[CONNECTION DATA]'),
        JSON.parse(JSON.stringify(this.connection))
      )
      console.log (
        ...this.stylizeLog('[PLAYER DATA]'),
        window.phaserScene.playerManager.otherPlayers[this.connectionId]
      )
      console.log(
        ...this.stylizeLog('[WEBRTC PC]'),
        this.connection.volatileChannel.peer._pc
      )
      console.log(
        ...this.stylizeLog('[WEBRTC RECIEVERS]'),
        this.connection.volatileChannel.peer._pc.getReceivers()
      )
      console.log(
        ...this.stylizeLog('[WEBRTC SENDERS]'),
        this.connection.volatileChannel.peer._pc.getSenders()
      )
      console.log (
        ...this.stylizeLog('[WEBRTC STATS]'),
      )
      let codecReports = []
      volatilePeerStats.forEach(report => {
        if(report.type==='codec') codecReports.push(report)
        else
          console.log (
            ...this.stylizeLog(report.type+(report.kind ? ' - '+report.kind : ''),'blue'),
            report
          )
      })
      console.log (
        ...this.stylizeLog('codecs','blue'),
        codecReports
      )
    },

    /**
     * Force this player to disconnect for testing purposes (we hope they will instantly reconnect!)
     */
    disconnectPlayer() {
      console.log(...this.stylizeLog('DEBUG CALLED FOR PLAYER DISCONNECT'))
      this.connection.volatileChannel.peer._channel.close()
    },

    /**
     * Update state variables that render connection statistics (happens on interval)
     */
    async updateConnectionStats() {
      // Get connection object
      this.connection = this.$store.state.networkProvider.connections?.[this.connectionId]

      // Get status
      this.status = JSON.stringify(this.connection?.volatileChannel?.readyState,null,2)

      // Get nickname
      // this.nickname = this.getPlayerNickname(this.connectionId)

      // Get stats into easily filterable array
      const stats = await this.connection?.volatileChannel?.peer?._pc?.getStats()

      if(stats) {
        let reports = []
        stats.forEach(report => reports.push(report))

        // Get each incoming video track bitrate
        this.inboundVideoReports = reports.filter(report => report.type==='inbound-rtp' && report.kind==='video')
        this.inboundVideoReports.forEach(report=>this.updateRollingDelta(report?.bytesReceived,report.id))

        // Get each incoming audio track bitrate
        this.inboundAudioReports = reports.filter(report => report.type==='inbound-rtp' && report.kind==='audio')
        this.inboundAudioReports.forEach(report=>this.updateRollingDelta(report?.bytesReceived,report.id))

        // Get each ougoing video track bitrate
        this.outboundVideoReports = reports.filter(report => report.type==='outbound-rtp' && report.kind==='video')
        this.outboundVideoReports.forEach(report=>this.updateRollingDelta(report?.bytesSent,report.id))

        // Get each ougoing audio track bitrate
        this.outboundAudioReports = reports.filter(report => report.type==='outbound-rtp' && report.kind==='audio')
        this.outboundAudioReports.forEach(report=>this.updateRollingDelta(report?.bytesSent,report.id))

        // Get transport report, which we can use to determine method of p2p connection
        const transportReport = reports.filter(report => report.type==='transport')[0]
        if(transportReport) {
          // Filter candidate pairs into only the one mentioned as used in the tranport report
          const successfulCandidatePairs = reports.filter(report => report.type==='candidate-pair' && report.id === transportReport?.selectedCandidatePairId)

          // Its possible there is none picked (bad connection)
          if(successfulCandidatePairs.length===0)  this.candidatePairData = 'no selected pair in transport report'

          // There should not be more than one, something is up with the code then... warn about it.
          else if(successfulCandidatePairs.length>1)  this.candidatePairData = 'multiple pairs! that shouldnt be...'

          // If we have one...
          else {

            // Parse into local/remote candidate
            const successfulCandidatePair = successfulCandidatePairs[0]
            const localCandidate = reports.filter(report => report.type==='local-candidate' && report.id === successfulCandidatePair?.localCandidateId)[0]
            const remoteCandidate = reports.filter(report => report.type==='remote-candidate' && report.id === successfulCandidatePair?.remoteCandidateId)[0]

            // Grab type/protocal and write into human readable form
            this.candidatePairData = `${localCandidate?.candidateType}/${localCandidate?.protocol} → ${remoteCandidate?.candidateType}/${remoteCandidate?.protocol}`
          }
        } else { this.candidatePairData = 'no transport' }

      }
    }
  },
  mounted() {
    if(!this.isMyPlayer) {
      // Recalculate our stats on an interval
      this.getConnectionStatsInterval = setInterval(this.updateConnectionStats,250)
    }
  },
  beforeUnmount() {
    if(!this.isMyPlayer) {
      // Stop stats rendering interval
      clearInterval(this.getConnectionStatsInterval)
    }
  },
  props: {
    connectionId: String,
    isMyPlayer: {
      type: Boolean,
      default: false,
    }
  }
}

</script>
