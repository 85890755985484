import { hexToString } from "../../lib/hexToString"

/**
 * Takes a white texture and colorizes it, returning the name of the colorized texture.
 * If a colorized texture of same name has already been created by this function before,
 * we don't create it again.  We just re-use it
 * @param {string} textureName
 * @param {number} color hex color e.g. 0xff0000
 * @param {Phaser.Scene} phaserScene
 * @returns {string} colorizedTextureName
 *
 * @example <caption>Colorize a particle with playerColor</caption>
 * ```js
 * this.scene.add.image(0, 0, colorizeTexture(`some-white-particle`, playerColor, phaserScene));
 * ```
 */
export function colorizeTexture(textureName,color,phaserScene) {

  /** The name of the colorized texture */
  const colorizedTextureName = `${textureName}-${hexToString(color)}`

  // Render the colorized texture via canvas if it does not exist
  if (!phaserScene.textures.exists(colorizedTextureName)) {

    // Get data on original texture
    const originalTexture = phaserScene.textures.get(textureName)
    const sourceImage = originalTexture.getSourceImage();
    const sourceWidth = sourceImage.width
    const sourceHeight = sourceImage.height

    // Create a canvas texture to draw our colotrized image
    const colorizedTexture = phaserScene.textures.createCanvas(colorizedTextureName, sourceWidth, sourceHeight);

    // Cover the image in a rectangle of our color
    colorizedTexture.context.globalCompositeOperation = 'source-over'
    colorizedTexture.context.fillStyle = hexToString(color);
    colorizedTexture.context.fillRect(0, 0, sourceWidth, sourceHeight);

    // Add the image with destination-in, which will effectively colorize it
    colorizedTexture.context.globalCompositeOperation = 'destination-in'
    colorizedTexture.context.drawImage(sourceImage,0,0);
  }

  return colorizedTextureName;
}
