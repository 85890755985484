import OtherPlayer from "../game/classes/OtherPlayer";
import { EventEmitter } from "events";
import { generateRandomPlayerColor } from "./generateRandomPlayerColor";

/**
 * Adds a tool that can incrementally add n x arbitrary game objects for benchmark/performance testing
 * Activated and controlled via query string:
 * - `?benchmark=cowhead-sprite` - Using the benchmark paramter activates this tool.  Providing a string specifies the test object
 * - `&benchmark-start-count=100` (optional, default `0`) - Specifies the number of test objects to start with
 *
 * @example <caption>This url tests the "use indicator circle", starting with count 100</caption>
 * `https://moondungeon.com/?benchmark=use-indicator-circle&benchmark-start-count=100`
 */
export default function benchmark() {
  // Parse the query string params
  const params = new URLSearchParams(window.location.search);

  // Abort if no benchmark param is present
  if (!params.get("benchmark")) return;

  // Settings
  const startCount = params.get("benchmark-start-count") ?? 0;
  const defaultIncrement = 10;
  const testObjectName = params.get("benchmark");

  // State
  let nItems = 0;
  let errored = false;

  // Create UI element
  const benchMarkElement = document.createElement("div");
  benchMarkElement.id = "benchmark";
  benchMarkElement.innerHTML = `
    <h1>Benchmark</h1> <span></span><br><input type="number" value="${defaultIncrement}"></input> <button>+</button>
  `;
  document.body.append(benchMarkElement);

  /**
   * The function used to add the test object, obtained from addTestObjectFunctions
   * @param {number} index a unique index for each object
   */
  const addTestObjectFunction = addTestObjectFunctions[testObjectName];
  if (addTestObjectFunction === undefined) {
    console.error(`BENCHMARK: No test object of name "${testObjectName}"`);
    errored = true;
  }

  /**
   * Renders the benchmark UI
   */
  function renderUI() {
    // Reset UI
    benchMarkElement.querySelector("span").innerHTML = "";
    benchMarkElement.classList.remove("benchmark--error");

    // Two states
    if (errored) {
      benchMarkElement.querySelector("span").innerHTML = "ERROR!";
      benchMarkElement.classList.add("benchmark--error");
    } else {
      benchMarkElement.querySelector(
        "span"
      ).innerHTML = `<code>${testObjectName}</code><br>count:${nItems}`;
    }
  }

  /**
   * Adds n items to the scene via render
   * @param {number} n
   */
  function addItems(n) {
    try {
      for (let i = 0; i < n; i++) {
        addTestObjectFunction(nItems); // nItems makes a good index
        nItems++;
      }
    } catch (err) {
      console.error(err);
      errored = true;
    }
    renderUI();
  }

  // Add starting items
  addItems(startCount);

  // Listen for clicks to button to add incrementSize
  benchMarkElement.querySelector("button").addEventListener("click", () => {
    const incrementSize = benchMarkElement.querySelector("input").value;
    addItems(incrementSize);
  });
}

/**
 * Generate a random position on camera
 * @param {Phaser.Scene} scene
 * @returns {Position}
 */
function randomWorldPositionOnCamera(scene) {
  const { width, height, x, y } = scene.cameras.main.worldView;
  return {
    x: Math.random() * width + x,
    y: Math.random() * height + y,
  };
}

/**
 * Generates fake stable state data for a player
 * @param {Phaser.Scene} scene
 * @returns {StableState}
 */
function createFakeStableState(scene) {
  return {
    player: {
      playerColor: generateRandomPlayerColor(),
      currentRoomId: phaserScene.room.id,
      tvbot: false,
      betweenRooms: false,
      visibility: 1,
      nickname: "bot-" + Math.random(),
    },
    gadgets: {
      top: {
        type: "fedora",
        name: "fedora of destiny",
        options: {},
      },
      left: {
        type: "gifLauncher",
        name: "magic toaster of many gifs",
        options: {
          keyword: "anime",
        },
      },
      bottom: {
        type: "buttThruster",
        name: "butt thruster 9000",
        options: {},
      },
      right: {
        type: "gator",
        name: "rawr gator",
        options: {},
      },
    },
  };
}

/**
 * Generates fake volatile state data for a player
 * @param {Phaser.Scene} scene
 * @param {Position} [currentPosition]
 * @returns {VolatileState}
 */
function createFakeVolatileState(scene, currentPosition = null) {
  let position;
  const maxSpeed = 100;
  const { width, height } = scene.cameras.main.worldView;
  if (currentPosition === null) position = randomWorldPositionOnCamera(scene);
  else {
    position = {
      x: Math.min(
        Math.max(0, currentPosition.x + (Math.random() * 2 - 1) * maxSpeed),
        width
      ),
      y: Math.min(
        Math.max(0, currentPosition.y + (Math.random() * 2 - 1) * maxSpeed),
        height
      ),
    };
  }

  // I copied this from a real incoming heartbeat, swapping real values for randomized ones.
  return {
    player: {
      x: position.x,
      y: position.y,
      rotation: ((Math.random() * 2 - 1) * Math.PI) / 4,
      energy: Math.random(),
      scaleMultiplier: 1,
      alpha: 1,
      betweenRooms: false,
    },
    lastActive: Date.now(),
    gadgets: {
      top: {
        bankedEnergy: Math.random(),
      },
      left: {
        dangleRotation: ((Math.random() * 2 - 1) * Math.PI) / 4,
        bankedEnergy: Math.random(),
      },
      bottom: {
        dangleRotation: ((Math.random() * 2 - 1) * Math.PI) / 4,
        bankedEnergy: Math.random(),
      },
      right: {
        dangleRotation: ((Math.random() * 2 - 1) * Math.PI) / 4,
        bankedEnergy: Math.random(),
      },
    },
  };
}

/**
 * Creates a network handler stub that can replace NetworkProvider.
 * This stub will fire a heartbeat to any subscribers, just like the real thing--but with fake/random data.
 *
 * We store this network handler in a context you pass in.  The context will be mutated, adding:
 * - `context.networkProviderStub` - the actual stub that you can pass to players
 * - `context.otherPlayers` - an array of all OtherPlayer objects (you will need to keep this up to date)
 *
 * @param {Phaser.Scene} scene
 * @param {Object} context An object that will be mutated to hold the networkProvider stub and any associated properties and methods
 */
function addNetworkProviderStubWithFakeUpdates(scene, context) {
  /**
   * Stores all otherPlayers using this stub (not automatically--you need to keep this up to date)
   * We'll reference this to e.g. get the player position when making fake data
   */
  context.otherPlayers = [];

  /**
   * Create a network handler stub that replaces the functions OtherPlayer uses mostly empty functions.
   */
  context.networkProviderStub = {
    events: new EventEmitter(),
    requestPeerStableState: (peerId) => {
      // Normally this would send a peer message requesting the full stable state the moment
      context.otherPlayers[peerId].updateStableState(
        createFakeStableState(scene)
      );
    },
  };

  // Send our subscribers a fake heartbeat
  setInterval(() => {
    // (Loops are a little faster... maybe it doesnt matter--but we are benchmarking after all!)
    const nOtherPlayers = context.otherPlayers.length;
    for (let i = 0; i < nOtherPlayers; i++) {
      // Send a fake state directly to each OtherPlayers functions to receive state messaages
      context.otherPlayers[i].updateVolatileState(
        createFakeVolatileState(scene, {
          x: context.otherPlayers[i].playerPhaserObject.x,
          y: context.otherPlayers[i].playerPhaserObject.y,
        })
      );
    }
  }, 30);
}

/**
 * Creates an OtherPlayer with id in scene.
 * Configures the other player to use a stub network handler (that sends a heartbeat)
 * Creates said network handler if it hasn't already been created
 * @param {number} id
 * @param {Phaser.Scene} scene
 * @returns {OtherPlayer}
 */
function spawnTestOtherPlayer(id, scene) {
  // Create the netowrkHandler stub if it has not yet been created
  if (testObjectGlobals.networkProviderStub === undefined) {
    addNetworkProviderStubWithFakeUpdates(scene, testObjectGlobals);
  }

  // Create an OtherPlayer, mimicing the creation used in PlayerManager
  const otherPlayer = new OtherPlayer(scene, id, {
    networkProvider: testObjectGlobals.networkProviderStub,
  });

  // Add this object to our globals
  // Do this before creation
  testObjectGlobals.otherPlayers.push(otherPlayer);

  // Fire create function--do so after this object has been pushed to global OtherPlayer list (above)
  otherPlayer.create();
  otherPlayer.afterCreate();
  const position = randomWorldPositionOnCamera(scene);
  otherPlayer.playerPhaserObject.x = position.x;
  otherPlayer.playerPhaserObject.y = position.y;

  // Update the OtherPlayer on scene update (todo: hmmmm... should OtherPlayer handle this itself?)
  scene.events.on("update", otherPlayer.update);

  return otherPlayer;
}

/**
 * An object to store any global variables
 */
let testObjectGlobals = {};

/**
 * An object containing all the functions that can be used to add an object.
 * Use a key of the object name to access the function that will add it
 */
const addTestObjectFunctions = {
  // Use-indicator graphic
  "use-indicator-circle": function (index) {
    const scene = window.phaserScene;
    const position = randomWorldPositionOnCamera(scene);

    const useIndicatorCircle = scene.add.circle(position.x, position.y, 200);
    useIndicatorCircle.name = "use-indicator";
    useIndicatorCircle.setStrokeStyle(50, 0xff0000);
  },

  // A test sprite
  "cowhead-sprite": function (index) {
    const scene = window.phaserScene;
    const position = randomWorldPositionOnCamera(scene);

    scene.add.image(position.x, position.y, "cowhead");
  },

  // The other player object, with networkProvider replaces by a stub that will send fake heartbeats
  "other-player": function (index) {
    const scene = window.phaserScene;

    // My id will just be the test object index
    const id = index;

    const otherPlayer = spawnTestOtherPlayer(id, scene);
  },

  // Ditto 'other-player', but each player is mashing use key
  "other-player-using": function (index) {
    const scene = window.phaserScene;

    // My id will just be the test object index
    const id = index;

    const otherPlayer = spawnTestOtherPlayer(id, scene);

    setInterval(() => {
      otherPlayer.handleRecieveRelay({
        type: "use",
        options: {
          x: otherPlayer.playerPhaserObject.x,
          y: otherPlayer.playerPhaserObject.y,
        },
      });
    }, 200);
  },

  // Ditto 'other-player', but each player is mashing butt thruster
  "other-player-butt-thrusting": function (index) {
    const scene = window.phaserScene;

    // My id will just be the test object index
    const id = index;

    const otherPlayer = spawnTestOtherPlayer(id, scene);

    setInterval(() => {
      otherPlayer.handleRecieveRelay({
        type: "gadget",
        options: {
          slot: "bottom",
          angle: (Math.random() * 2 - 1) * Math.PI,
          energy: Math.random(),
        },
        sender: id,
      });
    }, 200);
  },

  // Ditto 'other-player', but each player is in tv-bot mode
  "other-player-tv-bot": function (index) {
    const scene = window.phaserScene;

    // My id will just be the test object index
    const id = index;

    const otherPlayer = spawnTestOtherPlayer(id, scene);
    otherPlayer.setTVBot(true);
  },

  // Ditto 'other-player', but each player is mashing gator
  "other-player-gatoring": function (index) {
    const scene = window.phaserScene;

    // My id will just be the test object index
    const id = index;

    const otherPlayer = spawnTestOtherPlayer(id, scene);

    setInterval(() => {
      otherPlayer.handleRecieveRelay({
        type: "gadget",
        options: {
          slot: "right",
          energy: 0.2,
        },
        sender: id,
      });
    }, 200);
  },

  // Ditto 'other-player', but each player is mashing gif launcher
  "other-player-gif-launching": function (index) {
    const scene = window.phaserScene;

    // My id will just be the test object index
    const id = index;

    const otherPlayer = spawnTestOtherPlayer(id, scene);

    setInterval(() => {
      otherPlayer.handleRecieveRelay({
        type: "gadget",
        options: {
          slot: "left",
          angle: (Math.random() * 2 - 1) * Math.PI,
          gifURL: "https://media0.giphy.com/media/dvk8i2jAT2GrK/giphy.gif",
          energy: Math.random(),
        },
        sender: id,
      });
    }, 200);
  },

  // Ditto 'other-player', but each player is mashing hat tip
  "other-player-hat-tipping": function (index) {
    const scene = window.phaserScene;

    // My id will just be the test object index
    const id = index;

    const otherPlayer = spawnTestOtherPlayer(id, scene);

    setInterval(() => {
      otherPlayer.handleRecieveRelay({
        type: "gadget",
        options: {
          slot: "top",
          tipHat: true,
          energy: Math.random(),
        },
        sender: id,
      });
    }, 200);
  },

  // Ditto 'other-player', but each player is chatting
  "other-player-chatting": function (index) {
    const scene = window.phaserScene;

    // My id will just be the test object index
    const id = index;

    const otherPlayer = spawnTestOtherPlayer(id, scene);

    setInterval(() => {
      otherPlayer.handleRecieveRelay({
        type: "gadget",
        options: {
          slot: "top",
          msg: "penis",
        },
        sender: id,
      });
    }, 800);
  },
};
