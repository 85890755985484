<template>
  <div class="uppercase text-green font-head">{{ control.label }}</div>
  <div class="flex gap-2 items-center">
    <HotkeyButton
      class="flex-shrink-0"
      :hotkey="control.minusHotkey"
      :action="minus"
      :allowHold="true"
    >
      -
    </HotkeyButton>
    <input
      class="slider border-green border bg-black accent-green h-8 appearance-none"
      type="range"
      :min="control.min"
      :max="control.max"
      :step="control.step"
      v-model="control.value"
      @mousedown="startSending()"
      @mouseup="stopSending()"
      @click="sendValue()"
    />
    <HotkeyButton
      class="flex-shrink-0"
      :hotkey="control.plusHotkey"
      :action="plus"
      :allowHold="true"
    >
      +
    </HotkeyButton>
  </div>
</template>

<script>
import HotkeyButton from '@/components/PerformerControls/HotkeyButton'
import { sendMessageToCurrentRoom } from "@/lib/sendMessageToCurrentRoom";

export default {
  name: "SliderPerformerControl",
  components: {HotkeyButton},
  data() {
    return {
      sendInterval: null,
    };
  },
  methods: {
    plus() {
      this.control.value = Math.min(this.control.max, parseFloat(this.control.value)+this.control.hotKeyStep);
      this.sendValue();
    },
    minus() {
      this.control.value = Math.max(this.control.min, parseFloat(this.control.value)-this.control.hotKeyStep);
      this.sendValue();
    },
    startSending() {
      this.sendInterval = setInterval(() => {
        this.sendValue();
      }, 200);
    },
    stopSending() {
      this.sendValue();
      clearInterval(this.sendInterval);
    },
    sendValue() {
      if (this.control.action) {
        this.control.action(this.control.value);
      } else {
        sendMessageToCurrentRoom(
          "performer-action-triggered-" + this.control.id,
          parseFloat(this.control.value)
        );
      }
    },
  },
  mounted() {},
  unmounted() {
    clearInterval(this.sendInterval);
  },
  props: {
    control: Object,
  },
};
</script>

<style scoped>

.slider::-webkit-slider-thumb {
  @apply appearance-none w-8 h-8 cursor-pointer bg-green;
}
</style>
