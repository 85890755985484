import store from "@/lib/store";
import $ from "jquery";
export function onSendChat(callback) {
  $("#chatbox").show();
  $(document).on("keydown", function (e) {
    if (e.key == "Enter") handleEnter(callback);
  });
}

function handleEnter(callback) {
  if (
    $("#chatbox input").is(":focus") &&
    $("#chatbox input").val().length > 0
  ) {
    callback($("#chatbox input").val());
    $("#chatbox input").val("");
    $("#chatbox input").blur();
  } else {
    $("#chatbox input").focus();
  }
}

export function createSpeechBubble(
  phaserScene,
  x,
  y,
  width,
  height,
  msg,
  options = {}
) {
  const defaultOptions = {
    fontSize: 20,
    color: "#000000",
    fontFamily: "Arial",
    bubbleColor: 0xffffff,
    bubbleStroke: 0x565656,
    italic: false,
    alpha: 1,
  };
  options = { ...defaultOptions, ...options };

  var bubbleWidth = width;
  var bubbleHeight = height;
  var bubblePadding = 10;
  var arrowHeight = bubbleHeight / 4;

  var bubble = phaserScene.add.graphics({ x: x, y: y });

  //  Bubble shadow
  bubble.fillStyle(0x222222, 0.5);
  bubble.fillRoundedRect(6, 6, bubbleWidth, bubbleHeight, 16);

  //  Bubble color
  bubble.fillStyle(options.bubbleColor, 1);

  //  Bubble outline line style
  bubble.lineStyle(4, options.bubbleStroke, 1);

  //  Bubble shape and outline
  bubble.strokeRoundedRect(0, 0, bubbleWidth, bubbleHeight, 16);
  bubble.fillRoundedRect(0, 0, bubbleWidth, bubbleHeight, 16);

  //  Calculate arrow coordinates
  var point1X = Math.floor(bubbleWidth / 7);
  var point1Y = bubbleHeight;
  var point2X = Math.floor((bubbleWidth / 7) * 2);
  var point2Y = bubbleHeight;
  var point3X = Math.floor(bubbleWidth / 7);
  var point3Y = Math.floor(bubbleHeight + arrowHeight);

  //  Bubble arrow shadow
  bubble.lineStyle(4, 0x222222, 0.5);
  bubble.lineBetween(point2X - 1, point2Y + 6, point3X + 2, point3Y);

  //  Bubble arrow fill
  bubble.fillTriangle(point1X, point1Y, point2X, point2Y, point3X, point3Y);
  bubble.lineStyle(2, options.bubbleStroke, 1);
  bubble.lineBetween(point2X, point2Y, point3X, point3Y);
  bubble.lineBetween(point1X, point1Y, point3X, point3Y);

  bubble.setAlpha(options.alpha);

  var content = phaserScene.add.text(0, 0, msg, {
    fontFamily: options.fontFamily,
    fontSize: options.fontSize,
    color: options.color,
    fontStyle: options.italic ? "italic" : "normal",
    align: "center",
    wordWrap: { width: bubbleWidth - bubblePadding * 2 },
  });

  var b = content.getBounds();

  content.setPosition(
    bubble.x + bubbleWidth / 2 - b.width / 2,
    bubble.y + bubbleHeight / 2 - b.height / 2
  );
  content.setAlpha(options.alpha);

  setTimeout(function () {
    bubble.destroy();
    content.destroy();
  }, 2000);
}
