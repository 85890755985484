import store from '@/lib/store'

// import Gadget from './Gadget'
import Fedora from './gadgets/Fedora'
import Gator from './gadgets/Gator'
import GifLauncher from './gadgets/GifLauncher'
import ButtThruster from './gadgets/ButtThruster'
import {getEmptyInventoryTemplate} from '@/lib/constants'
import deepEqual from 'fast-deep-equal'
import { playerContainerWidth } from '../../lib/constants'
import loggers from '../../loggers'

export default class GadgetManager  {
  constructor (phaserScene,player,options={}) {
    this.phaserScene = phaserScene
    this.player = player
    this.gadgets = getEmptyInventoryTemplate()
    this.gadgetTypes = {
      fedora: Fedora,
      gator: Gator,
      buttThruster: ButtThruster,
      gifLauncher: GifLauncher
    }

    this.resetGadgetPositions()
  }



  create () {
    loggers.Gadgets('Creating container')
    this.gadgetContainer = this.phaserScene.add.container()

    this.player.playerPhaserObject.add(this.gadgetContainer)
    if(this.player.isMyPlayer) {
      this.updateGadgetsStableState(store.getters.stableState.gadgets)
    }
  }

  activateGadget = (slot, energy) => {
    if(this.gadgets[slot]) this.gadgets[slot].activate(energy)
  }

  // This fires is Player gets a relay of type "gadget"
  handleRecieveRelay = (data) => {
    if(this.player.outsideCurrentRoom) return // Dont pass relays to players who are outside the current room
    this.gadgets[data.options.slot].handleRecieveRelay(data)
  }

  /**
   * Route gadgets volatile state update to each gadget
   * @param {GadgetsVolatileState} gadgetsVolatileState
   */
  updateGadgetsVolatileState = (gadgetsVolatileState) => {
    for(const slot in gadgetsVolatileState) {
      if(this.gadgets[slot] && this.gadgets[slot].updateGadgetVolatileState)
      this.gadgets[slot].updateGadgetVolatileState(gadgetsVolatileState[slot])
    }
  }

  /**
   * Handle updates to the gadgets stable state, largely:
   * - Detect any gadgets that changed
   * - Destroy the corresponding Gadget
   * - Create a new Gadget in its place
   * @param {GadgetsStableState} gadgetsStableState
   */
  updateGadgetsStableState = (gadgetsStableState) => {

    // Loop through the slots in this.gadgets and see if there are any changes
    for (const slot in this.gadgets){

      if(typeof gadgetsStableState[slot] !== 'undefined' && !deepEqual(this.gadgets[slot].originalConfig,gadgetsStableState[slot])) {

        // Destroy our old Gadget if it exists
        if(this.gadgets[slot]?.destroy) {
          this.gadgets[slot].destroy()
        }

        // Find the class for the gadget type (return if not there)
        const GadgetToAdd = this.gadgetTypes[gadgetsStableState[slot].type]
        if (GadgetToAdd) {

          // Create our gadget
          this.gadgets[slot] = new GadgetToAdd({
            phaserScene: this.phaserScene,
            player: this.player,
            gadgetOptions: gadgetsStableState[slot].options,
            gadgetManager: this,
          })

          // It wants to know about its slot
          this.gadgets[slot].slot = slot

          // Give it this original stable state obj as its original config,
          // that way we can deeply compare with future updates to see if this gadget changed
          this.gadgets[slot].originalConfig = gadgetsStableState[slot]

          // Fire creation functions to start the gadget up
          this.gadgets[slot].create()
          this.gadgets[slot].afterCreate()
        }
      }
    }
  }

  /**
   * Inits or resets gadget offsets--which will be used to animate change gadget positions from normal
   */
  resetGadgetPositions() {
    this.gadgetPositions = {
      top: {x: 0, y: -playerContainerWidth/2},
      left: {x: -playerContainerWidth/2, y: 0},
      right: {x: playerContainerWidth/2, y: 0},
      bottom: {x: 0, y: playerContainerWidth/2},
    }
  }

  update () {
    // Trigger update method in myGadget and all other Gadgets
    for (const slot in this.gadgets) {
      if(this.gadgets[slot]) {
        if(this.player.isMyPlayer && this.gadgets[slot].updateStateWithBankedEnergyFromMyPlayer) this.gadgets[slot].updateStateWithBankedEnergyFromMyPlayer()
        if(this.gadgets[slot].update) this.gadgets[slot].update()
        if(this.player.isMyPlayer && this.gadgets[slot].sendVolatileStateToStore) this.gadgets[slot].sendVolatileStateToStore()
      }
    }
  }

  destroy() {
    // Trigger destroy method in myGadget and all other Gadgets
    for (const slot in this.gadgets) {
      if(this?.gadgets[slot]?.destroy) this.gadgets[slot].destroy()
    }

    this.gadgetContainer.destroy()
  }
}
