<template>
  <!-- This UI will be hidden for all non-performers.
  But its critical that this video element still exist (even if display:noned)
  --as phaser uses it to get the livestream video for graphics -->
  <div
    :class="`${
      isPerformer
        ? `border-l-8 border-b-4 border-green relative ${
            publishingStatus === 'on' ? 'bg-[red]' : ''
          }`
        : ' hidden'
    }`"
  >
    <!-- Row containing video elements and go live button -->
    <div class="flex gap-2 justify-center h-[6rem] w-[25vw]">
      <!-- This square box holds livestream video element and indicators -->
      <div class="bg-black border aspect-square relative w-full">
        <!-- Loading spinner, shows when publishing but video not ready -->
        <div
          class="
            w-8
            h-8
            absolute
            top-1/2
            left-1/2
            -translate-x-1/2 -translate-y-1/2
          "
          v-if="publishingStatus === 'on'"
        >
          <Spinner />
        </div>

        <!-- Remote Livestream video element -->
        <div
          class="h-full relative flex flex-row justify-around"
          id="livestream-subscriber-video"
        >
          <video
            v-for="(element, id) in this.remoteVideoElements"
            :key="id"
            :muted="publishingStatus === 'on' || subscribedLivestreamVideoMuted"
            :srcObject="element.srcObject"
            :id="id"
            @click="selectVideoElement"
            autoplay
          />
        </div>

        <!-- Livestream Label -->
        <div
          class="
            uppercase
            absolute
            pointer-events-none
            bottom-0
            left-0
            font-head
            text-[0.7rem]
            bg-opacity-80
            px-1
            bg-[red]
          "
        >
          Livestream
        </div>
      </div>

      <!-- Go live! or Stop! button -->
      <div
        class="w-20 grid align-middle items-center text-center"
        v-if="canPublish"
      >
        <button
          class="button bg-[red] text-white h-12 w-20 self-center"
          v-if="canPublish && publishingStatus === 'off'"
          @click="startPublishing()"
        >
          GO LIVE
        </button>
        <div
          class="
            bg-black
            text-white
            h-12
            w-20
            self-center
            grid
            place-content-center
          "
          v-if="canPublish && publishingStatus === 'transitioning'"
        >
          <div class="w-6 h-6">
            <Spinner />
          </div>
        </div>
        <button
          class="button bg-black text-white h-12 w-20 self-center"
          v-if="canPublish && publishingStatus === 'on'"
          @click="stopPublishing()"
        >
          STOP
        </button>
      </div>
      <!-- This square box holds user media video element and indicators -->
      <div class="bg-black border aspect-square relative" v-if="canPublish">
        <!-- Loading spinner, shows before gotten user media -->
        <div
          class="
            w-8
            h-8
            absolute
            top-1/2
            left-1/2
            -translate-x-1/2 -translate-y-1/2
          "
        >
          <Spinner />
        </div>

        <!-- User media monitor video element -->
        <video
          class="h-full pointer-events-none relative"
          ref="userMediaFeedVideo"
          muted
          autoplay
          id="livestream-publisher-video"
        />

        <!-- Label -->
        <div
          class="
            uppercase
            absolute
            bottom-0
            left-0
            font-head
            text-[0.7rem]
            bg-opacity-80
            px-1
            bg-dark-gray
          "
        >
          Your cam
        </div>
      </div>
    </div>

    <!-- Collection of controls to choose user media devices -->
    <div class="p-6 w-[25vw]" v-if="canPublish">
      <!-- Select audio devices -->
      <label class="flex gap-2">
        <span class="text-white font-head">A:</span>
        <select
          class="bg-black border border-green text-green text-[0.7rem] w-[12vw]"
          v-model="currentAudioInputDevice"
        >
          <option
            v-for="device in audioInputDevices"
            :key="device.deviceId"
            :value="device.deviceId"
          >
            {{ device.label }}
          </option>
        </select>
      </label>

      <!-- Select video devices -->
      <label class="flex gap-2">
        <span class="text-white font-head">V:</span>
        <select
          class="bg-black border border-green text-green text-[0.7rem] w-[12vw]"
          v-model="currentVideoInputDevice"
        >
          <option
            v-for="device in videoInputDevices"
            :key="device.deviceId"
            :value="device.deviceId"
          >
            {{ device.label }}
          </option>
        </select>
      </label>
    </div>
    <div
      class="absolute bottom-0 right-0 text-white p-4 text-right leading-none"
    >
      <div class="text-xl">
        {{ Object.keys(globalConnectionsSummary).length }}
      </div>
      <div class="text-sm">{{ percentConnected }}</div>
    </div>
  </div>
</template>
<style scoped>
.currentLivestreamVideoElement {
  border: 5px solid gold;
}
</style>
<script>
import loggers from "@/loggers";
import LivestreamSubscriber from "@/lib/LivestreamSubscriber";
import LivestreamPublisher from "@/lib/LivestreamPublisher";
import Spinner from "@/components/Spinner";
import store from "@/lib/store";

export default {
  name: "Livestream",
  components: {
    Spinner,
  },
  computed: {
    percentConnected() {
      const keys = Object.keys(this.globalConnectionsSummary);
      const nGoodConnections = keys.reduce((total, key) => {
        return total + this.globalConnectionsSummary[key].connected;
      }, 0);
      const totalNeeded = (keys.length - 1) * keys.length;
      const percent = (nGoodConnections / totalNeeded) * 100;
      return `${percent.toFixed(0)}%`;
    },
  },
  data() {
    return {
      /** Reference to LivestreamSubscriber, created in mount */
      livestreamSubscriber: null,
      remoteVideoElements: {},
      /** Reference to LivestreamPublisher, created in mount */
      livestreamPublisher: null,
      /** Whether the video is muted (always true for a livestreamer), will become false for others after usergesture */
      subscribedLivestreamVideoMuted: true,
      /**
       * Whether or not we are publishing:
       * - 'off' - not publishing
       * - 'on' - successfully publishing
       * - 'tranisitioning' - state change initiatied but not confirmed by server
       * @type {'off'|'on'|'transitioning'}
       */
      publishingStatus: "transitioning",
      /** Whether we are capable of livestreaming (from query string) */
      canPublish:
        new URLSearchParams(location.search).get("livestream") === "true",
      /** Whether we are a performer (from query string) */
      isPerformer:
        new URLSearchParams(location.search).get("performer") === "true",
      /** Audio devices available for user media */
      audioInputDevices: [],
      /** deviceId of currently used audio input device */
      currentAudioInputDevice: false,
      /** Video devices available for user media */
      videoInputDevices: [],
      /** deviceId of currently used video input device */
      currentVideoInputDevice: false,
      /** copy of globalConnectionsSummary report (who is connected to who) */
      globalConnectionsSummary: {},
    };
  },

  async mounted() {
    console.log(store.getters.playerList);
    loggers.Livestream("Livestream.vue mounted");

    // Create subscriber to manage receiving livestream
    this.livestreamSubscriber = new LivestreamSubscriber();
    this.livestreamSubscriber.init();
    this.remoteVideoElements = this.livestreamSubscriber.videoElements;
    this.livestreamSubscriber.events.on("livestream-track-update", () => {
      console.log("new track detected!");
      this.remoteVideoElements = { ...this.livestreamSubscriber.videoElements };
    });
    // Down the line, we'll want better infrastructure for passing this object to other components
    // Maybe VUEX. But this area is going to be greatly impacted by near-term move to having multiple streams
    // So just passing this to the window for now.
    // This is ACTIVELY consumed by LivestreamTextureManger
    window.livestreamSubscriber = this.livestreamSubscriber;

    // If we can publish, create publisher to manage publishing livestream
    if (this.canPublish) {
      this.livestreamPublisher = new LivestreamPublisher(
        "livestream-publisher-video"
      );
      this.livestreamPublisher.init();

      // This is not being used by our, but is here as a temporary convenience for console exploration
      window.livestreamPublisher = this.livestreamPublisher;

      // Get available devices for UI (dropdowns)
      this.livestreamPublisher.events.on("got-available-devices", (devices) => {
        loggers.Livestream("Livestream.vue got available devices", devices);
        this.audioInputDevices = devices.audio;
        this.videoInputDevices = devices.video;
      });

      // Get devices actually used (sets the dropdown targets)
      this.livestreamPublisher.events.on("initialized", () => {
        this.currentVideoInputDevice = this.livestreamPublisher.videoDeviceId;
        this.currentAudioInputDevice = this.livestreamPublisher.audioDeviceId;
        this.publishingStatus = "off";
      });

      // Let the UI know if the livestream is publishing successfully!
      this.livestreamPublisher.events.on("started", () => {
        this.publishingStatus = "on";
      });
      this.livestreamPublisher.events.on("stopped", () => {
        this.publishingStatus = "off";
      });
      this.livestreamPublisher.events.on("failed", () => {
        this.publishingStatus = "off";
      });
    }

    // Unmute with a user gesture
    this.listenForUserGesture(() => {
      loggers.Livestream(
        "Recieved user gesture. Setting subscribedLivestreamVideoMuted to true"
      );
      // this.subscribedLivestreamVideoMuted = false;
    });

    // Get the global connections summary report from the server by listening to the event that is triggered when the server sends updates
    window.networkProvider.events.on(
      "global-connections-summary-update",
      (report) => {
        this.globalConnectionsSummary = report;
      }
    );
  },

  watch: {
    /** Whenever the currentAudioInputDevice changes (e.g. from the select), reget user media */
    currentAudioInputDevice(newVal, oldVal) {
      // Don't do anything if this either didn't change (oldVal===newVal)
      // or this is the first time setting it (oldVal===false)
      // (this var will be set by this.getUserMedia()'s first call on mount--changing it from false to the default deviceId,
      // we will want to not call getUserMedia again at this point)
      if (newVal === oldVal || oldVal === false) return;

      // Otherwise reget user media
      loggers.Livestream(
        "User changed currentAudioInputDevice via select",
        newVal
      );
      this.setAudioDevice(newVal);
    },
    /** Whenever the currentVideoInputDevice changes (e.g. from the select), reget user media */
    currentVideoInputDevice(newVal, oldVal) {
      // Don't do anything if this either didn't change (oldVal===newVal)
      // or this is the first time setting it (oldVal===false)
      // (this var will be set by this.getUserMedia()'s first call on mount--changing it from false to the default deviceId,
      // we will want to not call getUserMedia again at this point)
      if (newVal === oldVal || oldVal === false) return;

      // Otherwise reget user media
      loggers.Livestream(
        "User changed currentVideoInputDevice via select",
        newVal
      );
      this.setVideoDevice(newVal);
    },
  },

  methods: {
    /**
     * Tells the livestreamPublisher we want to start our livestream
     */
    startPublishing() {
      // LivestreamProvider will take it from here
      this.livestreamPublisher?.start();
      this.publishingStatus = "transitioning";
    },

    /**
     * Tells the livestreamPublisher we want to end our livestream
     */
    stopPublishing() {
      this.livestreamPublisher?.stop();
      this.publishingStatus = "transitioning";
    },

    /**
     * Tells our publisher the new desired video device
     */
    setVideoDevice(deviceId) {
      loggers.Livestream("Livestream.vue setVideoDevice()", deviceId);
      this.livestreamPublisher.videoDeviceId = deviceId;
    },

    /**
     * Tells our publisher the new desired audio device
     */
    setAudioDevice(deviceId) {
      loggers.Livestream("Livestream.vue setAudioDevice()", deviceId);
      this.livestreamPublisher.audioDeviceId = deviceId;
    },

    /**
     * Fires callback with any user gesture that can start audio (once only)
     */
    listenForUserGesture(callback) {
      /**
       * A list of events that constitute a user-gesture
       * @see https://html.spec.whatwg.org/multipage/interaction.html#user-activation-processing-model
       */
      const userGestureEvents = [
        "keydown",
        "mousedown",
        "pointerdown",
        "pointerup",
        "touchend",
      ];

      /**
       * A listener function that runs callback() and then removes itself from all userGestureEvents event listeners
       */
      const fireCallbackAndRemoveEventListeners = () => {
        callback();

        // Remove listener from all user gesture events
        userGestureEvents.forEach((eventName) => {
          window.removeEventListener(
            eventName,
            fireCallbackAndRemoveEventListeners
          );
        });
      };

      // Add listener function to all user gesture events
      userGestureEvents.forEach((eventName) => {
        window.addEventListener(eventName, fireCallbackAndRemoveEventListeners);
      });
    },

    /**
     * Retrieves available devices for user media, sets our reactive data which will be used to populate selects/UI
     * @see https://github.com/webrtc/samples/blob/gh-pages/src/content/devices/input-output/js/main.js
     */
    async getAvailableMediaInputDevices() {
      const devices = await navigator.mediaDevices.enumerateDevices();

      this.audioInputDevices = devices.filter(
        (device) => device.kind === "audioinput"
      );
      this.videoInputDevices = devices.filter(
        (device) => device.kind === "videoinput"
      );
    },
    /**
     * Sets a video element to be the source for the LivestreamTextureManager
     */
    selectVideoElement(event) {
      if (event) console.log(event.target?.id);
      if (event.target.classList.contains("currentLivestreamVideoElement")) {
        event.target.classList.remove("currentLivestreamVideoElement");
        store.commit("updatePlayerStableState", {
          tvbot: false,
          tvbot_id: null,
        });
      } else {
        document
          .querySelector(".currentLivestreamVideoElement")
          ?.classList?.remove("currentLivestreamVideoElement");
        event.target.classList.add("currentLivestreamVideoElement");
        // event.target.muted = !event.target.muted;
        store.commit("updatePlayerStableState", { tvbot_id: event.target.id });
      }
    },
  },
};
</script>