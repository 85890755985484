<template>
  <div
    :class="control.teleprompterMode ? `
      fixed top-0 left-[37.5vw] max-w-[75vw] -translate-x-1/2
      w-max
      px-4 py-2
      text-xl
      border border-green bg-black bg-opacity-60` : ''"
  >
     <span class="uppercase text-green font-head">{{ control.label }}:</span> <span>{{ control.steps[control.current] }}</span>
  </div>
  <div class="uppercase text-green font-head" :v-if="control.teleprompterMode">{{ control.label }}</div>
  <div class="flex gap-2 align-middle">

    <HotkeyButton
      :hotkey="control.prevHotkey"
      :action="prev"
    >
      prev
    </HotkeyButton>
    <div class="text-xl">{{ control.current }}</div>
    <HotkeyButton
      :hotkey="control.nextHotkey"
      :action="next"
    >
      next
    </HotkeyButton>
  </div>
</template>

<script>
import HotkeyButton from '@/components/PerformerControls/HotkeyButton'
import {sendMessageToCurrentRoom} from '@/lib/sendMessageToCurrentRoom'
export default {
  name: 'InputPerformerControl',
  components: {HotkeyButton},
  data() {
    return {
    }
  },
  methods: {
    /**
     * If there are remaining steps, control.current++ via the store
     */
    next() {
      const nSteps = this.control.steps.length
      if(this.control.current<nSteps-1) {
        // Update the control directly in the store, which will proliferate to this template
        this.$store.commit('updateRoomPerfomerControl', {
          id: this.control.id,
          update: {
            current: this.control.current+1
          }
        })
        this.triggerPerformerAction(this.control.current+1)
      }
    },

    /**
     * If there are remaining steps, control.current-- via the store
     */
    prev() {
      if(this.control.current>0) {
        // Update the control directly in the store, which will proliferate to this template
        this.$store.commit('updateRoomPerfomerControl', {
          id: this.control.id,
          update: {
            current: this.control.current-1
          }
        })
        this.triggerPerformerAction(this.control.current-1)
      }
    },

    /**
     * Triggers performer action in room, passing step number
     */
    triggerPerformerAction(step) {
      sendMessageToCurrentRoom('performer-action-triggered-'+this.control.id, step)
    },
  },
  mounted() {
  },
  props: {
    control: Object,
  }
}
</script>
