import { SimpleMediasoupPeer } from "simple-mediasoup-peer-client";
import LivestreamDefaults from "./LivestreamDefaults.js";

/**
 * This object manages subscibing to an AntMedia Livestream.
 *
 * This will
 * - Detect the livestream id via the current cohort
 * - Establish a connection with AntMedia livestream server
 * - Retrieve and play a Livestream if one is going
 * - If nothing is playing, this will rapidly check to see if a new stream has started
 *
 * `this.events` is an EventEmitter:
 * - `initialized`: fired when antMediaSDK gives 'inititialized' callback
 *
 * @param {string} videoElementId id of the video element that will house the Livestream
 * @param {string} label label used to identify this Livestream in logs
 *
 * @see https://antmedia.io/how-to-embed-webrtc-live-streaming-into-your-website/
 * @see https://github.com/ant-media/Ant-Media-Server/wiki/WebRTC-JavaScript-SDK-Guide
 */
export default class LivestreamSubscriber extends LivestreamDefaults {
  constructor(label = "LivestreamSubscriber") {
    super(label);
    this.videoElements = {};
  }

  async init() {
    // This will populate this.antMediaLivestreamId
    await super.init();

    // Initialize the SimpleMediasoupPeer
    this.mediasoupPeer = new SimpleMediasoupPeer(this.socketio); // TODO: add our ice/turn servers
    this.mediasoupPeer.on("track", this.onTrack)
    //this doesnt work yet
    this.mediasoupPeer.socket.on("clientDisconnected", this.removeElement)

    this.socketio.on("clientDisconnect", this.removeElement)
  }
  onTrack = (track, id, label) => {
    console.log(`Got track of kind ${label} from ${id}`);
    let el;
    if(!this.videoElements[id]){
      el = document.createElement("video");
      el.id = id;
      el.autoplay = true;
      el.muted = true;
      el.setAttribute("playsinline", true);
      el.srcObject = new MediaStream([track]);
      el.onloadedmetadata = (e) => {
        el.play().catch((e) => {
          console.log("Play Error: " + e);
        });
      };
      this.videoElements[id] = el;

    }
    else {
      this.videoElements[id].srcObject.addTrack(track)
    }
    this.events.emit("livestream-track-update")
    console.log(this.videoElements)
  }
  removeElement = (id) => {
    console.log("client disconnected, deleting video element")
    delete this.videoElements[id]
    this.videoElements = {...this.videoElements}
    this.events.emit("livestream-track-update")
  }

  /** Cleanup */
  destroy() {
    super.destroy();
  }
}
