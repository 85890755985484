import Debug from "debug";

/** A list of our Debug namespaces */
const loggerNames = [
  "dialogs",
  "NetworkProvider",
  "PlayerManager",
  "Livestream",
  "Livestream:Sdp",
  "Gadgets",
  "changeRoom",
  "notifications",
  "playerTargeting",
];

/**
 * Creates an object of Debug loggers we will use throughout Moon Dungeon
 * @example <caption>Of form:</caption>
 * ```js
{
    foo: Debug('MoonDungeon:foo'),
    bar: Debug('MoonDungeon:bar'),
    // ...
}
```
 * @returns an object full of Debugs namespaced by key
 */
function createLoggers() {
  let loggers = {};
  loggerNames.forEach((loggerName) => {
    loggers[loggerName] = Debug("MoonDungeon:" + loggerName);
  });

  return loggers;
}
const loggers = createLoggers();

/** Our complete list of loggers */
export default loggers;
