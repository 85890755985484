<template>
  <div class="bg-black text-white absolute bottom-0 left-0 px-2 py-1 text-sm max-w-[15rem] pointer-events-none">
    <h2 class="font-head uppercase text-md" v-if="$store.getters.room.title">{{ $store.getters.room.title }}
      <span class="text-green block text-sm">{{ $store.getters.room.world }}</span>
    </h2>
    <p class="text-gray" v-if="$store.getters.room.description">{{ $store.getters.room.description }}</p>
    <!-- <p class="lowercase text-gray ml-[2rem]" v-if="$store.getters.room.author">– {{ $store.getters.room.author }}</p> -->
  </div>
</template>

<script>

export default {
  created() {
  }
}
</script>

<style lang="scss" scoped>
</style>
