import { WebRTCAdaptor } from "@antmedia/webrtc_adaptor";
import { httpsCallable } from "firebase/functions";
import firebaseFunctions from "./firebaseFunctions.js";
import LivestreamDefaults from "./LivestreamDefaults.js";

/**
 * Calls firebase function which handles sensitive interactions with AntMedia server,
 * requests that any livestream currently playing at data.id stop (so that, e.g., we can takeover)
 * @param {Object} data `{id}` contains id property
 * @param {string} data.id id of livestream to stop
 */
const askServerToStopLivestream = httpsCallable(
  firebaseFunctions,
  "stopLivestream"
);

/**
 * This manages
 *
 * `this.events` is an EventEmitter:
 * - `initialized`: fired when antMediaSDK gives 'inititialized' callback
 * - `started`: fired when server verifies we are successfully publishing
 * - `stopped`: fired when server verifies we have stopped publishing
 * (either via error, publish takeover, or us stopping)
 * - `failed`:  fired if livestream fails to begin (currently just due to not being able to takeover)
 * - `got-available-devices`: fired when the SDK retrieves the available media devices.
 * Passes those devices in an object of form: {audio: InputDeviceInfo[], video: InputDeviceInfo[]}
 *
 * @param {string} videoElementId id of the video element that will house the Livestream
 * @param {string} label label used to identify this Livestream in logs
 * @param {Object} options media configuration
 * @param {number} options.videoWidth specify width in pixels
 * @param {number} options.videoHeight specify height in pixels
 * @param {string} options.videoDeviceId specify input device
 * @param {string} options.audioDeviceId specify audio device
 *
 * @see https://antmedia.io/how-to-embed-webrtc-live-streaming-into-your-website/
 * @see https://github.com/ant-media/Ant-Media-Server/wiki/WebRTC-JavaScript-SDK-Guide
 */
export default class LivestreamPublisher extends LivestreamDefaults {
  constructor(videoElementId, label = "LivestreamPublisher", options = {}) {
    super(videoElementId, label, options);

    /**
     * Media configuration options, created by merging user-specified options with defaults
     */
    this.options = {
      videoWidth: 500,
      videoHeight: 500,
      videoDeviceId: null,
      audioDeviceId: null,
      ...options,
    };

    /** whether we are receiving a stream from antmedia */
    this.isStreaming = false;

    /** The media constraints to use for getUserMedia (handled by SDK) */
    this.mediaConstraints = {
      deviceId: this.options.videoDeviceId,
      video: {
        width: {
          exact: this.options.videoWidth,
        },
        height: {
          exact: this.options.videoHeight,
        },
      },
      audio: {
        deviceId: this.options.audioDeviceId,
        // echoCancellation: false,
        // googAutoGainControl: false,
        // autoGainControl: false,
        // noiseSuppression: false,
        // googNoiseSuppression: false,
        // googHighpassFilter: false,
        // googTypingNoiseDetection: false,
        volume: 1, // does this do anything?
      },
    };
  }

  /**
   * Get livestream ID (done in parent Default class)
   * and initialize the AntMedia SDK
   */
  async init() {
    // This will populate this.antMediaLivestreamId
    await super.init();

    /**
     * Config to initialize the SDK:
     * - some defaults from the paren LivestreamDefaults class
     * - publisher-specific configuration
     */
    const config = {
      ...this.webRTCAdaptorDefaultConfig,
      callback: (eventName, eventData) => {
        this.webRTCAdaptorCallback(eventName, eventData);
      },
      callbackError: (error, message) => {
        this.webRTCAdaptorCallbackError(error, message);
      },
      mediaConstraints: this.mediaConstraints,
      sdp_constraints: {
        OfferToReceiveAudio: false,
        OfferToReceiveVideo: false,
      },
      localVideoId: this.videoElementId,
    };
    this.log("init with config", config);

    // Initialize the SDK
    this.antMediaSDK = new WebRTCAdaptor(config);
  }

  /**
   * This is called by Antmedia's SDK whenever anything happens
   * The publisher in particular adds the following chores to Default:
   * - Fire `got-available-devices` when input devices are found
   * - Fire `started` when publish is confirmed by server
   * - Fire `sttopped` when publish end is confirmed by server
   * @param {string} eventName
   * @param {Object} eventData
   */
  webRTCAdaptorCallback(eventName, eventData) {
    super.webRTCAdaptorCallback(eventName, eventData);
    if (eventName === "available_devices") {
      const devices = {
        audio: eventData.filter((device) => device.kind === "audioinput"),
        video: eventData.filter((device) => device.kind === "videoinput"),
      };
      this.events.emit("got-available-devices", devices);
    }

    if (eventName === "publish_started" || eventName === "session_restored") {
      this.events.emit("started");
    }

    if (eventName === "publish_finished") {
      this.events.emit("stopped");
    }
  }

  /**
   * An extension of the Default error handling callback, adding:
   * - Emits `failed` event when we attempt to publish and there is already a stream
   * @param {string} eventName
   * @param {Object} eventData
   */
  webRTCAdaptorCallbackError(error, message) {
    super.webRTCAdaptorCallbackError(error, message);
    if (error === "streamIdInUse") this.events.emit("failed");
  }

  /**
   * Requests stop of any existing livestreams on our id
   * And start publishing our livestream to our id
   */
  async start() {
    this.log("start()");
    await askServerToStopLivestream({ id: this.antMediaLivestreamId });
    this.antMediaSDK.publish(this.antMediaLivestreamId);
  }

  /**
   * Stop publishing
   */
  stop() {
    this.log("stop()");
    this.antMediaSDK.stop(this.antMediaLivestreamId);
  }

  /**
   * Cleanup
   */
  destroy() {
    clearInterval(this.checkForStreamInterval);
    super.destroy();
  }

  /** Retrieve video device from And Media SDK internals */
  get videoDeviceId() {
    return this.antMediaSDK.videoTrack.getSettings().deviceId;
  }

  /** Retrieve audio device from And Media SDK internals */
  get audioDeviceId() {
    return this.antMediaSDK.audioTrack.getSettings().deviceId;
  }

  /** Setting id will automatically trigger media device switching via the Ant Media SDK */
  set videoDeviceId(id) {
    this.log("setting videoDeviceId", id);
    this.antMediaSDK.switchVideoCameraCapture(this.antMediaLivestreamId, id);
  }

  /** Setting id will automatically trigger media device switching via the Ant Media SDK */
  set audioDeviceId(id) {
    this.log("setting audioDeviceId", id);
    this.antMediaSDK.switchAudioInputSource(this.antMediaLivestreamId, id);
  }
}
