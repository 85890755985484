<template>
  <div
    v-if="isPerformer"
    class="
      bg-black
      border-l-8 border-green
      p-4
      text-lg
      col-start-2
      overflow-y-auto
    "
    data-cy="performer-controls"
  >
    <ul class="flex flex-wrap gap-8">
      <li
        v-for="control in $store.state.roomPerformerControls"
        :key="control.id"
        :id="'performer-control-' + control.id"
        :class="`${control.type === 'button' ? '' : 'w-full'}`"
      >
        <ButtonPerformerControl
          v-if="control.type === 'button'"
          :control="control"
        />
        <TextPerformerControl
          v-if="control.type === 'text'"
          :control="control"
        />
        <InputPerformerControl
          v-if="control.type === 'input'"
          :control="control"
        />
        <DynamicInputPerformerControl
          v-if="control.type === 'dynamic-input'"
          :control="control"
        />
        <SequencePerformerControl
          v-if="control.type === 'sequence'"
          :control="control"
        />
        <TogglePerformerControl
          v-if="control.type === 'toggle'"
          :control="control"
        />
        <TargetPerformerControl
          v-if="control.type === 'target'"
          :control="control"
        />
        <SliderPerformerControl
          v-if="control.type === 'slider'"
          :control="control"
        />
      </li>
      <li class="w-full border-t-[1rem] pt-8 border-dark-gray border-opacity-50">
        <TogglePerformerControl
          :control="{
            label: 'TV-bot mode',
            type: 'toggle',
            value: $store.getters.stableState.player.tvbot,
            hotkey: 'L',
            action: (newVal) => {
              $store.commit('updatePlayerStableState', {
                tvbot: newVal,
              });
            },
          }"
        />
      </li>
      <li class="w-full">
        <SliderPerformerControl
          :control="{
            label: 'Player Visibility',
            type: 'slider',
            min: 0,
            max: 1,
            step: 0.01,
            value: $store.getters.stableState.player.visibility,
            action: (newVal) => {
              $store.commit('updatePlayerStableState', {
                visibility: newVal,
              });
            },
          }"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import TextPerformerControl from "@/components/PerformerControls/TextPerformerControl";
import ButtonPerformerControl from "@/components/PerformerControls/ButtonPerformerControl";
import InputPerformerControl from "@/components/PerformerControls/InputPerformerControl";
import DynamicInputPerformerControl from "@/components/PerformerControls/DynamicInputPerformerControl";
import SequencePerformerControl from "@/components/PerformerControls/SequencePerformerControl";
import TogglePerformerControl from "@/components/PerformerControls/TogglePerformerControl";
import TargetPerformerControl from "@/components/PerformerControls/TargetPerformerControl";
import SliderPerformerControl from "@/components/PerformerControls/SliderPerformerControl";

export default {
  name: "PerformerControls",
  components: {
    TextPerformerControl,
    ButtonPerformerControl,
    InputPerformerControl,
    DynamicInputPerformerControl,
    SequencePerformerControl,
    TogglePerformerControl,
    TargetPerformerControl,
    SliderPerformerControl,
  },
  data() {
    return {
      /**
       * Determines if this player is a perfomer based on query string paramter
       */
      isPerformer:
        new URLSearchParams(window.location.search).get("performer") === "true",
    };
  },
};
</script>

