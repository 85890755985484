import GadgetManager from "./GadgetManager";
import { playerContainerWidth, playerContainerHeight } from "@/lib/constants";
import { hslToHex } from "../../lib/hslToHex";
import { sendPlayerUseMessageToRoom } from "../../lib/sendPlayerUseMessageToRoom";
import loggers from "../../loggers";
import { colorizeTexture } from "./colorizeTexture";
import { setMyPlayerBetweenRooms } from "../../lib/setMyPlayerBetweenRooms";

/** Width to render livestream tv-bot */
const livestreamWidth = playerContainerWidth * 5;
/** Height to render livestream tv-bot */
const livestreamHeight = livestreamWidth;

export default class Player {
  constructor(scene, id, options = {}) {
    this.scene = scene;
    this.id = id;
    this.options = {
      networkProvider: window.networkProvider,
      ...options,
    };

    this.gadgetManager = new GadgetManager(this.scene, this);
    this.networkProvider = this.options.networkProvider;

    /**
     * A human readable alias, starts out as the id--but will be chosen by the server
     * (maybe the player can choose this in the future, for now the server dictates it)
     **/
    this.nickname = null;

    /**
     * Tracks whether we are between rooms (useful for animation-sake)
     * We get this message from both stable state and volatile state
     * (because they arent always perfectly synched, we must track both)
     */
    this.betweenRooms = {
      stable: true,
      volatile: true,
    };

    this.livestreamTextureKey = null;
  }

  // PlayerManager ensures this gets fired when created
  create() {
    // Init currentRoom tracking (this will be part of player state)
    this.currentRoomId = null;
    this.outsideCurrentRoom = true;

    // Init energy
    this.energy = 0;

    // Init customizations
    this.playerColor = hslToHex(0, 0, 0); // A default color

    // Can be used to change player size
    this.scaleMultiplier = 1;

    // Can be used to change player alpha
    this.visibility = 1;

    // Make a player physics object in phaser
    this.playerPhaserObject = this.scene.add.container(
      this.scene.room.playerSpawn.x,
      this.scene.room.playerSpawn.y
    );
    this.playerPhaserObject.setSize(
      playerContainerWidth,
      playerContainerHeight
    );

    // Create a container for different types of body

    // Default
    this.defaultBodyContainer = this.scene.add.container();
    this.playerPhaserObject.add(this.defaultBodyContainer);

    // Livestream / TVbot
    // (I want to do this in setTVBot BUT it messes with stacking order, it is a hack to set it here)
    // (TODO: figure out layers/depth to fix this properly)
    this.livestreamBodyContainer = this.scene.add.container();
    this.playerPhaserObject.add(this.livestreamBodyContainer);

    // Add Head image
    this.headSprite = this.scene.add.image(0, 0, "cowhead");
    this.headSprite.setDisplaySize(
      playerContainerWidth * 0.6,
      playerContainerHeight * 0.6
    );
    this.defaultBodyContainer.add(this.headSprite);

    // Add bubble circle
    this.bubbleCircle = this.scene.add.circle(
      0,
      0,
      playerContainerWidth / 2,
      this.playerColor
    );
    this.bubbleCircle.setStrokeStyle(4, 0xffffff);
    this.bubbleCircle.setFillStyle(this.playerColor, 0.4);
    this.defaultBodyContainer.add(this.bubbleCircle);

    // Set default livestreamPlayer
    this.livestreamTextureKey = null;
    this.rebuildLivestream = false;
    this.tvbotToggle = false;
    this.setTVBot(false);

    // Emit custom event with player id/nickname on click (useful for selecting the player in interactions)
    this.initPlayerTargeting();
  }

  afterCreate() {
    // Init the gadgets, this must be done after descendants run create(), so its in afterCreate()
    this.gadgetManager.create();
  }

  /**
   * @returns {boolean} whether or not we are in a "between rooms" state
   */
  isBetweenRooms() {
    return this.betweenRooms.volatile || this.betweenRooms.stable;
  }

  /**
   * Update the stable state of other player to match state received (usually from RTC message)
   * @param {PlayerStableState} playerStableState
   */
  updatePlayerStableState = (playerStableState) => {
    if (!playerStableState) return;
    if (typeof playerStableState.currentRoomId !== "undefined")
      this.currentRoomId = playerStableState.currentRoomId;
    if (typeof playerStableState.playerColor !== "undefined")
      this.setPlayerColor(playerStableState.playerColor);
    if (typeof playerStableState.tvbot_id !== "undefined"){
      for(const id in window.livestreamSubscriber?.videoElements){
        let el =  window.livestreamSubscriber?.videoElements[id]
        if(id === playerStableState.tvbot_id && !this.isMyPlayer){
          el.muted = false;
        } else {
          el.muted = true
        }
      }
      this.livestreamTextureKey = playerStableState.tvbot_id
      this.rebuildLivestream = true;
      this.setTVBot(playerStableState.tvbot);

    }
    if (typeof playerStableState.tvbot !== "undefined"){
      this.tvbotToggle = playerStableState.tvbot
      this.setTVBot(playerStableState.tvbot);
    }

    if (typeof playerStableState.nickname !== "undefined")
      this.setNickname(playerStableState.nickname);
    if (typeof playerStableState.betweenRooms !== "undefined") {
      this.betweenRooms.stable = playerStableState.betweenRooms;
    }
    if (typeof playerStableState.visibility !== "undefined")
      this.setVisibility(playerStableState.visibility);
  };

  /**
   * Parses a stable state update and delivers it to functions that update the player, gadgets and anything else owned by this player
   * @param {StableState} stableState
   */
  updateStableState = (stableState) => {
    this.updatePlayerStableState(stableState.player);
    if (this.gadgetManager && stableState.gadgets)
      this.gadgetManager.updateGadgetsStableState(stableState.gadgets);
  };

  /**
   * Sets nickname
   * @param {string} newNickname
   */
  setNickname(newNickname) {
    this.nickname = newNickname;
  }

  /**
   * Sets the player color and makes visual updates
   * @param {color} newColor
   */
  setPlayerColor(newColor) {
    // Set color
    this.playerColor = newColor;

    // Apply color
    if (this.bubbleCircle)
      this.bubbleCircle.setFillStyle(this.playerColor, 0.4);
  }

  /**
   * Sets the player visibility and makes visual updates
   * @param {alpha} newVisibility
   */
  setVisibility(newVisibility) {
    this.visibility = newVisibility;
  }

  // PlayerManager ensures this gets fired when a relay server message data.sender has an id that matches this player
  // route relay messages to their appropriate handler functions
  handleRecieveRelay(data) {
    if (data.type === "gadget") {
      this.gadgetManager.handleRecieveRelay(data);
    }

    if (data.type === "use") {
      this.handleUseRelay(data);
    }

    if (data.type === "player-animation") {
      this.handlePlayerAnimationRelay(data);
    }
  }

  /**
   * Triggers animations requested by player animation relays
   * @param {Object} data
   * @param {'spawn-teleport'|'spawn-door'} data.options.animationName
   * @param {number} data.options.x
   * @param {number} data.options.y
   */
  handlePlayerAnimationRelay(data) {
    // Get animation name
    const { animationName } = data.options;

    //---------------------------------------------------
    // SPAWN: TELEPORT
    //---------------------------------------------------
    if (animationName === "spawn-teleport") {
      loggers.changeRoom("Starting spawn-teleport animation");

      // Create particle effects IF we are in the current room
      if (!this.outsideCurrentRoom) {
        // Emit particles
        const particles = this.scene.add.particles(
          colorizeTexture("particle-circle", this.playerColor, this.scene)
        );
        const teleportEmitter = particles.createEmitter({
          x: data.options.x,
          y: data.options.y,
          lifespan: 200,
          speed: { min: 50, max: 150 },
          alpha: { start: 1, end: 0, ease: "linear" },
          scale: { start: 0, end: 5, ease: "Power3" },
          blendMode: Phaser.BlendModes.ADD,
        });
        // Stop particles
        setTimeout(() => {
          teleportEmitter.stop();
        }, 400);

        // Clear particle emitter from memory
        setTimeout(() => {
          particles.destroy();
        }, 1000);
      }

      // Reveal player
      setTimeout(() => {
        if (this.isMyPlayer) {
          loggers.changeRoom(
            "Revealing MyPlayer as part of spawn-teleport anim"
          );
          setMyPlayerBetweenRooms(false, this.scene);
        }
      }, 50);
    }

    //---------------------------------------------------
    // SPAWN: DOOR
    //---------------------------------------------------
    if (animationName === "spawn-door") {
      loggers.changeRoom("Starting spawn-door animation");

      if (this.isMyPlayer) {
        setTimeout(() => {
          let myPlayerRevealed = false;
          const revealPlayer = () => {
            if (!myPlayerRevealed) {
              myPlayerRevealed = true;
              loggers.changeRoom(
                "Revealing MyPlayer as part of spawn-door anim"
              );
              setMyPlayerBetweenRooms(false, this.scene);
            }
          };

          // Create a counter tween from 0 to 1, onUpdate apply its value to scaleMultiplier
          // This is the best way to tween a player shrinking/growing since scaleMultiplier is not a native phaser property
          const sizeTween = this.scene.tweens.addCounter({
            from: 0.1,
            to: 1,
            duration: 300,
            onUpdate: () => {
              this.scaleMultiplier = sizeTween.getValue();
              if (this.scaleMultiplier > 0.4) revealPlayer();
            },
            onComplete: () => {
              // Just in case
              this.scaleMultiplier = 1;

              // Just in case
              revealPlayer();
            },
          });
        }, 200);
      }
    }
  }

  /**
   * Fired when a use relay is received from the server attributed to this player
   * Currently just draws an illustrative graphic to indicate player has pressed use key
   * @param {Object} data - use relay payload
   */
  handleUseRelay(data) {
    if (this.outsideCurrentRoom) return;

    const useIndicatorCircle = this.scene.add.circle(
      data.options.x,
      data.options.y,
      playerContainerWidth / 20
    );
    useIndicatorCircle.name = "use-indicator";
    useIndicatorCircle.setStrokeStyle(50, this.playerColor);
    const tween = this.scene.tweens.add({
      targets: useIndicatorCircle,
      alpha: 0.1,
      radius: playerContainerWidth * 1.3,
      duration: 200,
      onComplete: () => {
        useIndicatorCircle.destroy();
      },
    });

    // Let the room know a use has occurred
    sendPlayerUseMessageToRoom(this);
  }

  /**
   * Fires a custom event `player-targeted` with id and nickname data on click
   * For example, you might listen for such an event after clickint a "select player" button
   * And that will tell you who was selected
   */
  initPlayerTargeting() {
    loggers.playerTargeting(
      "Initializing click event handling on player",
      this.nickname
    );
    this.playerPhaserObject.setInteractive();
    this.playerPhaserObject.on("pointerdown", (pointer) => {
      window.dispatchEvent(
        new CustomEvent("player-targeted", {
          detail: { id: this.id, nickname: this.nickname },
        })
      );
    });
  }

  /**
   * Style the body based on tv-bot true/false
   * @param {boolean} tvbot
   */
  setTVBot(tvbot) {
    if (tvbot) {
      loggers.Livestream(
        `Player.setTVBot(): Player ${
          this.nickname ? this.id + " / " + this.nickname : this.id
        } has activated TV-bot mode`
      );
      // If the TVBotBody hasn't been created yet, make it now
      if (!this.TVBotBody || this.rebuildLivestream) {
        this.rebuildLivestream = false;
        this.livestreamBodyContainer.removeAll(true)
        // The antenna
        this.TVBotAntenna = this.scene.add.image(
          livestreamWidth * 0.35,
          -livestreamHeight * 0.53,
          "tv-bot-antenna"
        );
        this.TVBotAntenna.setDisplaySize(
          livestreamWidth / 20,
          livestreamHeight / 2
        );
        this.livestreamBodyContainer.add(this.TVBotAntenna);

        // A playerColored circle to add a tint of playerColor to the livestream
        this.TVBotScreenColorOverlay = this.scene.add.circle(
          0,
          0,
          (livestreamHeight * 0.94) / 2,
          this.playerColor
        );
        this.livestreamBodyContainer.add(this.TVBotScreenColorOverlay);

        // The livestream texture (already circl-cropped and grayscaled)
        // With slight alpha so the color overlay shows through
        if(this.scene.textures.exists(`tv-bot-livestream-${this.livestreamTextureKey}`)){
          if(this.TVBotLivestream) this.TVBotLivestream.destroy()
          this.TVBotLivestream = this.scene.add.image(0, 0, `tv-bot-livestream-${this.livestreamTextureKey}`);
          this.TVBotLivestream.setDisplaySize(livestreamWidth, livestreamHeight);
          this.TVBotLivestream.setAlpha(0.7);
          this.livestreamBodyContainer.add(this.TVBotLivestream);
        } 
        // Adds the pixel-y screen overlay that somewhat masks the livestream low quality
        this.TVBotScreen = this.scene.add.image(0, 0, "tv-bot-screen");
        this.TVBotScreen.setDisplaySize(livestreamWidth, livestreamHeight);
        this.TVBotScreen.setBlendMode(Phaser.BlendModes.ADD);
        this.TVBotScreen.setAlpha(0.5);
        this.livestreamBodyContainer.add(this.TVBotScreen);

        // The metal body and the screen gradient/shading
        this.TVBotBody = this.scene.add.image(0, 0, "tv-bot-body");
        this.TVBotBody.setDisplaySize(livestreamWidth, livestreamHeight);
        this.livestreamBodyContainer.add(this.TVBotBody);

        // Store all lights in an array so we can animated them programatically
        this.TVBotBodyLights = [];

        // Antenna lights (these look a little cooler when you layer 2 on top of each other, top one with ADD blendmode)
        this.TVBotBodyLights[0] = this.scene.add.image(
          livestreamWidth * 0.34,
          -livestreamHeight * 0.52 - livestreamHeight / 4,
          colorizeTexture(`particle-circle`, this.playerColor, this.scene)
        );
        this.TVBotBodyLights[0].setDisplaySize(
          livestreamWidth / 5,
          livestreamHeight / 5
        );
        this.TVBotBodyLights[1] = this.scene.add.image(
          livestreamWidth * 0.34,
          -livestreamHeight * 0.52 - livestreamHeight / 4,
          colorizeTexture(`particle-circle`, this.playerColor, this.scene)
        );
        this.TVBotBodyLights[1].setDisplaySize(
          livestreamWidth / 5,
          livestreamHeight / 5
        );
        this.TVBotBodyLights[1].setBlendMode(Phaser.BlendModes.ADD);

        // Body light (these look a little cooler when you layer 2 on top of each other, top one with ADD blendmode)
        this.TVBotBodyLights[2] = this.scene.add.image(
          -livestreamWidth * 0.34,
          -livestreamHeight * 0.34,
          colorizeTexture(`particle-circle`, this.playerColor, this.scene)
        );
        this.TVBotBodyLights[2].setDisplaySize(
          livestreamWidth / 5,
          livestreamHeight / 5
        );
        this.TVBotBodyLights[3] = this.scene.add.image(
          -livestreamWidth * 0.34,
          -livestreamHeight * 0.34,
          colorizeTexture(`particle-circle`, this.playerColor, this.scene)
        );
        this.TVBotBodyLights[3].setDisplaySize(
          livestreamWidth / 5,
          livestreamHeight / 5
        );
        this.TVBotBodyLights[3].setBlendMode(Phaser.BlendModes.ADD);

        // Body light (these look a little cooler when you layer 2 on top of each other, top one with ADD blendmode)
        this.TVBotBodyLights[4] = this.scene.add.image(
          -livestreamWidth * 0.4,
          -livestreamHeight * 0.25,
          colorizeTexture(`particle-circle`, this.playerColor, this.scene)
        );
        this.TVBotBodyLights[4].setDisplaySize(
          livestreamWidth / 5,
          livestreamHeight / 5
        );
        this.TVBotBodyLights[5] = this.scene.add.image(
          -livestreamWidth * 0.4,
          -livestreamHeight * 0.25,
          colorizeTexture(`particle-circle`, this.playerColor, this.scene)
        );
        this.TVBotBodyLights[5].setDisplaySize(
          livestreamWidth / 5,
          livestreamHeight / 5
        );
        this.TVBotBodyLights[5].setBlendMode(Phaser.BlendModes.ADD);

        // Add the lights to the container
        this.TVBotBodyLights.forEach((light) => {
          this.livestreamBodyContainer.add(light);
        });

        // Animate the lights opacity and size based on sine of time (give each a slightly different rate)
        this.scene.events.on("update", (time) => {
          this.TVBotBodyLights.forEach((light, index) => {
            const speed = (1 / 200) * (1 + index / 10);
            light.setAlpha(Math.sin(time * speed) * 0.2 + 0.8);
            light.setDisplaySize(
              ((Math.sin(time * speed) * 0.2 + 0.8) * livestreamWidth) / 5,
              ((Math.sin(time * speed) * 0.2 + 0.8) * livestreamWidth) / 5
            );
          });
        });
      }

      // Emit animated radio waves every so often
      this.TVBotRadiowaveInterval = setInterval(() => {
        if (this.TVBotRadiowave) return;

        // The radiowave
        this.TVBotRadiowave = this.scene.add.image(
          livestreamWidth * 0.34,
          -livestreamHeight * 0.52 - livestreamHeight / 4,
          colorizeTexture(`tv-bot-radiowave`, this.playerColor, this.scene)
        );
        this.TVBotRadiowave.setDisplaySize(
          livestreamWidth / 20,
          livestreamHeight / 20
        );
        this.TVBotRadiowave.setBlendMode(Phaser.BlendModes.ADD);
        this.livestreamBodyContainer.add(this.TVBotRadiowave);

        // Tween the radio wave to expand/fade it
        this.scene.tweens.add({
          targets: this.TVBotRadiowave,
          alpha: 0,
          scale: 1,
          duration: 1000,
          onComplete: () => {
            this.TVBotRadiowave.destroy();
            this.TVBotRadiowave = null;
          },
        });
      }, 2000);

      // Hide default body
      this.defaultBodyContainer.setActive(false).setVisible(false);

      // Show livestream Body
      this.livestreamBodyContainer.setActive(true).setVisible(true);

      // Move the gadgets to compensate for this big boy
      this.gadgetManager.gadgetPositions = {
        top: { x: 0, y: -livestreamHeight / 2 },
        left: { x: -livestreamWidth / 2, y: 0 },
        right: { x: livestreamWidth / 2, y: 0 },
        bottom: { x: 0, y: livestreamHeight / 2 },
      };
    } else {
      // Hide livestream body
      if (this.livestreamBodyContainer)
        this.livestreamBodyContainer.setActive(false).setVisible(false);

      // Show default body
      this.defaultBodyContainer.setActive(true).setVisible(true);

      // Use default gadget positions
      this.gadgetManager.resetGadgetPositions();

      // Stop emitting animated radio waves
      clearInterval(this.TVBotRadiowaveInterval);
    }
  }

  // PlayerManager fires this on scene update
  update() {
    this.gadgetManager.update();

    const shouldBeHidden = this.outsideCurrentRoom || this.isBetweenRooms();
    this.playerPhaserObject.setVisible(!shouldBeHidden);
  }

  // PlayerManager fires this when destroying a player
  destroy() {
    this.gadgetManager.destroy();
    this.playerPhaserObject.destroy();
    if (this.livestreamTexture) this.livestreamTexture.destroy();
  }
}
