<template>
  <HotkeyButton
    :hotkey="control.hotkey"
    :action="toggle"
    classes="flex gap-2 outline-0"
    activeClasses=""
  >
    <div :aria-label="control.value" class="border border-green flex">
      <div
        :class="'px-2 ' + (control.value ? 'text-gray' : 'bg-green text-black')"
        aria-hidden="true"
      >
        No
      </div>
      <div
        :class="'px-2 ' + (control.value ? 'bg-green text-black' : 'text-gray')"
        aria-hidden="true"
      >
        Yes
      </div>
    </div>
    {{ control.label }}
  </HotkeyButton>
</template>

<script>
import { sendMessageToCurrentRoom } from "@/lib/sendMessageToCurrentRoom";
import HotkeyButton from "@/components/PerformerControls/HotkeyButton";

export default {
  name: "TogglePerformerControl",
  components: { HotkeyButton },
  data() {
    return {};
  },
  methods: {
    /**
     * Toggle control value and performer action
     */
    toggle() {
      if (this.control.action) {
        this.control.action(!this.control.value);
      } else {
        this.$store.commit("updateRoomPerfomerControl", {
          id: this.control.id,
          update: {
            value: !this.control.value,
          },
        });
        sendMessageToCurrentRoom(
          "performer-action-triggered-" + this.control.id,
          !this.control.value
        );
      }
    },
  },
  props: {
    control: Object,
  },
};
</script>
