import { initializeApp } from "firebase/app";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { isDev } from "./isDev";

/**
 * Our Firebase App (don't worry, none of this config is secret)
 * @see https://firebase.google.com/docs/functions
 */
const firebaseApp = initializeApp({
  apiKey: "AIzaSyAbj0FSOqbFe79HWHrZRaMXEvYq8ax-xWU",
  authDomain: "vetw-33b72.firebaseapp.com",
  projectId: "vetw-33b72",
  storageBucket: "vetw-33b72.appspot.com",
  messagingSenderId: "142162969236",
  appId: "1:142162969236:web:4c56daabd7aacbcabc6a81",
  measurementId: "G-3SKL9GJ4SP",
});

/**
 * The Functions object from our firebase app.  Used, e.g. with httpsCallable
 * @see https://firebase.google.com/docs/functions/callable
 * */
const firebaseFunctions = getFunctions(firebaseApp);

// If in dev mode, we dont call our production firebase functions,
// We instead consult the local emulator.
if (isDev) {
  connectFunctionsEmulator(
    firebaseFunctions,
    "localhost",
    process.env.VUE_APP_FIREBASE_EMULATOR_PORT
  );
}

export default firebaseFunctions;
