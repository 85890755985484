<template>
  <div id="chatbox" class="absolute bottom-0 right-0 bg-black px-2 py-1 group">
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-0 z-40 pointer-events-none group-focus-within:bg-opacity-50 group-focus-within:pointer-events-auto group-focus-within:cursor-pointer" />
    <h2 class="text-sm font-head text-green uppercase relative z-50">Chat</h2>
    <input type="text"
      class="input w-40 focus:text-lg focus:w-80 transition-all relative z-50"
      placeholder="Press [Enter] or click"
    />
  </div>
</template>

<script>
import $ from 'jquery';
export default {
  async mounted(){
    $('#chatbox input').keydown(function(e) {
      if(e.key == "Enter"){
      }
      else {
        e.stopPropagation()
      }
    })
    $('#chatbox input').keyup(function(e) {
      if(e.key == "Enter"){

      }
      else if(e.key == "Escape"){
        $(this).blur()
      }
      else {
        e.stopPropagation()
      }
    })
    $(document).click(function(event) {
      var $target = $(event.target);
      if(!$target.closest('#chatbox input').length &&
      $('#chatbox input').is(":focus")) {
        $('#chatbox input').blur();
      }
    });
  }
}


</script>