import Joi from 'joi';

/**
 * Some defaults to import into our dialog schemas
 */
const dialogSchemaDefaults = {
  id: Joi.string().required(),
};

/**
 * An object of schemas for different types of dialog messages
 * Adding a new dialog type? Just expand this object and you are done with validation.
 */
const dialogPossibleSchemas = {
  alert: Joi.object().keys({
    ...dialogSchemaDefaults,
    type: Joi.string().valid('alert').required(),
    message: Joi.string().required(),
    button: Joi.string().default('ok')
  }),
  prompt: Joi.object().keys({
    ...dialogSchemaDefaults,
    type: Joi.string().valid('prompt').required(),
    question: Joi.string().allow('').default(''),
    placeholder: Joi.string().default('...'),
    button: Joi.string().default('answer')
  }),
};

/**
 * A Joi schema that validates against ANY of dialogPossibleSchemas
 * Which one will be used to validate is based on .type
 */
export const dialogSchema =
  Joi.alternatives()
  // We use .type to determine which which schema it should be.
  .conditional('.type', {
    // If .type = a key in in peerfomerControlSchemas, we validate this item against that schema
    switch: [
      ...Object.keys(dialogPossibleSchemas).map(controlType => ({
        is: controlType,
        then: dialogPossibleSchemas[controlType],
      }))
    ]
  })