import { sendMessageToCurrentRoom } from "./sendMessageToCurrentRoom";
import { convertWorldToIframeCoordinates } from "./convertWorldToIframeCoordinates";
import { hexToString } from "./hexToString";

/**
 * Sends my or other-player-use message to room to trigger clicks and fire md.events
 * (Will infer my vs. other player from Player argument)
 * @param {Player} player
 */
export function sendPlayerUseMessageToRoom(player) {
  const { x: iframeX, y: iframeY } = convertWorldToIframeCoordinates(player.playerPhaserObject, player.scene);

  // Send use data
  const messageName = (player.isMyPlayer ? 'my' : 'other') + '-player-use'
  sendMessageToCurrentRoom(messageName, {
    x: iframeX,
    y: iframeY,
    id: player.nickname, // Rooms use nickname ids for better readability
    color: hexToString(player.playerColor),
  });
}
