import Gadget from "../Gadget";
import { playerContainerWidth, playerContainerHeight } from "@/lib/constants";
import {
  onSendChat,
  createSpeechBubble,
} from "@/game/classes/gadgets/functions/chat";
import { getWorldPointOfSprite } from "@/lib/getWorldPointOfSprite";
import { choice } from "@/lib/choice";
import { lerp } from "@/lib/lerp";
import { lerpAngular } from "@/lib/lerpAngular";
import store from "../../../lib/store";

export default class Fedora extends Gadget {
  constructor(gadgetProps) {
    super(gadgetProps);
    (this.sprite = "fedora"),
      (this.keyPhrase = choice(["milady", "see?", "capiche?"]));
    this.targetRotation = 0;
  }

  create() {
    super.create();
    if (this.player.isMyPlayer) {
      onSendChat((msg) => {
        this.broadcastRelay({
          msg, //: this.fedorify(msg)
        });
      });
    }

    // Create and size fedora sprite
    this.gadgetSprite = this.phaserScene.add.image(
      0,
      -playerContainerHeight * 0.4,
      "fedora"
    );
    this.gadgetSprite.displayWidth = playerContainerWidth * 0.8;
    this.gadgetSprite.scaleY = this.gadgetSprite.scaleX;

    // Add to our gadget container
    this.gadgetManager.gadgetContainer.add(this.gadgetSprite);

    this.scaleMultiplier = 1;
    this.originalScale = this.gadgetSprite.scaleX;
  }
  activate(energy) {
    super.activate(energy);
    this.broadcastRelay({ tipHat: true, energy: energy });
  }
  handleRecieveRelay(data) {
    console.log(this.player.visibility);
    super.handleRecieveRelay(data);
    if (data.options.msg) {
      const pos = getWorldPointOfSprite(this.gadgetSprite);
      const bubbleHeight =
        playerContainerHeight * 0.5 +
        (playerContainerHeight * 0.5 * data.options.msg.length) / 10;
      createSpeechBubble(
        this.phaserScene,
        pos.x,
        pos.y - bubbleHeight < 0 ? 0 : pos.y - bubbleHeight,
        playerContainerWidth * 3,
        bubbleHeight,
        data.options.msg,
        {
          bubbleColor: 0xdedede,
          fontFamily: "Baskerville",
          italic: true,
          fontSize: 100,
          alpha: this.player.visibility,
        }
      );
      this.targetRotation = -0.3;
      setTimeout(() => (this.targetRotation = 0.3), 200);
      setTimeout(() => (this.targetRotation = 0), 400);
    } else if (data.options.tipHat) {
      this.targetRotation = -data.options.energy * 2;
      if (data.options.energy == 1) {
        this.scaleMultiplier = 5;
        this.targetRotation = -Math.PI / 6;
      }
      setTimeout(() => {
        this.targetRotation = 0;
        if (
          this.player.isMyPlayer &&
          data.options.energy > 0.1 &&
          data.options.energy < 1
        )
          this.player.incrementEnergy(data.options.energy + 0.15);
      }, 500);
    }
  }

  fedorify(msg) {
    if (Math.random() < 0.5) {
      return msg + ", " + this.keyPhrase;
    } else {
      return msg;
    }
  }

  update() {
    super.update();
    this.gadgetSprite.rotation = lerpAngular(
      this.gadgetSprite.rotation,
      this.targetRotation,
      0.1
    );
    this.gadgetSprite.scaleX = this.originalScale * this.scaleMultiplier;
    this.gadgetSprite.scaleY = this.originalScale * this.scaleMultiplier;
    this.scaleMultiplier = lerp(this.scaleMultiplier, 1, 0.01);
  }

  destroy() {
    super.destroy();
    // this.player.playerPhaserObject.add(this.gadgetSprite)  TODO: how do we remove this?
  }
}
