<template>
  <div
    class="bg-black fixed top-0 left-0 w-full h-full grid place-items-center"
    data-cy="failure-overlay"
    v-if="reason">
    <div>
      <h1 class="text-xl text-green text-head">Oh Fudge!</h1>
      <p class="text-gray">Moon Dungeon hit a snag:</p>
      <p class="text-lg max-w-[60vw]" data-cy="failure-overlay-reason">{{ reason }}</p>
      <div v-if="buttons">
        <button @click="recover()" class="border text-green p-4 text-lg mt-8 hover:bg-green hover:text-black inline-block">Recover where you left off</button>
      </div>
      <div v-if="buttons">
        <a :href="currentUrl" target="_blank" class="border text-green p-4 mt-4 hover:bg-green hover:text-black inline-block">Restart with a clean slate</a>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Failure',
    components: {},
    updated() {
      // Throw this error to the console as well.
      if(this.reason) {
        throw new Error('Catastrophic network failure error: '+this.reason)
      }
    },
    props: {
      reason: String|Boolean,
      buttons: Boolean,
    },
    data() {
      return {
        currentUrl: window.location.href
      }
    },
    methods: {
      recover() {
        location.reload()
      }
    },
    async mounted() {},
    destroyed() {}
  }
</script>

<style lang="scss" scoped>

</style>
