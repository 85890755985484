<template>
  <div v-if="started">
    <!-- This is the grid that fits all top-level elements (game state + performer controls) -->
    <div
      id="stage-wrap"
      :class="`relative w-[100vw] h-[100vh] grid grid-rows-[12rem_auto] grid-cols-[75%_25%]`"
    >
      <div
        class="
          absolute
          w-20
          h-20
          top-1/2
          left-1/2
          -translate-x-1/2 -translate-y-1/2
          opacity-50
        "
      >
        <Spinner />
      </div>

      <!-- The "game stage":
        to position anything relative to the game window and be properly repositioned to avoid performer controls,
        make it an asbolutely positioned child here
      -->
      <div
        id="stage"
        :class="`overflow-hidden will-change-transform h-[100vh] relative ${
          isPerformer ? 'w-[75vw]' : 'col-span-2'
        }`"
      >
        <!-- Holds room iframes -->
        <div id="room-container" />
        <!-- Holds phaser -->
        <div :id="gameContainerId" />

        <!-- Title/Nav -->
        <Header />

        <!-- Room Info -->
        <RoomInfo />

        <!-- Gadget UI -->
        <Inventory />

        <!-- Chat UI -->
        <ChatBox />

        <!-- High priority alerts/prompts/etc -->
        <Notifications />

        <!-- High priority alerts/prompts/etc -->
        <Dialogs />

        <!-- A connecting indicator that appears when
        the server has not yet arrived at target connection state -->
        <div
          v-if="!fullyConnected"
          class="
            absolute
            top-0
            left-1/2
            bg-black
            px-2
            text-white
            -translate-x-1/2
            font-head
            text-sm
          "
        >
          Connecting players...
        </div>
      </div>

      <!-- Livestream video element (needed for all) + controls (needed for performer) -->
      <Livestream />

      <!-- special UI for performer, defined largely by the room -->
      <PerformerControls />

      <!-- A buffet of app data used to debug -->
      <Debug />
    </div>
  </div>

  <!-- The loading screen -->
  <div
    class="
      fixed
      top-0
      left-0
      bg-black
      w-full
      h-full
      grid
      content-center
      align-middle
    "
    v-else
  >
    <h1 class="text-[5vw] text-green font-head text-center uppercase">
      Moon Dungeon
    </h1>
    <p class="text-[2vw] text-green text-center max-w-[55vw] mx-auto">
      {{
        resuming
          ? "Welcome back, trash-bot. Mother AI will now teleport you to your most recent location."
          : "The year is 582451.8856.  You are about to be synthesized as a trash-bot of the Moon junkyard 453b fleet."
      }}
    </p>
    <button
      @click="start()"
      :class="`border text-green p-4 text-lg hover:bg-green  d hover:text-black block w-40 mt-4 mx-auto
      ${downloaded ? '' : 'opacity-20 pointer-events-none'}
    `"
    >
      {{ downloaded ? (resuming ? "resume" : "synthesize") : "loading..." }}
    </button>
  </div>

  <!-- Failure overlay, appears when the app is irreperably broken and needs restarting -->
  <Failure :reason="failure" :buttons="failureButtons" />
</template>

<script>
import NetworkProvider from "@/lib/NetworkProvider";
import Header from "@/components/Header";
import Inventory from "@/components/Inventory";
import ChatBox from "@/components/ChatBox";
import Debug from "@/components/Debug";
import Notifications from "@/components/Notifications";
import Dialogs from "@/components/Dialogs";
import RoomInfo from "@/components/RoomInfo";
import PerformerControls from "@/components/PerformerControls";
import Failure from "@/components/Failure";
import Livestream from "@/components/Livestream";
import Spinner from "@/components/Spinner";
import store from "@/lib/store";
import { choice } from "@/lib/choice";
import { userHasNoMouse } from "@/lib/userHasNoMouse";
import { generateRandomPlayerColor } from "@/lib/generateRandomPlayerColor";
import { sniffChrome } from "@/lib/sniffChrome";
import getCohortName from "@/lib/getCohortName";

export default {
  name: "Game",
  components: {
    Inventory,
    ChatBox,
    Debug,
    Notifications,
    PerformerControls,
    Header,
    Failure,
    Dialogs,
    RoomInfo,
    Livestream,
    Spinner,
  },
  data() {
    return {
      /** Whether the game is downloaded */
      downloaded: false,

      /** Phaser game */
      gameInstance: null,

      /** Element to add game to */
      gameContainerId: "game-container",

      /** Whether the app is failing catasrophically */
      failure: false,

      /** Whether the failure screen (if showing), should have refresh/restart buttons */
      failureButtons: true,

      /** Whether the player is fully connected to all other players, according to the server */
      fullyConnected: false,

      /** Whether a player is a perfomer based on query string parameter */
      isPerformer:
        new URLSearchParams(window.location.search).get("performer") === "true",

      /** Whether the user has started the game */
      started: false,

      /** The Phaser game object */
      game: null,

      /** Whether or not we are resuming, based on sessionStorage */
      resuming: sessionStorage.getItem("started") === "true",
    };
  },
  methods: {
    async start() {
      this.started = true;

      sessionStorage.setItem("started", "true");

      this.$nextTick(() => {
        this.gameInstance = this.game.launch(this.gameContainerId);
      });

      // Dumping this here for now, it will want to live somewhere better
      const initNetworkProvider = () => {
        // Set up our networkProvider which will deal with all
        this.networkProvider = new NetworkProvider(
          process.env.VUE_APP_SIGNAL_SERVER_URL,
          {
            getVolatileState: () => store.getters.volatileState,
            getStableState: () => store.getters.stableState,
            cohortName: getCohortName(),
          }
        );
        this.networkProvider.init();

        // Add heartbeat metrics to UI
        this.networkProvider.events.on(
          "peer-volatile-state-received",
          (peerId, data) => {
            store.commit(
              "addHeartbeatLength",
              this.networkProvider.connections[peerId].volatileChannel
                .lastUpdateDelta
            );
          }
        );
        this.networkProviderPlayers = this.networkProvider.connections;

        // Surface a catastrophic network failure to the UI
        this.networkProvider.events.on("catastrophic-failure", (reason) => {
          this.failure = reason;
        });

        // Send nickname to player stable state if we get it
        this.networkProvider.events.on("nickname-chosen", (nickname) => {
          store.commit("updatePlayerStableState", { nickname });
        });

        // Expose the networkProvider globally
        window.networkProvider = this.networkProvider;
        store.commit("setNetworkProvider", this.networkProvider);

        return new Promise((resolve) => {
          if (networkProvider.isReady) resolve();
          else {
            this.networkProvider.events.once("ready", resolve);
          }
        });
      };
      await initNetworkProvider();

      // Dumping this here for now, it will want to live somewhere better
      // Really we are just doing this for testing purposes--we will want a different flow for initially condifuring the player
      const configurePlayer = () => {
        store.commit("updateGadgetStableState", {
          top: {
            type: "fedora",
            name: "fedora of destiny",
            options: {},
          },
          left: {
            type: "gifLauncher",
            name: "magic toaster of many gifs",
            options: {
              keyword: "scared",
            },
          },
          right: {
            type: "gator",
            name: "scream machine",
            options: {},
          },
          bottom: {
            type: "buttThruster",
            name: "butt thruster 9000",
            options: {},
          },
        });
        store.commit("updatePlayerStableState", {
          playerColor: generateRandomPlayerColor(),
        });
      };
      configurePlayer();

      /**
       * Process the summary into a form our UI can use
       */
      const processGlobalConnectionsSummary = (summary) => {
        /** Filter for all summaries that have a connected number lower than nPlayers - 1 */
        const nPlayers = Object.keys(summary).length;
        const failingIds = Object.keys(summary).filter(
          (id) => summary[id].connected !== nPlayers - 1
        );

        // Report to our UI
        this.fullyConnected = failingIds.length === 0;
      };

      // Run this now on the current summary, if it exists--and on any future updates.
      if (this.networkProvider.globalConnectionsSummary)
        processGlobalConnectionsSummary(
          this.networkProvider.globalConnectionsSummary
        );
      this.networkProvider.events.on(
        "global-connections-summary-update",
        processGlobalConnectionsSummary
      );
    },
  },
  async mounted() {
    // Don't do anything if we are not using a compatible device/browser
    if (
      !(
        new URLSearchParams(window.location.search).get(
          "force-compatibility"
        ) === "true"
      ) &&
      (!sniffChrome().isChrome || userHasNoMouse())
    ) {
      this.failure =
        "Sorry.  Moon Dungeon is in early development and has only been rigorously tested on Google Chrome / desktop. Please re-open there. Thanks for bearing with us!";
      this.failureButtons = false;
      return; // End all other initializations
    }

    // This could be useful but our code is too buggy for it right now.
    // window.addEventListener('error',(e)=>{this.failure = 'An error occurred. Devs, check the console!'; console.log(e)})

    // Start our phaser game
    this.game = await import(/* webpackChunkName: "game" */ "@/game/game");
    this.downloaded = true;

    // Force start?
    if (new URLSearchParams(location.search).get("force-start") === "true")
      this.start();
  },
  destroyed() {
    this.gameInstance.destroy(false);
    this.networkProvider.destroy();
  },
};
</script>

<style lang="scss">
// The phaser-added canvas does NOT get included in vue's style scoping, so we need an unscoped style tag to target it
#stage {
  #game-container {
    position: relative;
    // pointer-events: none;
    user-select: none;
  }

  #room-container {
    position: absolute;
    // outline: 2px solid blue;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    user-select: none;

    iframe {
      height: 100%;
      width: 100%;
      pointer-events: none;
      border: 0;
      user-select: none;
    }
  }
}
</style>
