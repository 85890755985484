<template>
  <article>
    <button @click="toggle">{{ title }} <span class="scale-[.6] inline-block">{{ isOpen ? '▼' : '▶' }}</span></button>
    <div class="pl-2" v-if="isOpen">
      <slot>Content</slot>
    </div>
  </article>
</template>

<script>
  export default {
    name: 'Accordion',
    components: {},
    props: {
      title: String,
      startOpen: Boolean,
    },
    data() {
      return {
        isOpen: !!this.startOpen
      }
    },
    methods: {
      toggle() {
        this.isOpen = !this.isOpen
      }
    },
    async mounted() {},
    destroyed() {}
  }
</script>