/**
 *  Takes in a sprite and returns the position of the sprite in the world
 * @param {Object} sprite - The phaser object of the sprite which we want to find the world point of
 * @return {Object} - {x:, y:} The position of the sprite
 */

export function getWorldPointOfSprite(sprite) {
  let tempMatrix = new Phaser.GameObjects.Components.TransformMatrix();
  let tempParentMatrix = new Phaser.GameObjects.Components.TransformMatrix();
  sprite.getWorldTransformMatrix(tempMatrix, tempParentMatrix);
  var d = tempMatrix.decomposeMatrix();
  return { x: d.translateX, y: d.translateY };
}
