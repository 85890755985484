<template>
  <span class="uppercase text-green font-head">{{ control.label }}:</span> <span>{{ control.value }}</span>
</template>

<script>

export default {
  name: 'TextPerformerControl',
  components: {},
  data() {
    return {

    }
  },
  methods: {
  },
  mounted() {
  },
  props: {
    control: Object,
  }
}
</script>
