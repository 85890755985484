<template>
  <HotkeyButton
    :hotkey="control.hotkey"
    :action="trigger"
  >
    {{ control.label }}
  </HotkeyButton>
</template>

<script>

import {sendMessageToCurrentRoom} from '@/lib/sendMessageToCurrentRoom'
import HotkeyButton from '@/components/PerformerControls/HotkeyButton'

export default {
  name: 'ButtonPerformerControl',
  components: {HotkeyButton},
  data() {
    return {
    }
  },
  methods: {
    /**
     * Trigger performer action
     */
    trigger() {
      sendMessageToCurrentRoom('performer-action-triggered-'+this.control.id, {})
    },
  },
  props: {
    control: Object,
  }
}
</script>
