
/**
 * Queries for currently active room and sends post message
 * @property {string} name -- will be namespaced with 'moon-dungeon-' prefix
 * @property {Object} payload
 */
export function sendMessageToCurrentRoom(name,payload) {
  document.querySelectorAll('[data-moon-dungeon-room-iframe][data-moon-dungeon-room-iframe-active]').forEach(iframe => {
    iframe.contentWindow.postMessage({
      type: 'moon-dungeon-'+name,
      payload
    }, '*');
  });
}
