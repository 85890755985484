import Gadget from "../Gadget";
import { playerContainerWidth, playerContainerHeight } from "@/lib/constants";
import { getWorldPointOfSprite } from "@/lib/getWorldPointOfSprite";
import { choice } from "@/lib/choice";
import {
  createDangle,
  updateDangle,
  getDangleWorldAngle,
  destroyDangle,
} from "@/game/classes/gadgets/functions/dangle";
import $ from "jquery";
import { colorizeTexture } from "../colorizeTexture";
export default class GifLauncher extends Gadget {
  constructor(gadgetProps) {
    super(gadgetProps);
  }

  create() {
    super.create();

    // Create and size sprite
    this.gadgetSprite = this.phaserScene.add.image(
      -playerContainerWidth * 0.35,
      playerContainerHeight * 0.1,
      "toaster"
    );
    this.gadgetSprite.displayWidth = playerContainerWidth * 0.5;
    this.gadgetSprite.scaleY = this.gadgetSprite.scaleX;
    this.gadgetSprite.setOrigin(0.5, 0);
    //Flipped to orient the toaster in the right direction. TODO: Standardize sprite orientation
    this.gadgetSprite.flipX = true;
    this.gadgetSprite.flipY = true;

    // Add to our gadget container
    this.gadgetManager.gadgetContainer.add(this.gadgetSprite);

    // Build dangle
    this.dangle = createDangle(this, {
      bobX: -playerContainerWidth,
      bobY: playerContainerHeight * 0.1,
      bobFrictionAir: 0.2,
      constraintX: -playerContainerWidth * 0.45,
      constraintY: playerContainerHeight * 0.05,
      length: playerContainerHeight * 0.15,
      // stiffness: 0.7
    });

    // Prevent inner collisions
    if (this.player.isMyPlayer) this.dangle.bob.isSensor = true;
  }

  activate(energy) {
    super.activate(energy);
    //Fetch the gif from the Giphy server, wait for it to return, then do physics motion and send relay
    this.getGif(this.gadgetOptions.keyword, true).then((gifURL) => {
      const angle = getDangleWorldAngle(this.dangle, this) + Math.PI;

      const forceAbs = energy;
      const forceX = Math.cos(angle) * forceAbs;
      const forceY = Math.sin(angle) * forceAbs;
      this.phaserScene.matter.body.applyForce(
        this.dangle.bob,
        { x: this.dangle.bob.position.x, y: this.dangle.bob.position.y },
        { x: forceX, y: forceY }
      );

      this.broadcastRelay({
        angle: angle,
        gifURL: gifURL,
        energy: energy == 1 ? energy * 0.66 : energy,
      });
      if (energy == 1) {
        this.getGif(this.gadgetOptions.keyword, true).then((gifURL) => {
          this.broadcastRelay({
            angle: angle + 1,
            gifURL: gifURL,
            energy: energy * 0.66,
          });
        });
        this.getGif(this.gadgetOptions.keyword, true).then((gifURL) => {
          this.broadcastRelay({
            angle: angle - 1,
            gifURL: gifURL,
            energy: energy * 0.66,
          });
        });
      }
    });
  }

  handleRecieveRelay(data) {
    super.handleRecieveRelay(data);
    // all players create and animate gif at the same time
    this.launchGif(
      data.options.gifURL,
      data.options.angle,
      data.options.energy
    );
  }

  update() {
    super.update();
    updateDangle(this.gadgetSprite, this.dangle, this);
  }

  destroy() {
    destroyDangle(this.dangle, this);
    this.gadgetSprite.destroy();
    super.destroy();
  }

  //Asynchronously fetches a gif from the Giphy api based on a keyword and returns a URL to the image. If sticker is true, gif will have a transparent background, otherwise it will be a rectangle
  async getGif(tag, sticker = true) {
    var url = sticker
      ? "https://api.giphy.com/v1/stickers/search?api_key=SnREKKYQNbZIxQm0BvFOeBhW1lYCDpjy&limit=10&q=" +
        tag
      : "https://api.giphy.com/v1/gifs/search?api_key=SnREKKYQNbZIxQm0BvFOeBhW1lYCDpjy&limit=1&q=" +
        tag;

    const gifURL = await fetch(url)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        return choice(data.data).images.downsized_medium.url;
      })
      .catch(function (error) {
        return console.log(error);
      });
    return gifURL;
  }

  //Takes in a giphy url and an angle to launch, loads the gif as an HTML image, grows it as it moves to a destination further away, then deletes it
  launchGif(url, angle, energy) {
    energy = energy < 0.15 ? 0.15 : energy;
    const sprite = this.gadgetSprite;
    let pos = getWorldPointOfSprite(sprite);
    pos.x += Math.cos(angle) * playerContainerWidth * 0.4;
    pos.y += Math.sin(angle) * playerContainerWidth * 0.4;

    const distance = 100 + 300 * energy;
    const gifSize = 120 * energy;
    const destination = {
      x: pos.x + distance * Math.cos(angle),
      y: pos.y + distance * Math.sin(angle),
    };
    const gif = $("<img />").attr("src", url).css({
      // position:'absolute',
      // "z-index": 999999,
      // left: worldToScreenX(pos.x,this.phaserScene)+'px',
      // top: worldToScreenY(pos.y,this.phaserScene)+'px',
      // opacity: this.player.visibility,
      width: "10px",
      transform: "translate(-50%,-50%)",
      willChange: "transform",
    })[0];

    const gifPhaser = this.phaserScene.add.dom(pos.x, pos.y, gif);
    gifPhaser.setAlpha(this.player.visibility);
    this.phaserScene.tweens.add({
      targets: gifPhaser,
      duration: 1500,
      x: pos.x + distance * Math.cos(angle),
      y: pos.y + distance * Math.sin(angle),
      scaleX: gifSize,
      scaleY: gifSize,
      ease: "Sine.easeInOut",
    });

    // $('.stage').prepend(gif)
    // $(gif).animate({
    //   width: worldToScreen(gifSize)+'px',
    //   left: worldToScreenX(destination.x,this.phaserScene) + "px",
    //   top: worldToScreenY(destination.y,this.phaserScene) + "px",
    // }, 1000)
    setTimeout(() => {
      gifPhaser.destroy();
    }, 3000);

    // Create little particle fire
    const particles = this.phaserScene.add.particles(
      colorizeTexture(
        "particle-circle",
        this.player.playerColor,
        this.phaserScene
      )
    );
    const launchEmitter = particles.createEmitter({
      x: pos.x,
      y: pos.y,
      lifespan: 200,
      speed: { min: 400, max: 600 },
      quantity: 8,
      delay: 80,
      angle: {
        min: angle * (180 / Math.PI) - 60,
        max: angle * (180 / Math.PI) + 60,
      },
      gravityY: 200,
      // tint: hslToHex(60,100,70),
      alpha: { start: this.player.visibility, end: 0, ease: "linear" },
      scale: { start: 0, end: 0.2, ease: "Power3" },
      // blendMode: Phaser.BlendModes.SCREEN,
    });
    setTimeout(() => {
      launchEmitter.stop();
    }, 100);
    setTimeout(() => {
      particles.destroy();
    }, 500);
  }
}
