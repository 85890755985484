import { getCanvasElementToCanvasRatio } from "./getCanvasElementToCanvasRatio";

/**
 * x,y coordinate in 2d cartesian space
 * @typedef {Object} Position
 * @property {number} x
 * @property {number} y
 */
/**
 * Convert iframe coordinates to word coordinates via room scale
 * @param {Position} position
 * @param {Phaser.Scene} phaserScene
 * @returns {Position}
 */

export function convertWorldToIframeCoordinates({ x, y }, phaserScene) {
  return {
    x: x * (phaserScene.room.scale * getCanvasElementToCanvasRatio(phaserScene)),
    y: y * (phaserScene.room.scale * getCanvasElementToCanvasRatio(phaserScene))
  };
}
