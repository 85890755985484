
/**
 * Sniffs for chrome, chromium and IOSChrome
 * Beware -- user agent sniffing is fragile magic.  This code will go stale soon.
 * @returns {Object} An object with booleans: {isChrome, isChromium, isIOSChrome}
 * @see https://stackoverflow.com/a/13348618 (adapted from)
 **/
export function sniffChrome() {
  const isChromium = window.chrome;
  const winNav = window.navigator;
  const vendorName = winNav.vendor;
  const isOpera = typeof window.opr !== "undefined";
  const isIEedge = winNav.userAgent.indexOf("Edg") > -1;
  const isIOSChrome = winNav.userAgent.match("CriOS");
  const isChrome = isChromium !== null &&
    typeof isChromium !== "undefined" &&
    vendorName === "Google Inc." &&
    isOpera === false &&
    isIEedge === false

  return {
    isChromium,
    isIOSChrome,
    isChrome
  }
}

