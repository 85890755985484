import { lerp } from "./lerp";


export function lerpAngular(currentRotation, targetRotation, rate) {
  const currentX = Math.cos(currentRotation);
  const currentY = Math.sin(currentRotation);
  const targetX = Math.cos(targetRotation);
  const targetY = Math.sin(targetRotation);
  const newX = lerp(currentX, targetX, rate);
  const newY = lerp(currentY, targetY, rate);
  return Math.atan2(newY, newX);
}
