<template>
  <div class="uppercase text-green font-head">
    {{ control.label }}
  </div>
  <div class="flex gap-2">
    <HotkeyButton
      :hotkey="control.hotkey"
      :action="targetPlayer"
      :classes="`min-w-max button ${isTargeting ? 'bg-[red]' : ''}`"
    >
      <span>Pick</span> ☝
    </HotkeyButton>
    <span class="max-w-8 overflow-x-hidden grid place-items-center px-2"
      :style="{
        backgroundColor: targetPlayerColor
      }"
    >
      <span class="bg-black bg-opacity-25 px-2 font-[monospace] font-bold text-[1rem] w-max">{{ control.value ?? "none" }}</span>
    </span>
  </div>
</template>

<script>
import { sendMessageToCurrentRoom } from "@/lib/sendMessageToCurrentRoom";
import HotkeyButton from "@/components/PerformerControls/HotkeyButton";
import loggers from '@/loggers'
import { hexToString } from "@/lib/hexToString";

export default {
  name: "InputPerformerControl",
  components: { HotkeyButton },
  data() {
    return {
      /** Whether we are in the process of targeting (UI should give indicator) */
      isTargeting: false,
      /** The current player id (not nickname) we are targeting */
      targetId: null,
    };
  },
  computed: {
    targetPlayerColor() {
      console.log( window?.phaserScene?.playerManager?.getPlayerFromId(this.targetId))
      return hexToString(
        window?.phaserScene?.playerManager?.getPlayerFromId(this.targetId)?.playerColor ?? 0x000000
      )
    }
  },
  watch: {},
  methods: {
    /**
     * Begins listening for custom "player-targeted" event, once the next such event is fired,
     * The nickname it contains is our new target.
     * For ease, our rooms track players with nickname (much easier to read) NOT official player ids (the long char string generated by socketIO)
     */
    targetPlayer() {
      loggers.playerTargeting("targetPlayer()");

      // St targeting
      this.isTargeting = true;

      window.addEventListener(
        "player-targeted",
        (e) => {
          // No longer targeting
          this.isTargeting = false;

          /** id from event */
          this.targetId = e.detail.id

          /** nickname from event */
          const nickname = e.detail.nickname;

          loggers.playerTargeting('Received "player-targeted" event with id',nickname,this.targetedId);

          // Update this controls value in the store (which will propogate to our component template via prop)
          this.$store.commit("updateRoomPerfomerControl", {
            id: this.control.id,
            update: {
              value: nickname, // Reminder: rooms track players w/ nicknames, not id
            },
          });

          // Let the room know the targeted id
          sendMessageToCurrentRoom(
            "performer-action-triggered-" + this.control.id,
            nickname // Reminder: rooms track players w/ nicknames, not id
          );
        },

        // We only need to do this once
        { once: true }
      );
    },
  },
  mounted() {},
  props: {
    control: Object,
  },
};
</script>
