<template>
  <div class="bg-black text-white absolute top-0 right-0 px-2 py-1 text-sm">
    <h2 class="font-head text-green uppercase">Gadgets</h2>
    <ul>
      <li v-for="(gadget, slot) in $store.getters.stableState.gadgets"
        :key="slot"
        class="flex"
      >
        <h3 class="font-head w-8">{{  GADGET_SLOT_INPUTS[slot] }}</h3>
        <p class="text-gray">{{ gadget ? gadget.name : ""}}</p>
      </li>
    </ul>
  </div>
</template>

<script>
import {GADGET_SLOT_INPUTS} from '../lib/constants'

export default {
  created() {
    this.GADGET_SLOT_INPUTS = GADGET_SLOT_INPUTS
  }
}
</script>

<style lang="scss" scoped>
</style>
