import {playerContainerWidth} from '@/lib/constants'
import {lerpAngular} from '@/lib/lerpAngular'

/**
 * @typedef {Object} Dangle
 * @property {Object} bob - the Matter.js object of the dangle's "bob" (the thing that dangles from a pendulum), a ploygon attached to the constraint
 * @property {Object} constraint - the Matter.js object of the dangle's constraint
 */

/**
 * Creates a "dangle" which is a matter js object that is attached to the player via a constraint and "dangles."  You can, for example, use this to animate the sprite so it's movement is physical/emergent.
 * @param {Object} gadget - The gadget object which will contain the dangle
 * @param {Object} [options] - Optional list of configuration options for the dangle
 * @param {number} [options.bobX=0] - X offset of the bob's initial placement relative to player origin (center)
 * @param {number} [options.bobY=0] - Y offset of the bob's initial placement relative to player origin (center)
 * @param {number} [options.bobFrictionAir=0.1] - The air friction of the bob, changing this number will change the speed it moves
 * @param {number} [options.constraintX=0] - X offset of the constraint's attachment point to the player, relative to player origin (center)
 * @param {number} [options.constraintY=0] - Y offset of the constraint's attachment point to the player, relative to player origin (center)
 * @param {number} [options.length=playerContainerWidth*0.5] - Length of constraint
 * @param {number} [options.stiffness=0.1] - Stiffness of constraint
 * @return {Dangle}
 */
export function createDangle (gadget, options) {

  const defaultOptions = {
    bobX: 0,
    bobY: 0,
    bobFrictionAir: 0.1,
    constraintX: 0,
    constraintY: 0,
    length: playerContainerWidth*0.5,
    stiffness: 0.1,
    ignoreGravity: false,
  }
  options = {...defaultOptions,...options}

  let bob, constraint

  // Initiate Matter js physics if we belong to MyPlayer
  if(gadget.player.isMyPlayer) {

    // I SWEAR bob is a thing in pendulum phsyics.  This is a good name, fuck you.
    bob = gadget.phaserScene.matter.add.polygon(gadget.player.playerPhaserObject.x + options.bobX, gadget.player.playerPhaserObject.y+ options.bobY, 6, 14, {
      friction: 0,
      frictionAir: options.bobFrictionAir,
      restitution: 0,
      ignoreGravity: options.ignoreGravity,
      inertia: Infinity,
      isStatic: false,
    });
    constraint = gadget.phaserScene.matter.add.constraint(bob, gadget.player.playerPhaserObject, options.length, options.stiffness);
    constraint.pointB = {x: options.constraintX, y:options.constraintY}
    constraint.damping = 0.1

    // angle constraints: https://github.com/liabru/matter-js/pull/837
  }

  // TODO: Bummer we need to fix that the state variable name is hardcoded here because if you have more than one dangle in a gadget, they will overwrite
  gadget.state.dangleRotation = 0

  return {bob:bob, constraint:constraint}
}
/**
 * Animates the dangle by updating the sprite to coincide with the angle of the MatterJS constraint. To be called in the update function of the gadget.
 * @param {Object} sprite - The Phaser game object of containing the sprite for the gadget
 * @param {Dangle} dangle - The object containing the dangle to be animated
 * @param {Object} gadget - The 'this' object of the gadget calling this function
 */
export function updateDangle (sprite,dangle,gadget) {

  // Calculate rotation if we belong to myplayer, send this to store (which will send to heartbeat)
  if(gadget.player.isMyPlayer){
    gadget.state.dangleRotation = getDangleRelativeAngle(dangle, gadget) + Math.PI/2
    sprite.setRotation(gadget.state.dangleRotation)
  }

  // Render from state (everybody else does this)
  else {
    if(
      typeof sprite.rotation === 'undefined' ||
      typeof gadget.state.dangleRotation === 'undefined'
    ) return
    sprite.setRotation(lerpAngular(sprite.rotation, gadget.state.dangleRotation, 0.2))
  }
}

/**
 * Returns angle between bob and player anchor in world coordinates in radians
 * @param {Dangle} dangle - The object containing the dangle whose angle to calculate
 * @param {Object} gadget - The 'this' object of the gadget calling this function
 * @return {Number} The angle between bob and player anchor in radians
 */
export function getDangleWorldAngle (dangle, gadget) {
  //
  const p1 = {
    x: gadget.phaserScene.matter.constraint.pointAWorld(dangle.constraint).x,
    y: gadget.phaserScene.matter.constraint.pointAWorld(dangle.constraint).y
  }

  const p2 = {
    x: gadget.phaserScene.matter.constraint.pointBWorld(dangle.constraint).x,
    y: gadget.phaserScene.matter.constraint.pointBWorld(dangle.constraint).y
  }

  return Math.atan2(p2.y - p1.y, p2.x - p1.x)
}

/**
 * Returns bob angle relative to player container in radians (properly accounting for player rotation)
 * @param {Dangle} dangle - The object containing the dangle whose angle to calculate
 * @param {Object} gadget - The 'this' object of the gadget calling this function
 * @return {Number} The relative angle between bob and player anchor in radians
 */
export function getDangleRelativeAngle(dangle, gadget) {
  return getDangleWorldAngle(dangle, gadget) - (gadget.player?.playerPhaserObject?.rotation ? gadget.player.playerPhaserObject.rotation : 0)
}

/**
 *  Will remove the constraint and bob of the dangle from the MatterJS simulation. Only myPlayer runs MatterJs, so this code only runs for myPlayer
 * @param {Dangle} dangle - The object containing the dangle to be destroyed
 * @param {Object} gadget - The 'this' object of the gadget calling this function
 */
export function destroyDangle(dangle, gadget) {
  if(gadget.player.isMyPlayer){
    gadget?.phaserScene?.matter?.world?.remove(gadget.phaserScene.matter.world, dangle.constraint)
    gadget?.phaserScene?.matter?.world?.remove(gadget.phaserScene.matter.world, dangle.bob)
  }
}