/**
 * Throws an error if the update object has top level keys absent in the target object
 * (This is almost certainly due to an error--e.g. specifying the wrong key)
 * @param {Object} update
 * @param {Object} target
 */

export function throwErrorIfNotKeySubset(update, target) {
  const updateKeys = Object.keys(update);
  const targetKeys = Object.keys(target);
  const keysMatch = updateKeys.every(val => targetKeys.includes(val));
  if (!keysMatch)
    throw new Error(`Update {${updateKeys}} contains keys not in target {${targetKeys}}`);
}
