import { canvasWidth } from "./constants";

/**
 * Calculate ratio of canvase element width to canvas width.
 * These can be different!  E.g. the element might be 2000px wide, even if the canvas is only painting an image 800px wide.
 * @param {Phaser.Scene} phaserScene
 * @returns ratio of canvase element width to canvas width
 */

export function getCanvasElementToCanvasRatio(phaserScene) {
  return phaserScene.scale.canvasBounds.width / canvasWidth;
}
