import Gadget from "../Gadget";
import { playerContainerWidth, playerContainerHeight } from "@/lib/constants";
import { getWorldPointOfSprite } from "@/lib/getWorldPointOfSprite";
import {
  createDangle,
  updateDangle,
  getDangleWorldAngle,
  destroyDangle,
} from "@/game/classes/gadgets/functions/dangle";
import { colorizeTexture } from "../colorizeTexture";

export default class ButtThrusters extends Gadget {
  constructor(gadgetProps) {
    super(gadgetProps);
  }

  create() {
    super.create();

    // Create and size ButtThrusters sprite
    this.gadgetSprite = this.phaserScene.add.image(
      0,
      playerContainerHeight * 0.5,
      "thrusters"
    );
    this.gadgetSprite.displayWidth = playerContainerWidth * 0.5;
    this.gadgetSprite.scaleY = this.gadgetSprite.scaleX;
    this.gadgetSprite.setOrigin(0.5, 0.3);

    // Add to our gadget container
    this.gadgetManager.gadgetContainer.add(this.gadgetSprite);

    // Build dangle
    this.dangle = createDangle(this, {
      bobX: 0,
      bobY: playerContainerHeight,
      constraintX: 0,
      constraintY: playerContainerHeight * 0.5,
      length: playerContainerWidth * 0.5,
    });

    // Remove dangle bob collisions (so this can swivel 360)
    if (this.player.isMyPlayer) this.dangle.bob.isSensor = true;
  }

  // Will only be called ever by MyPlayer
  activate(energy) {
    super.activate(energy);

    // Send relay with color and angle data
    this.broadcastRelay({
      angle: getDangleWorldAngle(this.dangle, this),
      // color: hslToHex(Math.random()*255,100,70),
      energy: energy,
    });
  }

  handleRecieveRelay(data) {
    super.handleRecieveRelay(data);
    const { options } = data;
    const { energy } = data.options;

    // Apply force to player if MyPlayer
    if (this.player.isMyPlayer) {
      const forceAbs = window?.cheats?.thrust ?? 8 * Math.sqrt(energy) + 3;
      this.player.playerPhaserObject.applyForce({
        x: Math.cos(options.angle) * forceAbs,
        y: Math.sin(options.angle) * forceAbs,
      });
    }

    // Launch a particle firework
    this.launchFirework(
      options.angle + Math.PI,
      this.player.playerColor,
      energy
    );
  }

  // This launches a particle firework of color/angle
  launchFirework(angle, color, energy) {
    // Get sprite of correct color
    const particles = this.phaserScene.add.particles(
      colorizeTexture(
        "particle-circle",
        this.player.playerColor,
        this.phaserScene
      )
    );

    let { x, y } = getWorldPointOfSprite(this.gadgetSprite);
    x += Math.cos(angle) * playerContainerWidth * 0.2;
    y += Math.sin(angle) * playerContainerWidth * 0.2;

    // Calculate destination for firework
    const distance = playerContainerWidth * (0.2 + 0.3 * energy);
    const destinationX = x + distance * Math.cos(angle);
    const destinationY = y + distance * Math.sin(angle);

    // Make particle emitter for trail that leads up to firework
    const trailEmitter = particles.createEmitter({
      x: {
        start: x,
        end: destinationX,
        steps: 100,
      },
      y: {
        start: y,
        end: destinationY,
        steps: 100,
      },
      lifespan: 200 * (1 + energy),
      speed: { min: 100, max: 300 },
      quantity: 8,
      tint: color,
      alpha: {
        start: 1 * this.player.visibility,
        end: 0 * this.player.visibility,
        ease: "linear",
      },
      scale: { start: 0.3, end: 0.5, ease: "Power3" },
      blendMode: Phaser.BlendModes.ADD,
    });

    setTimeout(() => {
      // Stop the trail emitter
      trailEmitter.stop();

      // Shake the camera if very big
      if (energy > 0.9) {
        this.phaserScene.cameras.main.shake(800, 0.02);
      }

      // Create explosion
      const explosionEmitter = particles.createEmitter({
        x: destinationX,
        y: destinationY,
        lifespan: 500 + 500 * Math.pow(energy, 2),
        speed: { min: 50, max: 1200 * Math.pow(energy, 2) },
        quantity: 5 * (0.5 + energy),
        alpha: {
          start: 1 * this.player.visibility,
          end: 0 * this.player.visibility,
          ease: "linear",
        },
        scale: { start: 0, end: 6 * energy, ease: "Power3" },
        tint: color,
        blendMode: Phaser.BlendModes.ADD,
      });

      // Stop explosion emitter
      setTimeout(() => {
        explosionEmitter.stop();
      }, 100);
    }, 150);

    // Cleanup particles/emitters
    setTimeout(() => {
      particles.destroy();
    }, 2000);
  }

  update = () => {
    super.update();
    updateDangle(this.gadgetSprite, this.dangle, this);
  };

  destroy() {
    destroyDangle(this.dangle, this);
    this.gadgetSprite.destroy();
    super.destroy();
  }
}
