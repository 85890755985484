import loggers from "../loggers";
import store from "./store";

/**
 * Set betweenRooms property in both stable/volatile channels in such a way that it will be set locally AND throughout the network
 * @param {Boolean} newValue
 * @param {Phaser.Scene} phaserScene
 */

export function setMyPlayerBetweenRooms(newValue, phaserScene) {

  if(typeof phaserScene === 'undefined') throw new Error('Tried to call setMyPlayerBetweenRooms without phaserScene argument')

  loggers.changeRoom('setMyPlayerBetweenRooms',newValue)
  // Store is authoratative for stable state,
  store.commit('updatePlayerStableState', {
    betweenRooms: newValue,
  });

  // MyPlayer object is authoratitive for volatile state
  if (phaserScene?.playerManager?.myPlayer?.betweenRooms?.volatile) {
    phaserScene.playerManager.myPlayer.betweenRooms.volatile = newValue;
  }

  // The above changes will proliferate throughout the network
}
