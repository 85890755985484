import { EventEmitter } from "events";
import loggers from "../loggers.js";
import iceServers from "./iceServers.js";
import getCohortName from "./getCohortName.js";
import firebaseFunctions from "./firebaseFunctions.js";
import { httpsCallable } from "firebase/functions";
import {io} from "socket.io-client";
/**
 * Calls firebase function which handles sensitive interactions with antmediaserver,
 * retrieves the livestream id from cohort name
 * @param {Object} data `{cohort}` contains cohort property
 * @param {string} data.cohort name of the cohort (e.g. via getCohortName())
 */
const getLivestreamId = httpsCallable(firebaseFunctions, "getLivestreamId");

/**
 * This class is not functional on its own, but provides the default shared behavior leveraged by its children:
 * - LivestreamPublisher
 * - LivestreamSubscriber
 *
 * `this.events` is an EventEmitter:
 * - `initialized`: fired when antMediaSDK gives 'inititialized' callback
 *
 * @param {string} videoElementId id of the video element that will house the Livestream
 * @param {string} label label used to identify this Livestream in logs
 *
 */
export default class LivestreamDefaults {
  constructor(label = "Livestream") {
    /** Event emitter */
    this.events = new EventEmitter();
    /** base URL of antmedia server, no trailing slash, no protocol */
    this.mediasoupServerUrl = process.env.VUE_APP_MEDIASOUP_SERVER_BASE_URL;
    /** Display label, mostly for logs, but might use this for labels in the future? */
    this.label = label;
    /** Holds the Socket.io Client library object */
    this.socketio = io(this.mediasoupServerUrl)
 

  }

  /**
   * Default initialization behavior
   */
  async init() {
    this.log("init()");

  }

  /**
   * A wrapper to our debug loggers that includes our label--
   * since there may be a few LivestreamDefaults objects to keep track of in the logs!
   * @param  {...any} args what to log
   */
  log(...args) {
    loggers.Livestream(`[${this.label}]`, ...args);
  }


  /** Leverage videoElementId to get video element */
  get videoElement() {
    return document.getElementById(this.videoElementId);
  }

  /**
   * Cleanup and destroy
   * (not tested, since as of now, these Livestream objects are never destroyed)
   */
  destroy() {

  }
}
