import Gadget from "../Gadget";
import {
  playerContainerWidth,
  playerContainerHeight,
  canvasWidth,
} from "@/lib/constants";
import {
  createDangle,
  updateDangle,
  getDangleRelativeAngle,
  destroyDangle,
} from "@/game/classes/gadgets/functions/dangle";
import { createSoundwave } from "@/game/classes/gadgets/functions/sound";
import { lerp } from "@/lib/lerp";
import { getWorldPointOfSprite } from "@/lib/getWorldPointOfSprite";
import { distance } from "@/lib/distance";

export default class Gator extends Gadget {
  constructor(gadgetProps) {
    super(gadgetProps);
  }

  create() {
    super.create();

    // Create and size gator sprite
    this.gadgetSprite = this.phaserScene.add.image(
      playerContainerWidth * 0.4,
      0,
      "gator"
    );
    this.gadgetSprite.displayWidth = playerContainerWidth * 0.5;
    this.gadgetSprite.scaleY = this.gadgetSprite.scaleX;
    this.gadgetSprite.setOrigin(0.5, 0);

    // Add to our gadget container
    this.gadgetManager.gadgetContainer.add(this.gadgetSprite);

    // Build dangle
    this.dangle = createDangle(this, {
      bobX: playerContainerWidth,
      bobY: 0,
      bobFrictionAir: 0.2,
      constraintX: playerContainerWidth * 0.4,
      constraintY: playerContainerHeight * 0.1,
      length: playerContainerWidth * 0.6,
    });

    // Prevent inner collisions
    if (this.player.isMyPlayer) this.dangle.bob.isSensor = true;

    // Variables used to animate scale
    this.scaleMultiplier = 1;
    this.originalScale = this.gadgetSprite.scaleX;

    // Create a single sound instance to recycle
    this.soundInstance = null;
  }

  activate(energy) {
    super.activate(energy);
    this.broadcastRelay({ energy: energy });
  }

  handleRecieveRelay(data) {
    const gatorBaselineVolume = window.gatorVolume ?? 0.6;
    // console.log(this.phaserScene.cameras)
    super.handleRecieveRelay(data);
    const { energy } = data.options;

    // If we are not in focus, skip all this.
    // If we execute the below code with the browser out of focus, it will queue up all the sounds animations
    // And it will explode all at once when the tab refocuses--possibly with enough power to appcrash.
    if (!document.hasFocus()) return;

    // Get distance to myplayer to determine volume (wont allow 0, so min 1)
    const distanceToMyPlayer = Math.max(
      distance(
        this.player.playerPhaserObject.x,
        this.player.playerPhaserObject.y,
        this.phaserScene.playerManager.myPlayer.playerPhaserObject.x,
        this.phaserScene.playerManager.myPlayer.playerPhaserObject.y
        // this.phaserScene.cameras.main.worldView.centerX,
        // this.phaserScene.cameras.main.worldView.centerY
      ),
      1
    );
    const distanceSimulationMultiplier = Math.min(
      1,
      Math.max(
        0.25 / Math.pow((distanceToMyPlayer / canvasWidth) * 0.2, 1),
        0.1
      )
    );
    // console.log(distanceSimulationMultiplier);

    const panSimulator =
      (this.player.playerPhaserObject.x -
        this.phaserScene.cameras.main.worldView.centerX) /
        canvasWidth /
        2 +
      0.5;

    if (this.soundInstance?.isPlaying) {
      this.soundInstance?.stop();
    }
    this.soundInstance = this.phaserScene.sound.add("airhorn", {
      rate: 1.2 - Math.pow(energy, 2) * 0.4,
      volume:
        (2 * Math.pow(energy, 2) + 0.1) *
        distanceSimulationMultiplier *
        gatorBaselineVolume,
      pan: Math.max(Math.min(1, panSimulator), 0),
    });
    this.soundInstance.play();

    // Make me big
    this.scaleMultiplier += 3 * energy;

    const spriteCoords = getWorldPointOfSprite(this.gadgetSprite);

    const gatorLength = playerContainerWidth * (1 + energy);
    const gatorAngle = this?.state?.dangleRotation
      ? this.state.dangleRotation + Math.PI / 2
      : 0;
    createSoundwave(
      spriteCoords.x + Math.cos(gatorAngle) * gatorLength,
      spriteCoords.y + Math.sin(gatorAngle) * gatorLength,
      this.soundInstance,
      this.phaserScene,
      { radius: Math.pow(energy, 2) * 10 + 0.2, alpha: this.player.visibility }
      // this.player.playerColor // A little too dark to see for now--will think on this
    );

    // Add a little upward force to the dangle
    if (this.player.isMyPlayer) {
      this.phaserScene.matter.body.applyForce(
        this.dangle.bob,
        { x: this.dangle.bob.position.x, y: this.dangle.bob.position.y },
        { x: 0, y: -0.1 * (energy + 0.1) }
      );

      this.player.playerPhaserObject.setAngularVelocity(
        Math.pow(energy, 2) *
          (Math.sin(getDangleRelativeAngle(this.dangle, this)) > 0 ? 1 : -1)
      );
    } else {
      this?.player?.positionIndicator?.ping(energy);
    }
  }

  update() {
    super.update();
    updateDangle(this.gadgetSprite, this.dangle, this);

    // Animate scale and lerp back to normal
    this.gadgetSprite.scaleX = this.originalScale * this.scaleMultiplier;
    this.gadgetSprite.scaleY = this.originalScale * this.scaleMultiplier;
    this.scaleMultiplier = lerp(this.scaleMultiplier, 1, 0.1);
  }

  destroy() {
    destroyDangle(this.dangle, this);
    this.gadgetSprite.destroy();
    super.destroy();
  }
}
