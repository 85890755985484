import { io } from "socket.io-client";
import SimplePeer from "simple-peer/simplepeer.min.js";
import { deserialize, serialize } from "bson";
import { EventEmitter } from "events";
import iceServers from "./iceServers.js";

import loggers from "../loggers.js";
/**
 * Time in ms between sending state updates
 */
const VOLATILE_STATE_UPDATE_INTERVAL = 30;
const STABLE_STATE_UPDATE_INTERVAL = 1000;

/**
 * Contains relevant info and objects to manage p2p connection
 * @typedef {string} peerId - ID of a peer connection, given by socket.id
 */

/**
 * An object containing some or all of the data in the store's stable state
 * Will be used to overwrite/update said store stable state
 * @typedef {Object} StableState
 *
 * @property {Object} [player]
 * @property {Object} [gadgets]
 */

/**
 * An object containing some or all of the data in the store's volatile state
 * Will be used to overwrite/update said store volatile state
 * @typedef {Object} VolatileState
 *
 * @property {Object} [player]
 * @property {Object} [gadgets]
 *
 * @property {number} [lastActive] - last time user had an interaction (to detect idelness)
 */

/**
 * Data for a "relay" -- a packet of data passed between peers
 * meant to trigger an ephemeral, one-off event
 * @typedef {Object} RelayData
 *
 * @property {string} type - e.g. 'use'
 * @property {Object} options - any configuration data needed to execute the relay
 */

/**
 * Contains relevant info and objects to manage p2p connection
 * @typedef {Object} PlayerConnection
 *
 * @property {string} nickname - human readable nickname
 *
 * @property {Object} volatileChannel
 * @property {SimplePeer} volatileChannel.peer - the SimplePeer connection
 * @property {number} volatileChannel.lastUpdate - time of last update
 * @property {number} volatileChannel.lastUpdateDelta - time since last update
 *
 * @property {Object} stableChannel
 * @property {SimplePeer} stableChannel.peer - the SimplePeer connection
 */

/**
 * Handles all connections with servers and p2p
 * @fires NetworkProvider#ready
 * @fires NetworkProvider#peer-stable-state-received
 * @fires NetworkProvider#peer-volatile-state-received
 * @fires NetworkProvider#receive-relay
 * @fires NetworkProvider#peer-connection
 * @fires NetworkProvider#peer-disconnection
 * @fires NetworkProvider#catastrophic-failure
 * @fires NetworkProvider#nickname-chosen
 * @fires NetworkProvider#global-connections-summary-update
 */
export default class NetworkProvider {
  /**
   * Function used to generate a player state update, returns the player state.
   * The NetworkProvider doesn't care how this is done...
   * e.g. you can get real states from the player or stub it for a bot
   * @callback getVolatileState
   * @returns {PlayerStateUpdate}
   */

  /**
   * An object of options for the networkProvider
   * @typedef {Object} NetworkProviderOptions
   * @property {getVolatileState} [getVolatileState=()=>null] - function that returns the player state, used to build our volatile update
   * @property {getStableState} [getStableState=()=>null] - function that returns the player state, used to build our stable full update
   * @property {Object} [peerOptions={}] - additional objects that are passed directly to simple-peer instantiation, e.g. wrtc (for node)
   * @property {boolean} [isTesterBot=false] - mark true if this is a test bot. We will let the server know for performance optimizations (e.g. don't connect bots to other bots, just to humans)
   */

  /**
   * Constructs a network handler
   * @param {string} serverUrl url for the signaling server
   * @param {NetworkProviderOptions} options
   */
  constructor(serverUrl, options = {}) {
    this.serverUrl = serverUrl;

    // Set defaults and merge in option arguments
    this.options = {
      getVolatileState: () => null,
      getStableState: () => null,
      peerOptions: {},
      Peer: SimplePeer,
      isTesterBot: false,
      cohortName: "default",
      ...options,
    };

    /**
     * Tracks health of all connections, is reported to server
     * The server will use this information to assemble a global report of who is successfully connected to who
     * @example
     * ```js
     * {
     *   peerid1wqewqweqwe: 'open',
     *   peerid2aafewqacaw: 'connecting',
     *   // etc.
     * }
     * ```
     */
    this.localConnectionsSummary = {};
    /** Stores second most recent connections summary for comparison to detect change */
    this.previousLocalConnectionsSummary = null;

    /** Global summary of all connections as reported by the server */
    this.globalConnectionsSummary = null;

    /**
     * An event emitter to handle all networkProvider events
     */
    this.events = new EventEmitter();

    /**
     * Our id, which, once ready, is just socket.id
     */
    this.id = null;

    /**
     * Interval fired rapidly to send state updates to peers
     */
    this.volatileStateUpdateInterval = null;

    /**
     * Keeps track of the last time we broadcast a volatile state update
     */
    this.timeOfLastVolatileStateBroadcast = null;

    /**
     * Keep track of whether we have failed catastophically so we dont call this more than once
     */
    this.hasAlreadyCatastrophicallyFailed = false;

    /** Human readable nickname given by the server */
    this.nickname = false;

    /** Tracks whether NetworkProvider is ready */
    this.isReady = false;
  }

  /**
   * Compares new connection summary with previous connection summary (by way of JSON.stringify)
   * @returns {boolean}
   */
  checkIfConnectionsSummaryChanged() {
    // Stringify and compare
    const previousStringified = JSON.stringify(
      this.previousLocalConnectionsSummary
    );
    const newStringified = JSON.stringify(this.localConnectionsSummary);
    if (previousStringified === newStringified) return false;

    // Otherwise, if changed, and we should update our copy of previous, too
    this.previousLocalConnectionsSummary = { ...this.localConnectionsSummary };
    return true;
  }

  /**
   * Sends local connections summary to server via socket, IF there has been a change
   * The server will use this information to assemble a global report of who is successfully connected to who
   */
  reportLocalConnectionSummary() {
    if (this.checkIfConnectionsSummaryChanged()) {
      loggers.NetworkProvider(
        `Connections summary changed`,
        this.localConnectionsSummary
      );
      this.socket.emit(
        "local-connections-summary-update",
        this.localConnectionsSummary
      );
    }
  }

  /**
   * Changes connection summary for a particular peer,
   * and reports the whole summary to the server if there is any change
   * The server will use this information to assemble a global report of who is successfully connected to who
   * @param {peerId} id
   * @param {string} summary
   */
  updateLocalConnectionSummary(id, summary) {
    this.localConnectionsSummary[id] = summary;
    this.reportLocalConnectionSummary();
  }

  /**
   * Start the network handler, necessary to call when creating
   */
  init() {
    // Rapidly broadcast volatile state many times per second
    // This is the main way volatile state proliferates
    this.volatileStateUpdateInterval = setInterval(
      this.broadcastVolatileState,
      VOLATILE_STATE_UPDATE_INTERVAL
    );

    //
    this.stableStateUpdateInterval = setInterval(
      this.rebroadcastStableState,
      STABLE_STATE_UPDATE_INTERVAL
    );

    // Keep track of when this player becomes inactive
    this.keepTrackOfLastActive();

    /**
     * Our SocketIO handler, connects to server, notes if this is a bot in handshake query
     */
    const socketEndpoint = this.serverUrl + "/" + this.options.cohortName + "/";
    loggers.NetworkProvider(
      "connecting via socket to signaling server",
      socketEndpoint
    );
    this.socket = io(socketEndpoint, {
      // Our way of letting the server instantaneously know if we are a bot or not
      query: `bot=${this.options.isTesterBot ? "true" : "false"}`,
    });

    /**
     * An object containing PlayerConnection objects with peerId as key
     * @type {Object.<peerId, PlayerConnection>}
     */
    this.connections = {};

    // Handle connection to server
    this.socket.on("connect", () => {
      loggers.NetworkProvider("connected");
      this.id = this.socket.id;
    });

    this.socket.on("connect_error", (err) => {
      console.warn("SERVER CONNECTION ERROR", err);
      const errorMessage = err.message.includes("Invalid namespace")
        ? "Looks like the cohort was not found."
        : "";
      this.catastrophicFailure(
        `Could not connect to the server. ${errorMessage}`
      );
    });

    this.socket.on("nickname", (nickname) => {
      this.nickname = nickname;
      /**
       * @event NetworkProvider#nickname-chosen
       * @param {string} nickname
       */
      this.events.emit("nickname-chosen", nickname);

      // Let anyone listening know the network provider is ready
      this.ready();
    });

    // When receiving a global connections summary from the server, transmit it to all listening and store it
    this.socket.on("global-connections-summary-update", (summary) => {
      this.globalConnectionsSummary = summary;
      /**
       * @event NetworkProvider#global-connections-summary-update
       * @param {GlobalConnectionSummary} summary
       */
      this.events.emit(
        "global-connections-summary-update",
        this.globalConnectionsSummary
      );
    });

    /**
     * Fires once the server advertises an available peer
     * Starts the job of connecting them and managing their connection
     * @param {*} data
     */
    const connectToAdvertisedPeer = (data) => {
      /**
       * This id comes from the socket id of the other client
       * @type {peerId}
       */
      const peerId = data.peerId;

      // Add this to network summary
      this.updateLocalConnectionSummary(peerId, "created");

      // Check if this player already exists
      if (typeof this.connections[peerId] !== "undefined") {
        // Check if its been initialized
        if (
          this.connections?.[peerId]?.volatileChannel?.readyState &&
          this.connections?.[peerId]?.stableChannel?.readyState
        ) {
          // If so, is it closed?
          if (
            this.connections[peerId].volatileChannel.readyState === "closed" ||
            this.connections[peerId].stableChannel.readyState === "closed"
          ) {
            // Then its a garbage connection, delete it and carry on connecting
            this.deletePlayer(peerId, { tellServer: false });
          }
          // If its initialized and not closed, do not overwrite
          else return;
        }
        // If its not fully initialized yet, do not overwrite (its probably being created)
        else return;
      }

      // Configure and create a SimplePeer
      const peerOptions = {
        initiator: data.initiator,
        channelConfig: { ordered: true },
        // sdpTransform: transformLivestreamSdpForMoonDungeon,
        trickle: true,
        config: {
          iceServers,
          // Enable this if you want to FORCE use of TURN servers
          // iceTransportPolicy: 'relay',
        },
        ...this.options.peerOptions,
      };
      const peer = new this.options.Peer(peerOptions);

      // Add our PlayerConnection object to the list
      this.connections[peerId] = {
        /** Interval that will fire repeatedly to check connection */
        checkConnectionInterval: null,
        /**
         * Objects/data pertaining to the stable channel--used for relays and stableState changes
         * (Currently this is just an alias for the volatileChannel--but we might split them up)
         */
        stableChannel: {
          /** simple-peer object */
          peer,
          get readyState() {
            return peer?._channel?.readyState;
          },
        },
        /**
         * Objects/data pertaining to the volatile channel--used to transmit continuous/unimportant updates to phsyics
         */
        volatileChannel: {
          /** simple-peer object */
          peer,
          get readyState() {
            return peer?._channel?.readyState;
          },
          /** Last update */
          lastUpdate: Date.now(),
          /** Time since last update */
          lastUpdateDelta: 0,
        },
      };

      // Fires when "the peer wants to send signaling data to the remote peer"
      // I don't fully understant the signaling process
      peer.on("signal", (data) => {
        this.socket.emit("signal", {
          signal: data,
          peerId: peerId,
        });
      });

      // Handle errors
      peer.on("error", (e) => {
        console.warn("Error sending connection to peer %s:", peerId, e);
      });

      // STABLE CHANNEL
      // Once all the signals fly and do their thing, we'll actually connect!
      peer.on("connect", () => {
        loggers.NetworkProvider(
          "stable channel - peer connect event fired for",
          peerId
        );
      });

      // VOLATILE CHANNEL
      // Once all the signals fly and do their thing, we'll actually connect!
      peer.on("connect", () => {
        loggers.NetworkProvider(
          "volatile channel - peer connect event fired for",
          peerId
        );

        /**
         * @event NetworkProvider#peer-connection
         * @param {peerId} peerId
         */
        this.events.emit("peer-connection", peerId);
      });

      // Check for closure if the peer.on('close') handler doesn't work (it often takes a few seconds)
      this.connections[peerId].checkConnectionInterval = setInterval(() => {
        // This tells us if our data connection is reading data
        // If not, we had better close it.
        if (
          this.connections?.[peerId]?.volatileChannel?.readyState ===
            "closed" ||
          this.connections?.[peerId]?.stableChannel?.readyState === "closed"
        ) {
          loggers.NetworkProvider(
            "deleting player %s because - checkConnectionInterval found it closed",
            peerId
          );
          this.deletePlayer(peerId, { tellServer: true });
        }

        this.updateLocalConnectionSummary(
          peerId,
          this.connections?.[peerId]?.volatileChannel?.readyState
        );

        // Check if its been too long since a volatile message.
        // Ideally we'll have smelled a problem long before this--but this is a safety net.
        const maxAllowableTimeBetweenVolatileUpdates = 4000;
        const timeSinceVolatileUpdate =
          Date.now() - this.connections?.[peerId]?.volatileChannel.lastUpdate;
        if (timeSinceVolatileUpdate > maxAllowableTimeBetweenVolatileUpdates) {
          loggers.NetworkProvider(
            "deleting player %s because - been too long since we received a volatile message",
            peerId
          );
          this.deletePlayer(peerId, { tellServer: true });
        }
      }, 100);

      // STABLE CHANNEL
      // Handle incoming data
      peer.on("data", (data) => {
        // Convert data from binary
        const unserializedData = deserialize(data);

        //  Make sure this has a message type
        if (typeof unserializedData.rtcMessageType === "undefined") return;
        const { rtcMessageType } = unserializedData;

        // Route relay messages (e.g. gadget firings, use key)
        if (rtcMessageType === "relay") {
          const { rtcMessageType, ...relayData } = unserializedData;
          this.handleRecieveRelay(peerId, relayData);
        }

        // Route stable-state messages (FULL stable state update)
        if (rtcMessageType === "stable-state") {
          const { rtcMessageType, ...stableState } = unserializedData;
          /**
           * @event NetworkProvider#peer-stable-state-received
           * @param {peerId} peerId
           * @param {StableState} stableState
           */
          this.events.emit("peer-stable-state-received", peerId, stableState);
        }

        // Route stable-update messages (PARTIAL stable state update)
        if (rtcMessageType === "stable-state-update") {
          const { rtcMessageType, ...stableStateUpdate } = unserializedData;
          /**
           * @event NetworkProvider#peer-stable-state-update-received
           * @param {peerId} peerId
           * @param {StableState} stableStateUpdate
           */
          this.events.emit(
            "peer-stable-state-update-received",
            peerId,
            stableStateUpdate
          );
        }

        // Route request-stable-state messages
        if (rtcMessageType === "request-stable-state") {
          // Another player is requesting the current stable state. Send it to them
          this.sendStableState(peerId);
        }
      });

      // VOLATILE CHANNEL
      // Handle incoming data
      peer.on("data", (data) => {
        // Translate binary data
        const unserializedData = deserialize(data);

        // Make sure this is a volatile-state message
        if (
          unserializedData.rtcMessageType &&
          unserializedData.rtcMessageType === "volatile-state"
        ) {
          // Keep track of how long it has been since last update
          const now = Date.now();
          this.connections[peerId].volatileChannel.lastUpdateDelta =
            now - this.connections[peerId].volatileChannel.lastUpdate;
          this.connections[peerId].volatileChannel.lastUpdate = now;

          // Deconstruct data and emit event
          const { rtcMessageType, ...volatileState } = unserializedData;
          /**
           * @event NetworkProvider#peer-volatile-state-received
           * @param {peerId} peerId
           * @param {VolatileState} volatileState
           */
          this.events.emit(
            "peer-volatile-state-received",
            peerId,
            volatileState
          );
        }
      });

      // Handle connection close
      // In my experience, the peer closing doesn't happen much... the socket server will fire a disconnect first
      // But this is another important path to removing a dead connection that might need to be fleshed out
      peer.on("close", () => {
        loggers.NetworkProvider(
          "deleting player %s because - Peer close event fired",
          peerId
        );
        this.deletePlayer(peerId, { tellServer: true });
      });
    };

    // Peer signal data is sent from signaling server
    this.socket.on("signal", (data) => {
      this.connections?.[data.peerId]?.volatileChannel?.peer?.signal(
        data.signal
      );
      loggers.NetworkProvider(
        "incoming signal from %s via socket",
        data.peerId
      );
    });

    // This socket message is fired if anybody disconnects from the server--if so, get rid of em
    this.socket.on("peer-disconnect", (payload) => {
      loggers.NetworkProvider(
        "deleting player %s because - their socket disconnected from server",
        payload.peerId
      );
      this.deletePlayer(payload.peerId, { tellServer: false });
    });

    // Server will send a 'peer-available' message when a new socket connects to the server
    this.socket.on("peer-available", connectToAdvertisedPeer);

    this.socket.on("disconnect", () => {
      this.catastrophicFailure("Lost touch with the server!");
    });
  }

  /**
   * Handles removal of a playerconnection with peerId
   * @param {peerId} peerId
   * @param {Object} [options]
   * @param {boolean} [options.tellServer] whether or not we should let the server know we are disconnecting from this peer.
   * If we tell it we are, it may try to reconnect us.  That's good sometimes, but not always.
   * @returns
   */
  deletePlayer(peerId, options) {
    loggers.NetworkProvider("deletingPlayer called with", options);

    // Update current summary of connections
    this.updateLocalConnectionSummary(peerId, "deleted");

    options = {
      tellServer: true,
      ...options,
    };

    // Abort if already done
    if (typeof this.connections[peerId] === "undefined") return;
    loggers.NetworkProvider("deletingPlayer continuing because player exists");

    /**
     * @event NetworkProvider#peer-disconnection
     * @param {peerId} peerId
     */
    this.events.emit("peer-disconnection", peerId);

    // Clean up all the SimplePeer things
    this.connections[peerId].volatileChannel.peer.removeAllListeners();
    this.connections[peerId].stableChannel.peer.removeAllListeners();
    this.connections[peerId].volatileChannel.peer.destroy();
    // this.connections[peerId].stableChannel.peer.destroy() // < for now the two channels are actually the same so we comment this out.

    // Clear our checkConnection interval
    clearInterval(this.connections[peerId].checkConnectionInterval);

    // Delete from our player list
    delete this.connections[peerId];

    if (options.tellServer) {
      this.socket.emit("peer-connection-lost", {
        peerId,
      });
    }
  }

  /**
   * Called when a relay is received, distributes the information to subscribers
   * @param {peerId} peerId
   * @param {RelayData} relayData
   */
  handleRecieveRelay(peerId, relayData) {
    /**
     * @event NetworkProvider#receive-relay
     * @param {peerId} peerId
     * @param {RelayData} relayData
     */
    this.events.emit("receive-relay", peerId, relayData);
  }

  /**
   * Sends relay data to all peers via the stableChannel
   * @param {RelayData} relayData
   * @param {boolean} [mirrorLocally=true] - if true, will send a copy of this relay to our local relay handler
   */
  broadcastRelay(relayData, mirrorLocally = true) {
    // Send update to all connected peers
    // relayData should generally be structured {type: "eventName", options:{}}
    Object.keys(this.connections).forEach((id) => {
      this.sendMessageToPeer({
        peerId: id,
        messageType: "relay",
        data: relayData,
        channel: "stableChannel",
      });
    });

    // Sometimes it might be easiest to trigger a behavior on relay only.
    // But then, the sender of the relay wont see that behavior--just the recipients
    // If mirrorLocally is set, we mimic recieving the relay we sent
    // in order to trigger such behaviors
    if (mirrorLocally) {
      this.handleRecieveRelay(this.id, relayData);
    }
  }

  /**
   * Keep track of the last time a player interacted, we'll use this to disconnect idle connections
   */
  keepTrackOfLastActive = () => {
    /**
     * Prevents booting due to inactivity, will be true if set in query string param, or if not running in browser (e.g. node testing)
     */
    this.neverBoot =
      (typeof location !== "undefined" &&
        new URLSearchParams(location.search).get("never-boot") === "true") ||
      typeof window === "undefined";

    // Only do this if in an actual browser
    if (typeof window !== "undefined") {
      /**
       * Store the time that the user was last considered active i.e... button or click
       */
      this.lastActive = Date.now();

      // Update on keydown
      window.addEventListener("keydown", () => {
        this.lastActive = Date.now();
      });

      // Update on click
      window.addEventListener("click", () => {
        this.lastActive = Date.now();
      });
    }
  };

  /**
   * Determines if a peer and its data channel are ready to send messages
   * @param {SimplePeer} peer
   * @returns {boolean} true if peer & data channel are ready
   */
  peerIsReadyForMessages(peer) {
    return (
      typeof peer !== "undefined" &&
      peer._channel !== null &&
      peer._channel.readyState === "open"
    );
  }

  /**
   * Sends a message to a peer with:
   * - error handling
   * - serialization
   *
   * @param {Object} config
   * @param {peerId} config.peerId
   * @param {string} config.messageType - name of rtcMessageType
   * @param {Object} config.data - unserialized (this function will serialize it)
   * @param {'stableChannel'|'volatileChannel'} config.channel
   */
  sendMessageToPeer = ({ peerId, messageType, data, channel }) => {
    const serializedData = serialize({
      rtcMessageType: messageType,
      ...data,
    });

    try {
      const player = this.connections[peerId];

      // Make sure the peer exists and its channel is ready
      if (this.peerIsReadyForMessages(player[channel].peer)) {
        // Send the thing
        player[channel].peer.send(serializedData);
      } else {
        // If this is a stable channel, throw an error (caught below).
        // Do nothing if volatile
        if (channel === "stableChannel") {
          console.warn(
            `Error sending ${messageType} message to ${peerId}`,
            "Message sent to stable channel before it was ready. Trying again in 100ms!"
          );
          setTimeout(() => {
            loggers.NetworkProvider("Re-attempting stable-channel message");
            this.sendMessageToPeer({ peerId, messageType, data, channel });
          }, 100);
        }
      }
    } catch (err) {
      console.warn(`Error sending ${messageType} message to`, peerId, err);
    }
  };

  /**
   * Sends a message to specified peer requesting a full copy of their stable state
   * On reciept, they will send a sendStableState back to this NetworkProvider
   * @param {peerId} peerId
   * @returns {Promise} promise that resolves with stable state
   */
  requestPeerStableState = (peerId) => {
    // This promise should resolve once we here back with a stable state
    return new Promise((resolve) => {
      /**
       * An event listener that listens to 'peer-stable-state-received'
       * until the senderId matches the peerId, then resolves and unsubscribes
       */
      const resolveOnceWeGetStableState = (senderId, stableState) => {
        if (senderId === peerId) {
          this.events.off(
            "peer-stable-state-received",
            resolveOnceWeGetStableState
          );
          resolve(stableState);
        }
      };

      // Start listening (do this before asking)
      this.events.on("peer-stable-state-received", resolveOnceWeGetStableState);

      // Now request our stable state
      this.sendMessageToPeer({
        peerId,
        messageType: "request-stable-state",
        channel: "stableChannel",
      });
    });
  };

  /**
   * Sends our stable state (in FULL) to a connected peer
   * @param {peerId} peerId - who we send the stable to
   */
  sendStableState = (peerId) => {
    const data = this.options.getStableState();
    this.sendMessageToPeer({
      peerId,
      messageType: "stable-state",
      data,
      channel: "stableChannel",
    });
  };

  /**
   * Sends a partial stable state update to all connected peers
   * @param {StableState} updateData
   */
  broadcastStableStateUpdate = (updateData) => {
    Object.keys(this.connections).forEach((id) => {
      this.sendMessageToPeer({
        peerId: id,
        messageType: "stable-state-update",
        data: updateData,
        channel: "stableChannel",
      });
    });
  };

  /**
   * Sends stable state to all connected connections.
   * Ideally, this is never necessary as stable state is broadcasted whenever it changes
   * But we will rebroadcast it on a slow interval in case any such updates are missed.
   */
  rebroadcastStableState = () => {
    const completeStableStateUpdate = this.options.getStableState();
    this.broadcastStableStateUpdate(completeStableStateUpdate);
  };

  /**
   * Sends our state update to all connected peers
   */
  broadcastVolatileState = () => {
    // Determine if this is taking too long (likely a sign that this is in an unfocused tab--lets kill it then.)
    // const maxAllowableTimeBetweenVolatileBroadcasts = 800;
    const now = Date.now();
    // if (
    //   this.timeOfLastVolatileStateBroadcast !== null &&
    //   now - this.timeOfLastVolatileStateBroadcast >
    //     maxAllowableTimeBetweenVolatileBroadcasts &&
    //   !this.neverBoot
    // ) {
    //   this.catastrophicFailure(
    //     "Your connection stopped sending updates to the network. Did you unfocus the tab?"
    //   );
    // }
    this.timeOfLastVolatileStateBroadcast = now;

    // Build a volatile state and translate to binary
    const data = {
      ...this.options.getVolatileState(),
      lastActive: this.neverBoot ? now : this.lastActive,
    };

    // Send update to all connected peers
    Object.keys(this.connections).forEach((id) => {
      this.sendMessageToPeer({
        peerId: id,
        messageType: "volatile-state",
        data,
        channel: "volatileChannel",
      });
    });
  };

  /**
   * The number of connections currently connected
   */
  get playerCount() {
    return Object.keys(this.connections).length;
  }

  /**
   * Trigger this when things go totally fubar and we need the user to reboot the app
   * @param {string} reason why, oh why did this happen?
   */
  catastrophicFailure(reason = "An error has occurred.") {
    // Dont allow this more than once. Why?  Well this failure triggers disconnect from the server
    // which, in turn, will call this again.
    // And that will replace the reason/error message with an error message about disconnectin from the server.
    // We would like the OG error message to appear--not a server disconnection error every time!
    if (this.hasAlreadyCatastrophicallyFailed) return;

    // Dont do this again now that we are going through with it
    this.hasAlreadyCatastrophicallyFailed = true;

    /**
     * @event NetworkProvider#catastrophic-failure
     * @param {string} reason
     */
    this.events.emit("catastrophic-failure", reason);
    this.destroy();
  }

  /**
   * Fired when NetworkProvider is ready, sets internal state isReady and emits event
   */
  ready() {
    this.isReady = true;
    /**
     * @event NetworkProvider#ready
     */
    this.events.emit("ready");
  }

  /**
   * Destroy the networkProvider and clean up references/memory leaks
   */
  destroy() {
    // Clear the state update interval
    clearInterval(this.volatileStateUpdateInterval);

    // Disconnect the socket and kill all p2p connections
    this.socket.disconnect();
    Object.keys(this.connections).forEach((key) => {
      this.deletePlayer(this.connections[key], { tellServer: false });
    });
  }
}
