import { createStore } from "vuex";
import { mergeAndDiff } from "./mergeAndDiff";
import { throwErrorIfNotKeySubset } from "./throwErrorIfNotKeySubset";

import Joi from "joi";
import { performerControlSchema } from "./performerControlSchemas";
import loggers from "../loggers";
import { dialogSchema } from "./dialogSchemas";
import notificationSchema from "./notificationSchema";

export default createStore({
  state: {
    // State of the game that will be broadcast to the peer network
    networkSharedState: {
      // State changes that are OCCASIONAL and CRITICAL TO SEND
      stable: {
        player: {
          // id:'',
          // name: '',
          nickname: "",
          playerColor: null,
          currentRoomId: null,
          tvbot: false,
          tvbot_id:null,
          betweenRooms: true,
          visibility: 1,
        },
        gadgets: {
          left: {
            type: "",
            name: "",
            options: {},
          },
          right: {
            type: "",
            name: "",
            options: {},
          },
          top: {
            type: "",
            name: "",
            options: {},
          },
          bottom: {
            type: "",
            name: "",
            options: {},
          },
        },
      },

      // State changes that are CONSTANT and TOLERATE FAILURE TO SEND
      volatile: {
        player: {
          x: null,
          y: null,
          rotation: null,
          energy: null,
          scaleMultiplier: null,
          alpha: null,
          betweenRooms: true,
        },
        gadgets: {
          left: {},
          right: {},
          top: {},
          bottom: {},
        },
      },
    },

    // Details about the room
    room: {
      id: "",
      title: "",
      description: "",
      attribution: "",
    },

    // Room provided performer controls
    roomPerformerControls: [],

    // Queue of disrupting UI dialogs (e.g. prompts,alerts)
    dialogs: [],

    notifications: {
      story: [],
      system: [],
      currentId: 0,
    },

    // Debug UI
    fpsAverages: Array(8).fill(0),
    fps: [],
    playerCount: [],
    playerCountAverages: Array(8).fill(0),
    heartbeatLength: [],
    heartbeatLengthAverages: Array(8).fill(0),

    // Get access to networkProvider globally
    networkProvider: null,
  },
  actions: {
    pushNotification({ commit, state }, rawNotificationData) {
      loggers.notifications(
        "store pushNotification() committed",
        rawNotificationData
      );
      try {
        // Error if we do not validate against the rawNotification schema
        const { error, value: notification } =
          notificationSchema.validate(rawNotificationData);
        if (error)
          throw new Error(
            "Tried to push a notification with improper configuration: " + error
          );

        // Add an id
        notification.id = state.notifications.currentId++;

        // Add an animatingOut flag
        notification.animatingOut = false;

        // No error? Push the validated notification
        commit("addNotification", notification);

        setTimeout(() => {
          commit("removeNotification", notification.id);
        }, notification.timeout * 1000);
      } catch (err) {
        console.error(err);
      }

      loggers.notifications(
        "state.notifications is now",
        JSON.stringify(state.notifications)
      );
    },
  },
  mutations: {
    addNotification(state, notification) {
      state.notifications[notification.type].push(notification);
    },

    removeNotification(state, notificationId) {
      const deleteFrom = (category) => {
        const indexToDelete = state.notifications[category].findIndex(
          (notification) => notification.id === notificationId
        );
        if (indexToDelete >= 0) {
          state.notifications[category][indexToDelete].animatingOut = true;
          setTimeout(() => {
            state.notifications[category].splice(indexToDelete, 1);
          }, 300);
        }
      };
      deleteFrom("story");
      deleteFrom("system");
    },

    /**
     * Adds a dialog to our queue of dialogs.  User will view these in order.
     * @param {state} state
     * @param {Dialog} dialog
     */
    pushDialog(state, dialog) {
      loggers.dialogs("store pushDialog() committed", dialog);
      try {
        // Error if we do not validate against the dialog schema
        const { error, value } = dialogSchema.validate(dialog);
        if (error)
          throw new Error(
            "Used improper performer control configuration with md.performer.setControls(): " +
              error
          );

        // No error? Push the validated dialog
        state.dialogs.push(value);
      } catch (err) {
        console.error(err);
      }

      loggers.dialogs("state.dialogs is now", JSON.stringify(state.dialogs));
    },

    /**
     * Removes a dialog with particlar id from our queue of dialogs.
     * @param {state} state
     * @param {string} id
     */
    clearDialog(state, id) {
      loggers.dialogs("store clearDialog() committed", id);
      try {
        // Find our dialog
        const targetIndex = state.dialogs.findIndex(
          (dialog) => dialog.id === id
        );
        if (targetIndex === -1)
          throw new Error(
            `Tried to clear an dialog with a nonexistant id ${id}`
          );

        // No error? Remove it
        state.dialogs.splice(targetIndex, 1);
      } catch (err) {
        console.error(err);
      }
      loggers.dialogs("state.dialogs is now", JSON.stringify(state.dialogs));
    },

    /**
     * Removes all performer controls
     * @param {state} state
     */
    clearRoomPerformerControls(state) {
      state.roomPerformerControls = [];
    },

    /**
     * Adds controls to performer controls
     * @param {state} state
     * @param {PerformerControl[]} newControls
     */
    addRoomPerfomerControls(state, newControls) {
      console.log("addRoomPerfomerControls", newControls);
      try {
        // Use Joi to create a schema for valid newControls payload
        const updateSchema = Joi.array().items(performerControlSchema);

        // Error if we do not  validate against that schema
        const { error, value } = updateSchema.validate(newControls);
        if (error) {
          throw new Error(
            "Used improper performer control configuration add performer control: " +
              error
          );
        }

        // Check if control already exists...
        newControls.forEach((newControl) => {
          const id = newControl.id;
          const matchingControlIndex = state.roomPerformerControls.findIndex(
            (control) => control.id === id
          );
          if (matchingControlIndex !== -1) {
            throw new Error(
              `Tried to add performer control with id '${id}' but that id already exists! Not adding this new control.`
            );
          }
        });
        // No error? Set the controls...
        state.roomPerformerControls.push(...value);
      } catch (err) {
        console.error(err);
      }
    },

    /**
     * Merges in performer control updates to an existing perfomer control with a certain id
     * @param {state} state
     * @param {Object} payload
     * @param {string} payload.id
     * @param {PerformerControl} payload.update updates any provided keys by overwriting
     */
    updateRoomPerfomerControl(state, { id, update }) {
      try {
        // Find the control that matches this id
        const matchingControlIndex = state.roomPerformerControls.findIndex(
          (control) => control.id === id
        );
        if (matchingControlIndex === -1)
          throw new Error(
            `Attempted to update nonexisting perfomer control "${id}"`
          );

        // Build the new control created by this update and validate it against our Joi performerControlSchema
        const newControl = {
          ...state.roomPerformerControls[matchingControlIndex],
          ...update,
        };
        const { error, value } = performerControlSchema.validate(newControl);
        if (error)
          throw new Error(
            "Used improper performer control configuration with md.performer.updateControl(): " +
              error
          );

        // If no error by now, make the update.
        state.roomPerformerControls[matchingControlIndex] = value;
      } catch (err) {
        console.error(err);
      }
    },

    /**
     * Merges in performer control updates to an existing perfomer control with a certain id
     * @param {state} state
     * @param {Object} payload
     * @param {string} payload.id
     * @param {PerformerControl} payload.update updates any provided keys by overwriting
     */
    removeRoomPerfomerControl(state, id) {
      try {
        // Find the control that matches this id
        const matchingControlIndex = state.roomPerformerControls.findIndex(
          (control) => control.id === id
        );
        if (matchingControlIndex === -1)
          throw new Error(
            `Attempted to remove nonexisting perfomer control "${id}"`
          );

        // If no error by now, filter the controls array to remove the control.
        state.roomPerformerControls[matchingControlIndex] =
          state.roomPerformerControls.filter((control) => control.id !== id);
      } catch (err) {
        console.error(err);
      }
    },

    updatePlayerVolatileState(state, volatileState) {
      try {
        throwErrorIfNotKeySubset(
          volatileState,
          state.networkSharedState.volatile.player
        );
        state.networkSharedState.volatile.player = {
          ...state.networkSharedState.volatile.player,
          ...volatileState,
        };
      } catch (err) {
        console.error(err);
      }
    },
    updateGadgetVolatileState(state, { slot, ...volatileState }) {
      state.networkSharedState.volatile.gadgets[slot] = {
        ...state.networkSharedState.volatile.gadgets[slot],
        ...volatileState,
      };
    },
    updatePlayerStableState(state, stableState) {
      const { merged, diff } = mergeAndDiff(
        stableState,
        state.networkSharedState.stable.player
      );
      if (!diff) return;
      state.networkSharedState.stable.player = merged;

      // Doesn't feel amazing to do this here...
      // We should probably make use of vueX subscribers/watchers, or some other event emitter
      // Then the newtork handler and my player can sub to it and send out diff

      // Broadcast this change to all other peers
      window.networkProvider.broadcastStableStateUpdate({
        player: {
          ...diff,
        },
      });

      // Send to MyPlayer
      if (window?.phaserScene?.playerManager?.myPlayer?.updatePlayerStableState)
        // <-- this avoids a race case that would be solved with an emitter
        window.phaserScene.playerManager.myPlayer.updatePlayerStableState(diff);
    },
    updateGadgetStableState(state, stableState) {
      const { merged, diff } = mergeAndDiff(
        stableState,
        state.networkSharedState.stable.gadgets
      );
      if (!diff) return;
      state.networkSharedState.stable.gadgets = merged;

      // Doesn't feel amazing to do this here...
      // We should probably make use of vueX subscribers/watchers, or some other event emitter
      // Then the newtork handler and my player can sub to it and send out diff
      window.networkProvider.broadcastStableStateUpdate({
        gadgets: {
          ...diff,
        },
      });

      // Send to MyPlayer GadgetManager
      if (
        window?.phaserScene?.playerManager?.myPlayer?.gadgetManager
          ?.updateGadgetsStableState
      )
        // <-- this avoids a race case that would be solved with an emitter
        window.phaserScene.playerManager.myPlayer.gadgetManager.updateGadgetsStableState(
          diff
        );
    },
    changeMyPlayerInventory(state, payload) {
      //   state.myPlayerInventory = payload
    },
    changeGadgetState(state, payload) {
      // payload should be e.g. {slot: 'bottom', state: {...}}
      // state.myPlayerInventory[payload.slot].state = payload.state
    },
    addFps(state, payload) {
      state.fps.push(payload);
      state.playerCount.push(window.networkProvider.playerCount);
      if (state.fps.length >= 30) {
        let runningTotal = 0;
        for (let i = 0; i < state.fps.length; i++) {
          runningTotal += state.fps[i];
        }
        state.fpsAverages.pop();
        state.fpsAverages.unshift(runningTotal / state.fps.length);
        state.fps = [];
        state.playerCountAverages.pop();
        state.playerCountAverages.unshift(Math.max(...state.playerCount));
        state.playerCount = [];
      }
    },
    addHeartbeatLength(state, payload) {
      state.heartbeatLength.push(payload);
      if (
        state.heartbeatLength.length >=
        window.networkProvider.playerCount * 20
      ) {
        let runningTotal = 0;
        for (let i = 0; i < state.heartbeatLength.length; i++) {
          runningTotal += state.heartbeatLength[i];
        }
        state.heartbeatLengthAverages.pop();
        state.heartbeatLengthAverages.unshift(
          runningTotal / state.heartbeatLength.length
        );
        state.heartbeatLength = [];
      }
    },
    changeRoom(state, payload) {
      state.room.title = payload.title;
      state.room.id = payload.id;
      state.room.url = payload.url;
      state.room.description = payload.description;
      state.room.author = payload.author;
      state.room.world = payload.world;
    },
    setNetworkProvider(state, payload) {
      state.networkProvider = payload;
    },
  },
  getters: {
    notif: (state) => {
      return state.dialogs[0];
    },
    currentDialog: (state) => {
      return state.dialogs[0];
    },
    volatileState: (state) => {
      return state.networkSharedState.volatile;
    },
    stableState: (state) => {
      return state.networkSharedState.stable;
    },
    livestreamPlayer: (state) => {
      return state.livestreamPlayer;
    },
    serverHeartbeatDelay: (state) => {
      return state.serverHeartbeatDelay;
    },
    playerList: (state) => {
      return state.playerList;
    },
    myPlayerInventory: (state) => {
      return state.myPlayerInventory;
    },
    fps: (state) => {
      return state.fps;
    },
    room: (state) => {
      return state.room;
    },
    networkProvider: (state) => state.networkProvider,
  },
});
