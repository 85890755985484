/**
 * @returns {boolean} whether or not the user is moused, can be forced with query string `?force-no-mouse=true` or `?force-mouse=true`, returns true in node (e.g. for Next.js renders)
 */
export function userHasNoMouse() {
  let userHasNoMouse = true
  if (typeof window !== 'undefined') {
    const hasNoMouseFromParams = (new URLSearchParams(window.location.search)).get('force-no-mouse') === 'true';
    const hasMouseFromParams = (new URLSearchParams(window.location.search)).get('force-mouse') === 'true';
    const hasNoMouseFromMediaQuery = !(window.matchMedia('(any-hover: hover)').matches && window.matchMedia('(pointer: fine)').matches);
    userHasNoMouse = hasMouseFromParams ? false : hasNoMouseFromMediaQuery || hasNoMouseFromParams;
  }

  return userHasNoMouse;
}
