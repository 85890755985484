<template>
    <!-- Story Notifications -->
    <ul
      class="absolute bottom-0 left-[20%] w-[60%] text-xl pointer-events-none"
      data-cy="story-notifications"
    >
      <li v-for="notification in storyNotifications" :key="notification.id"
        :class="`bg-dark-gray bg-opacity-90 pl-14 pr-4 py-2 border-l-4 border-green ${notification.animatingOut ? 'animate-fadeout' : 'animate-slidein'}`"
        >
        <span class="text-green inline-block -ml-10 w-10 animate-flicker">$></span>
        <span class="text-green font-head inline-block mr-2 uppercase" v-if="notification.character">{{ notification.character }}: </span>
        {{ notification.content }}
      </li>
    </ul>

    <!-- System Notifications, currently hiding these -->
    <ul
      class="hidden"
      v-if="systemNotifications.length"
    >
      <li v-for="notification in systemNotifications" :key="notification.id" class="">
        {{ notification.content }}
      </li>
    </ul>
</template>

<script>
  import loggers from '@/loggers'
  import store from '@/lib/store'
  import { computed } from '@vue/runtime-core'
    export default {
      name: 'Notifications',
      setup() {
        loggers.notifications('Notifications.vue setup()')

        return {
          storyNotifications: computed(()=>store.state.notifications.story),
          systemNotifications: computed(()=>store.state.notifications.system)
        }
      }
    }
</script>

<style scoped>

  .animate-slidein {
    animation: slidein 0.2s ease-out forwards;
  }
  @keyframes slidein {
    0% {
      opacity: 0;
      transform: translateX(15vw);
    }
    100% {
      opacity: 1;
      transform: none;
    }
  }

  .animate-fadeout {
    animation: fadeout 0.3s forwards;
  }
  @keyframes fadeout {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }


  .animate-flicker {
    animation: flicker 0.5s 0.4s forwards;
  }
  @keyframes flicker {
    0%,20%,40%,60%,100% {
      opacity: 0.3;
    }
    10%,30%,50% {
      opacity: 1;
    }
  }
</style>