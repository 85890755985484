<template>
  <div class="flex gap-2">
    <input
      type="text"
      v-model="control.value"
      @keyup="containKeyPresses($event)"
      @keydown="containKeyPresses($event)"
      @keydown.enter="submitInput(); temporarilyShowSubmittingClasses()"
      @keydown.escape="blurInput($event)"
      placeholder="..."
      class="input shrink min-w-0"
    />
    <button
      @click="submitInput()"
      :class="`button min-w-max ${showSubmittingClasses ? '' : ''}`"
    >{{ control.label }}</button>
  </div>
</template>

<script>
import {sendMessageToCurrentRoom} from '@/lib/sendMessageToCurrentRoom'

export default {
  name: 'InputPerformerControl',
  components: {},
  data() {
    return {
      showSubmittingClasses: false,
    }
  },
  methods: {
    /**
     * Stops progogation of most keys when called from keyup event on an input
     * E.g. we don't want the player to fire gadgets because we typed a gadget key.
     */
    containKeyPresses(e) {
      e.stopPropagation()
    },
    /**
     * Triggers a performer action based on an input, clears that input
     * @param {boolean} [clearAfterSubmit=true] whether to clear the input after submitting
     */
    submitInput(clearAfterSubmit=true) {
      sendMessageToCurrentRoom('performer-action-triggered-'+this.control.id, this.control.value)
      if(clearAfterSubmit) this.control.value=''
    },
    /**
     * Blurs the input that is the target of a given event, e.g. for escape keydown
     */
    blurInput(e) {
      e.target.blur()
    },
    /**
     * Adds classes to temporarily give active styles (as if the user clicked the button)
     * Useful for UX feedback when activating button via hotkey
     * In our case, we use it on Enter submit
     */
    temporarilyShowSubmittingClasses() {
      this.showSubmittingClasses = true
      setTimeout(()=>{
        this.showSubmittingClasses = false
      },75)
    },
  },
  mounted() {
  },
  props: {
    control: Object,
  }
}
</script>
