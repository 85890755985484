import {lerp} from '@/lib/lerp'
import { playerContainerWidth } from '../../lib/constants'


// I DISABLED THESE FOR NOW BY TURNING OFF THE CREATE FUNCTION LINE 15
export default class PositionIndicator {
  constructor (target,phaserScene,options={}) {
    this.target = target
    this.phaserScene = phaserScene
    this.playerPhaserObject = this.phaserScene.playerManager.myPlayer.playerPhaserObject

    const defaultOptions = {
      color: 0x6666ff,
    }
    this.options = {...defaultOptions,...options}
    this.create()
  }

  create = () => {
    this.phaserScene.events.on('update', this.handleUpdate);
    this.createArrow()
  }

  handleUpdate = (time,delta) => {
    this.updateArrow()
  }

  createArrow() {

    const arrowPoints = [ 0,20, 84,20, 84,0, 120,50, 84,100, 84,80, 0,80 ]
    this.arrow = this.phaserScene.add.polygon(0,0, arrowPoints, this.options.color)
      .setOrigin(1,0.5)
      .setScrollFactor(0)
      .setAlpha(0.5)
    this.updateArrow()

    this.scaleMultiplier = 1
    this.originalScale = this.arrow.scaleX
  }

  updateArrow() {
    if(this.arrow) {
      // define the rectangle that our arrow will appear along (its the camera rectangle but padSize smaller on each side)
      const cameraRect = this.phaserScene.cameras.main.worldView
      const zoom = this.phaserScene.cameras.main.zoom

      // Normalize to a coord system centered at camera center
      const normalizeX = x => x - cameraRect.centerX
      const normalizeY = y => y - cameraRect.centerY

      // Get normalized screen coords for camera
      const top = normalizeY(cameraRect.top)
      const bottom = normalizeY(cameraRect.bottom)
      const left = normalizeX(cameraRect.left)
      const right = normalizeX(cameraRect.right)

      // Calculate our points in this normalized space
      const p1 = {x: 0, y: 0}
      // const p1 = {x: normalizeX(this.playerPhaserObject.x), y: normalizeY(this.playerPhaserObject.y)}
      const p2 = {x: normalizeX(this.target.x), y: normalizeY(this.target.y)}

      // Rotate the arrow
      const angleRadians = Math.atan2(p2.y - p1.y, p2.x - p1.x);
      this.arrow.setRotation(angleRadians)

      // Calculate slope
      const slope = (p2.y-p1.y)/(p2.x-p1.x);

      // Figure out which edges we are over and position accordingly (in normalized coordinates)
      let indicatorPos = {}
      let showIndicator = true
      const buffer = playerContainerWidth
      if(p2.y < top - buffer || p2.y > bottom + buffer || p2.x < left - buffer || p2.x > right + buffer)
      {
        showIndicator = true
        //Detect whether the target point is proportionally further away on the x or y axis, and then anchor the arrow to that side, using slope to calculate the unanchored side
        if(Math.abs(p2.y)/cameraRect.height > Math.abs(p2.x)/cameraRect.width){
          if(p2.y < top){
            indicatorPos.y = top
            indicatorPos.x = top/slope  // + p1.x
          } else {
            indicatorPos.y = bottom
            indicatorPos.x = bottom/slope // + p1.x
          }
        } else {
          if(p2.x < left){
            indicatorPos.x = left
            indicatorPos.y = left*slope // + p1.y
          } else {
            indicatorPos.x = right
            indicatorPos.y = right*slope // + p1.y
          }
        }
      }
      //point is on screen
      else {
        showIndicator = false
        indicatorPos.x = p2.x
        indicatorPos.y = p2.y
      }


      // Make changes to our arrow
      this.arrow
        .setPosition(indicatorPos.x+cameraRect.width/2*zoom,indicatorPos.y+cameraRect.height/2*zoom)
        .setVisible( showIndicator )

      // If we have scaled up for some reason
      if(this.originalScale && this.scaleMultiplier) {
        this.arrow.scaleX = this.originalScale * this.scaleMultiplier
        this.arrow.scaleY = this.originalScale * this.scaleMultiplier
        this.scaleMultiplier = lerp(this.scaleMultiplier,1,0.1)
      }
    }
  }

  ping = (energy) => {
    this.scaleMultiplier = 1+Math.pow(energy,2)*2+0.1
  }

  destroy () {
    if(this.arrow) {this.arrow.destroy()}
    this.phaserScene.events.off('postupdate', this.handleUpdate)
  }
}
