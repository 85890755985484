import { throwErrorIfNotKeySubset } from "./throwErrorIfNotKeySubset";
import { shallowDiff } from "./shallowDiff";

/**
 * Returns a update/target merged and diff.
 * Throws an error if update is not a key supset of target.
 * If this or any other error is thrown, merged is just target. And diff is false.
 * If there are no differences, diff is false
 * @param {Object} update
 * @param {Object} target
 * @returns {Object} `{merged, diff}`
 * @returns {Object} `merged` - update merged into target
 * @returns {Object|false} `diff` - those shared top-level properties changed by update
 */
export function mergeAndDiff(update, target) {
  try {
    throwErrorIfNotKeySubset(update, target);

    const diff = shallowDiff(update, target);

    return {
      merged: {
        ...target,
        ...update,
      },
      diff,
    };

  } catch (err) {
    console.error(err);

    return {
      merged: target,
      diff: false,
    };
  }
}
