/*
----------------------------
SIZING CONSTANTS
----------------------------
*/

export const playerContainerWidth = 200
export const playerContainerHeight = 200

export const canvasWidth = 1500
export const canvasHeight = canvasWidth * 9/16

/*
----------------------------
LERP CONSTANTS
----------------------------
*/

export const LERP_SPEED = 0.13
export const LERP_SPEED_ANGULAR = 0.05

/*
----------------------------
DEPTH CONSTANTS
----------------------------
*/

export const DEPTHS = {
  BACKGROUND: -999,
  BEHIND_PLAYERS: -100,
  OTHER_PLAYERS: -10,
  MY_PLAYER: 0,
  HUD: 999,
}

/*
----------------------------
INVENTORY CONSTANTS
----------------------------
*/

// All of our gadget slots and their associated activation keys
export const GADGET_SLOT_INPUTS = {
  top: 'W',
  left: 'A',
  bottom: 'S',
  right: 'D'
}

/*
Conveniently returns template of slots with null associated values (milked from above)
e.g.
  {
    top: null,
    left: null,
    right: null,
  }
*/
export function getEmptyInventoryTemplate(){
  let emptySlotsTemplate = {}
  for (const slot in GADGET_SLOT_INPUTS) {
    emptySlotsTemplate[slot] = {
      name: '',
      type: '',
      options: {},
    }
  }
  return emptySlotsTemplate
}


// Use key
export const USE_KEY = ' '

/*
----------------------------
ENERGY CONSTANTS
----------------------------
*/
export const energyColor = 0xfffb87
