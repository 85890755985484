<template>
  <div
    v-if="currentDialog"
    class="absolute top-0 left-0 w-full h-full
      bg-black bg-opacity-50
      grid place-items-center"
  >
    <div
      :data-cy="'dialog-'+currentDialog.type"
      class="bg-black border p-8 text-lg  max-w-[40vw] min-w-[20vw] text-center"
    >

      <template v-if="currentDialog.type==='prompt'">
        <h2>{{ currentDialog.question }}</h2>
        <div class="flex gap-4 mt-4">
          <input type="text" class="input grow" :placeholder="currentDialog.placeholder"
            @keydown="$event.stopPropagation()"
            @keyup="$event.stopPropagation()"
            v-model="currentDialog.inputValue"
            @keydown.enter="answer(currentDialog.id,currentDialog.inputValue)"
          />
          <button class="button" @click="answer(currentDialog.id,currentDialog.inputValue)">{{ currentDialog.button }}</button>
        </div>
      </template>

      <template v-if="currentDialog.type==='alert'">
        <h2>{{ currentDialog.message }}</h2>
        <button class="button mt-4"
          @click="answer(currentDialog.id,true)"
        >{{ currentDialog.button }}</button>
      </template>

    </div>
  </div>
</template>

<script>

  import { sendMessageToCurrentRoom } from '@/lib/sendMessageToCurrentRoom'
  import loggers from '@/loggers'
  export default {
    name: 'Dialogs',
    computed: {
      /**
       * Get current dialog in queue from store
       */
      currentDialog: function () {
        loggers.dialogs('currentDialog() calculated by Vue component')
        return this.$store.getters.currentDialog
      }
    },
    methods: {
      answer(id,answer) {

        loggers.dialogs('anser() method called by Vue component',id,answer)
        // Remove this dialog from the store's queue
        this.$store.commit('clearDialog',id)

        // Let the room know the answer (which will resolve the md.prompt/md.alert/etc promise)
        sendMessageToCurrentRoom('dialog',{id,answer})
      }
    },
  }
</script>