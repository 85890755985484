<template>
<div class="uppercase text-green font-head">{{ control.label }}</div>
  <div class="flex gap-2">
    <input
      type="text"
      v-model="control.value"
      @keyup="submitInput(); containKeyPresses($event)"
      @keydown="submitInput(); containKeyPresses($event)"
      @keydown.escape="blurInput($event)"
      placeholder="..."
      class="input grow"
    />
  </div>
</template>

<script>
import {sendMessageToCurrentRoom} from '@/lib/sendMessageToCurrentRoom'

export default {
  name: 'DynamicInputPerformerControl',
  methods: {
    /**
     * Stops progogation of most keys when called from keyup event on an input
     * E.g. we don't want the player to fire gadgets because we typed a gadget key.
     */
    containKeyPresses(e) {
      e.stopPropagation()
    },
    /**
     * Triggers a performer action based on an input, clears that input
     */
    submitInput() {
      sendMessageToCurrentRoom('performer-action-triggered-'+this.control.id, this.control.value)
    },
    /**
     * Blurs the input that is the target of a given event, e.g. for escape keydown
     */
    blurInput(e) {
      e.target.blur()
    },
  },
  mounted() {
  },
  props: {
    control: Object,
  }
}
</script>
