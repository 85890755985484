import deepEqual from 'fast-deep-equal';

/**
 * Returns an object containing top-level properties of update that:
 * 1. share keys with top-level elements in target
 * 2. have different values
 * Put another way, this gives you what shared properties that have changed with update
 * @param {Object} update
 * @param {Object} target
 * @returns {Object|false} The object of diff properties or, if none, false
 */

export function shallowDiff(update, target) {
  let diff = {};
  Object.keys(update)
    .filter(key => {
      return !deepEqual(update[key], target[key]);
    })
    .forEach(key => diff[key] = update[key]);

  if (Object.keys(diff).length === 0)
    return false;
  return diff;
}
