// Our xirsys stun/turn servers
// See https://global.xirsys.net/dashboard/services
const iceServers = [
  {
    urls: ["stun:us-turn5.xirsys.com"],
  },
  {
    username:
      "JtWV9xldFIdNLv-QHPRvkd9C4PgjqyezmDlaJGIK5qMaiafm2TiBRuRfrL9kJFmkAAAAAGI9NKRtb29uZHVuZ2Vvbg==",
    credential: "505ab66a-abea-11ec-93bb-0242ac140004",
    urls: [
      "turn:us-turn5.xirsys.com:80?transport=udp",
      "turn:us-turn5.xirsys.com:3478?transport=udp",
      "turn:us-turn5.xirsys.com:80?transport=tcp",
      "turn:us-turn5.xirsys.com:3478?transport=tcp",
      "turns:us-turn5.xirsys.com:443?transport=tcp",
      "turns:us-turn5.xirsys.com:5349?transport=tcp",
    ],
  },
];
export default iceServers;
